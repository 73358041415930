//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';
import { MaterialModule } from '../core/material.module';
import { DirectivesModule } from '../directives/directives.module';
import { ClientFormComponent } from './client-form.component';
import { clientRouting } from './client.routing';
import { ClientListComponent } from './client-list.component';
import { ClientAdminListComponent } from './client-admin-list.component';
import { LoaderModule } from '../components/loader/loader.module';
import { ClientAdminFormComponent } from './client-admin-form.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        clientRouting,
        ComponentsModule,
        DirectivesModule,
        LoaderModule,
        FlexLayoutModule,   
    ],
    declarations: [
        ClientFormComponent,
        ClientListComponent,
        ClientAdminListComponent,
        ClientAdminFormComponent
    ],
    providers: [
        
    ],
    exports: [
        ClientFormComponent,
        ClientListComponent,
        ClientAdminListComponent,
        ClientAdminFormComponent
    ]
})

export class ClientModule { }