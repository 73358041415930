import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ReserveService } from '../services/reserve.service';

@Component({
  selector: 'app-client',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientFormComponent implements OnInit {
  form: FormGroup;
  model: any;
  actividad: any;
  icono: any;

  constructor(private fb: FormBuilder, 
    public reserveService: ReserveService,
    private activeRoute: ActivatedRoute,
    private _snackBar: MatSnackBar) {
    localStorage.removeItem('session');
  }

  ngOnInit() {
    this.actividad = this.activeRoute.snapshot.queryParams.actividad;
    this.icono = this.activeRoute.snapshot.queryParams.icono;
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      cedula: ['', Validators.required],
      celular: ['', Validators.required],
      email: ['', Validators.required],
    });
  }

  save() {
    if (this.form.valid) {
      this.model = this.form.value;
      this.model.cedula = this.model.cedula.toString();
      this.model.token = this.activeRoute.snapshot.paramMap.get('id');
      this.reserveService.saveReserve(this.form.value)
        .subscribe(
          data => {
            this.buildForm();
            this.openSnackBar('Carga Exitosa. Gracias por completar sus datos ✔✔', 'CERRAR');
          });
    } else {
      this.openSnackBar('Advertencia. Complete todos lo datos', 'CERRAR');
    }
  }

  openSnackBar(title: any, action: any) {
    this._snackBar.open(title, action, {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
