import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '../interceptor/snackbar.service';
import { ReserveService } from '../services/reserve.service';

@Component({
  selector: 'app-reserva-form',
  templateUrl: './reserva-form.component.html',
})
export class ReservaFormComponent implements OnInit {

  list: any[];
  form: FormGroup;

  disponible: boolean = true;
  cantDisponible: boolean = true;
  infoData: any = {};
  available: any = {};
  constructor(
    private fb: FormBuilder,
    public reserveService: ReserveService,
    private snackbarService: SnackbarService,
    private datepipe: DatePipe,
    public dialogRef: MatDialogRef<ReservaFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.buildForm();
  }

  /**
   * Método para inicializar el formulario de reserva
   */
  buildForm() {
    this.form = this.fb.group({
      fechaReserva: ['', Validators.required],
      tipoReserva: ['', Validators.required],
      cantidadReserva: ['', Validators.required],
      horarioReserva: ['', Validators.nullValidator],
      cedula: ['', [Validators.minLength(5), Validators.required]],
      nombre: ['', [Validators.required, Validators.minLength(3)]],
      apellido: ['', [Validators.required, Validators.minLength(3)]],
      celular: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', [
        Validators.email,
        Validators.required
      ]],
      observacion: ''
    });
  }

  ngOnInit() {
    
  }

  /**
   * Método para cerrar el modal de reserva
   */
  close() {
    this.dialogRef.close('close');
  }

  /**
   * Método ejecutado al seleccionar una fecha, para habilitar el selector de actividades
   * @param type el tipo de evento
   * @param event el valor seleccionado
   */
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.getActivity(event.value)
  }

  /**
   * Método para buscar un cliente a partir de su número de cédula, para autocompletar los datos del formulario
   */
  searchClient() {
    if (this.form.value.cedula) {
      this.reserveService.getClient(this.form.value.cedula)
        .subscribe(
          data => {
            let client = data['cliente'];
            if (client) {
              this.form.controls.nombre.setValue(client.nombre);
              this.form.controls.apellido.setValue(client.apellido);
              this.form.controls.celular.setValue(client.celular);
              this.form.controls.email.setValue(client.email);
            }
          });
    }
  }
  /**
   * Método para obtener la lista de actividades disponibles para una fecha específica
   */
  getActivity(date: any) {
    this.reserveService.getActivityWithToken({ fechaReserva: date })
      .subscribe(
        data => {
          this.list = data;
          this.disponible = false;
        });
  }
  /**
   * Método para obtener la disponibilidad de la actividad selecciona para la fecha especificada
   */
  async selectionChange(item: any) {
    let id = this.form.controls.tipoReserva.value
    let d = this.form.controls.fechaReserva.value
    let body = { fechaReserva: this.datepipe.transform(d, 'yyyy-MM-dd') };
    //console.log(body);
    await this.reserveService.getReserveAvailableWithToken(id, body)
      .subscribe(
        data => {
          this.infoData = data;
          if (this.infoData.tipo == 'normal' && this.infoData.cantidadDisponible > 0) {
            this.cantDisponible = false;
          }
        });
  }

  /**
   * Se ejecuta al cerrar el selector múltiple
   * @param opened
   */
  closeSelect(opened: boolean) {
    if (!opened) {
      this.validation();
    }
  }


  /**
   * Método para validar la disponibilidad de lugar en un horario establecido
   */
  validation() {
    let id = this.form.controls.tipoReserva.value
    let d = this.form.controls.fechaReserva.value
    let body = { fechaReserva: this.datepipe.transform(d, 'yyyy-MM-dd'), tipo: this.infoData.tipo, horario: this.form.controls.horarioReserva.value };
    this.reserveService.reserveVerificationWithToken(id, body)
      .subscribe(
        data => {
          this.available = data;
          if (this.available.cantidadDisponible > 0) {
            this.cantDisponible = false;
          } else {
            this.snackbarService.showMessage('No hay reservas disponibles, seleccione otro horario')
          }
        });
  }

  /**
   * Método para guardar una reserva realizada desde la planilla.
   */
  saveReserve() {
    let horarioReserva;
    if (this.form.value.horarioReserva instanceof Array) {
      horarioReserva = this.form.value.horarioReserva;
    } else {
      horarioReserva = [this.form.value.horarioReserva];
    }
    let tipoReservaId;
    if (this.form.value.tipoReserva instanceof Array) {
      tipoReservaId = this.form.value.tipoReserva[0];
    } else {
      tipoReservaId = this.form.value.tipoReserva;
    }
    let body = {
      fechaReserva: this.datepipe.transform(this.form.value.fechaReserva, 'yyyy-MM-dd'),
      tipoReserva: { id: tipoReservaId, cantidad: this.form.value.cantidadReserva, tipo: this.infoData.tipo, horario: horarioReserva },
      cedula: this.form.value.cedula.toString(),
      nombre: this.form.value.nombre,
      apellido: this.form.value.apellido,
      celular: this.form.value.celular,
      email: this.form.value.email,
      observacion: this.form.value.observacion
    }
    this.reserveService.saveReserveWithToken(body)
      .subscribe(
        data => {
          this.buildForm();
          this.close();
          this.snackbarService.showMessage('Reserva Exitosa. Verifique su correo ✔✔', 'CERRAR');
        });
  }
}
