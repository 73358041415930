  <!-- Grafico de barra -->
  <div class="contenedorGraficos" fxLayout="row" fxLayoutGap="200px" >
    <div class="first" *ngIf="bandera === false">
      <ngx-charts-bar-vertical-2d
        [view]="view"
        [scheme]="colorScheme"
        [results]="datos"
        [gradient]="gradiante2"
        [xAxis]="showXAxis2"
        [yAxis]="showYAxis2"
        [legend]="mostrarLegend2"
        [legendPosition]="posicionLegend2"
        [showXAxisLabel]="showXAxisLabel2"
        [showYAxisLabel]="showYAxisLabel2"
        [yAxisLabel]="yAxisLabel2"
        [roundEdges]="round"
        [showDataLabel]="showDataLabel2"
        [legendTitle]="tituloLegend2">
      </ngx-charts-bar-vertical-2d>
    </div>
    <div class="contenedorSinDatos" *ngIf="bandera === true">
      <div class="sinDatos" fxLayout="row" fxLayoutGap="100px"> 
        No hay datos para mostrar
      </div>
    </div>
  </div>

  