import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PersonaService } from '../services/persona.service';

@Component({
  selector: 'app-persona-form',
  templateUrl: './persona-form.component.html',
})
export class PersonaFormComponent implements OnInit {
  form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<PersonaFormComponent>,
    private fb: FormBuilder,
    private personaService: PersonaService,
    @Inject(MAT_DIALOG_DATA) public data = null
  ) {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      cedula: ['', Validators.required],
      celular: ['', Validators.required],
      email: ['', Validators.required],
      confirmado: [false, Validators.required]
    });
  }

  ngOnInit() { }

  close() {
    this.dialogRef.close();
  }

  saveParticipant() {
    this.personaService.addParticipant(this.data.id, this.form.value)
      .subscribe(
        data => {
          this.close();
        });
  }

}
