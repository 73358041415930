import { Clipboard } from '@angular/cdk/clipboard';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalComponent } from '../components/modal/modal.component';
import { ReserveService } from '../services/reserve.service';
import { BloqueoFormComponent } from './bloqueo-form.component';
import { PersonaListComponent } from './persona-list-component';
import { ReservaFormComponent } from './reserva-form.component';
import { Router } from '@angular/router';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-drag-drop-reserve',
  templateUrl: './drag-drop-reserve.component.html',
  styleUrls: ['./drag-drop-reserve.component.scss']
})
export class DragDropReserveComponent implements OnInit {

  dias = [
    { nombre: 'Actividad / Días', diaNumero: null, tipoCabecera: 'TITULO_TABLA' },
    { nombre: 'Mie', diaNumero: 6, tipoCabecera: '' },
    { nombre: 'Jue', diaNumero: 7, tipoCabecera: '' },
    { nombre: 'Vie', diaNumero: 8, tipoCabecera: '' },
    { nombre: 'Sab', diaNumero: 9, tipoCabecera: 'DIA_FERIADO' },
    { nombre: 'Dom', diaNumero: 10, tipoCabecera: 'DIA_FERIADO' },
    { nombre: 'Lun', diaNumero: 11, tipoCabecera: '' },
    { nombre: 'Mar', diaNumero: 12, tipoCabecera: '' },
  ];

  data: any = null;
  parametro = null;
  primeraVez: boolean;
  listaHorario: any = [];
  // listaHorario: any = [
  //   {valor: '18:15'},
  //   {valor: '19:00'},
  //   {valor: '21:00'},
  // ];

  fechaActual = {
    dia: null,
    mes: null,
    anho: null,
  };

  diasH = [];
  tipoVisualList = [
    { value: 'Nombre titular', key: 'nombre' },
    { value: 'N° participantes', key: 'cantidadPersonas' },
    { value: 'N° reserva', key: 'codigo' },
  ];

  form: FormGroup;
  formReserva: FormGroup;
  datoDestino = null;

  searchText = 'Buscando registros...';

  // en caso de que el drawer se muestre en el componente padre
  @Output() detalleReserva = new EventEmitter<any>();

  // drawer
  @ViewChild(MatDrawer) matDrawer: MatDrawer;

  reservaSeleccionada = null;
  actividadSeleccionada = null;
  url = '';
  weekday = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  
  actividadesFiltroList = [];
  actividadFiltroCabecera = '';
  selectTodosTxt = 'Desmarcar todos';
  habilitarEdit: boolean = false;

  @ViewChild('actividadFiltro') actividadSelect: MatSelect;

  defaultFilter: any;
  statusList: any[];
  selected: any;
  hasError: boolean = true;

  constructor(
    private fb: FormBuilder,
    private reserveService: ReserveService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private clipboard: Clipboard,
    private router: Router,
    public cdref: ChangeDetectorRef,
  ) {

    this.data = data;

    this.fechaActual.dia = new Date().getDate();
    this.fechaActual.mes = new Date().getMonth() + 1;
    this.fechaActual.anho = new Date().getFullYear();

    //en caso de que se mande el parametro por medio de la notificacion
    if(this.router.getCurrentNavigation().extras.state) {
      this.parametro = this.router.getCurrentNavigation().extras.state;
        //bandera para la funcion verdetalles()
        this.primeraVez = true;
    }
    this.buildFilterForm();
  }

  ngOnInit(): void {
    this.obtenerActividadesFiltro();
    this.getStatus();
  }

  /**
   * Construye el formulario
   */
  buildFilterForm() {
    this.form = this.fb.group({
      visual: ['nombre'],
      fechaInicio: [],
      fechaFin: [],
      actividadFiltro: [],
      busquedaGlobal: [''],
    });

    // se setean por defecto la fecha de inicio y fecha de fin con una diferencia de 7 dias
    this.form.controls.fechaInicio.setValue(new Date(Date.UTC(this.fechaActual.anho, this.fechaActual.mes - 1, this.fechaActual.dia, 8)));
    this.form.controls.fechaFin.setValue(new Date(Date.UTC(this.fechaActual.anho, this.fechaActual.mes - 1, this.fechaActual.dia + 6, 8)));
    // por cada cambio en el selector de actividades se actualiza el texto con datos comprimidos en el selector
    this.form.controls.actividadFiltro.valueChanges.subscribe(value => {

      if (this.form.controls.actividadFiltro.value) {
        const index = this.actividadesFiltroList.findIndex(e => e.id == this.form.controls.actividadFiltro.value[0])
        if (index > -1) {
          this.actividadFiltroCabecera = this.actividadesFiltroList[index].nombre;
        }
      }

    });
  }

  /**
   * Marca/desmarca todos los elementos del selector de tipos de altividades
   */
  marcarTodos() {
    if (this.form.controls.actividadFiltro.value.length === this.actividadesFiltroList.length) {
      this.form.controls.actividadFiltro.setValue([]);
      this.selectTodosTxt = 'Marcar todos';
    } else {
      this.selectTodosTxt = 'Desmarcar todos';
      this.form.controls.actividadFiltro.setValue(this.actividadesFiltroList.map(e => e.id));
    }
  }

  /**
   * Soltar la actividad en otra celda
   * @param event evento que contiene el index anterior y actual
   */
  drop(event: CdkDragDrop<string[]>): void {
    //mover solo sino hubo error
    if (!this.hasError) {
      this.hasError = true;
      if (event.container.data['reservas'].length === 0) {
        event.container.data['reservas'].push();
      }

      if (event.previousContainer === event.container) {
        moveItemInArray(
          event.container.data['reservas'],
          event.previousIndex,
          event.currentIndex
        );
      } else {
        transferArrayItem(
          event.previousContainer.data['reservas'],
          event.container.data['reservas'],
          event.previousIndex,
          event.currentIndex
        );
      }
    }
  }

  /**
   * Evento de transicion al pasar una reserva de una celda a otra
   * @param event evento de transicion con indices
   * @param actividad 
   * @param dia 
   */
  moviendo(event: CdkDragDrop<string[]>, actividad: any = null, dia: string = null): any {
    this.datoDestino = {
      actividad,
      dia
    };
  }

  /**
   * Evento donde soltamos la reserva en una celda
   * @param event 
   * @param reserva la reserva seleccionada
   * @param actividad la actividad de destino
   */
  soltarElemento(event: any, reserva: any, actividad: any) {
    let fecha = `${event.container.data.mes}-${event.container.data.dia}-${event.container.data.anho}`
    this.reserveService.updateReserve(reserva.id, { actividadId: this.datoDestino.actividad.id, fechaReserva: fecha })
      .subscribe(
        data => {
          this.hasError = false;
          reserva.dia = event.container.data.dia;
          reserva.mes = event.container.data.mes;
          reserva.anho = event.container.data.anho;
          //this.snackbar.open(`Se movio ${reserva.codigo} ${actividad.nombre} a ${this.datoDestino.actividad.nombre} día ${event.container.data.dia}`, `Ok`, { duration: 1500 });
          this.snackbar.open(`Se movio ${reserva.codigo} ${actividad.nombre}`, `CERRAR`, { duration: 1500 });
          this.drop(event)
        },
        error => {
          this.hasError = true;
          let msg = 'No se pudo realizar la actualización';
          if (error.status == 400) {
            msg = error.error.message
          }
          this.snackbar.open(`No se puede mover. ${msg}`, `CERRAR`, { duration: 1500 });
        });

    this.datoDestino = null;
  }

  /**
   * Recupera las actividades disponibles para filtrar
   */
  obtenerActividadesFiltro() {
    // se recuperan actividades para el filtro
    this.reserveService.getActividadesFiltro().subscribe(data => {

      this.actividadesFiltroList = data;

      const actividadesId = this.actividadesFiltroList.map(e => e.id);
      this.form.controls.actividadFiltro.setValue(actividadesId);

      // setear estas fechas al inicio
      //en caso de recuperar el parametro desde la notificacion
      if(this.parametro != null){
        this.defaultFilter = {
          fechaDesde: this.parametro.mes + '-' + this.parametro.dia + '-'+ this.parametro.anho,
          fechaHasta: this.parametro.mes + '-' + this.parametro.dia + '-'+ this.parametro.anho,
          tipoActividad: actividadesId,
          busquedaGlobal: this.parametro.codigo,
        };
       }else{
        this.defaultFilter = {
          fechaDesde: this.form.controls.fechaInicio.value,
          fechaHasta: this.form.controls.fechaFin.value,
          tipoActividad: actividadesId,
       };
      } 
      this.getPlanilla(this.defaultFilter);


    });
  }

  /**
   * Se ejecuta al cerrar el select
   * @param opened
   */
  cerrarSelect(opened: boolean) {
    if (!opened) {
      this.buscarActividades();
    }
  }

  /**
   * Arma el filtro y recupera la nueva planilla
   */
  buscarActividades() {


    if (this.form.controls.fechaInicio.value && !this.form.controls.fechaFin.value ||
      !this.form.controls.fechaInicio.value && this.form.controls.fechaFin.value) {
      this.snackbar.open('Debe seleccionar Fecha de Inicio y Fecha de Fin', 'Entendido', { duration: 2000 })
      return;
    }

    const inicio = new Date(this.form.controls.fechaInicio.value);
    const fin = new Date(this.form.controls.fechaFin.value);
    const filtro = {
      fechaDesde: `${inicio.getMonth() + 1}-${inicio.getDate()}-${inicio.getFullYear()}`,
      fechaHasta: `${fin.getMonth() + 1}-${fin.getDate()}-${fin.getFullYear()}`,
      tipoActividad: this.form.controls.actividadFiltro.value,
      busquedaGlobal: this.form.controls.busquedaGlobal.value
    }
    this.defaultFilter = filtro;
    this.getPlanilla(filtro);

  }

  /**
   * Recupera la planilla a mostrar
   * @param filtro filtro para recuperar contenido especifico
   */
  getPlanilla(filtro = null) {

    this.searchText = 'Buscando registros...';

    // ceramos el listado de dias
    this.data = [];

    // ceramos el header
    this.diasH = [];
    this.reserveService.getPlanilla(filtro).subscribe(data => {
      if (data.length > 0) {
        this.diasH = data[0].reservaDias.map(e => {
          e['day'] = new Date(Date.UTC(e.anho, e.mes - 1, e.dia, 8));
          e['nombreDia'] = this.weekday[e.day.getDay()];
          e['tipoCabecera'] = this.weekday[e.day.getDay()] === 'Domingo' ? 'DIA_FERIADO' : this.weekday[e.day.getDay()] === 'Sábado' ? 'DIA_FERIADO' : ''
          return e;
        });
        this.diasH = [...[{ dia: null, nombreDia: 'Actividad / Días', diaKey: null, tipoCabecera: 'TITULO_TABLA' }], ...this.diasH]
      } else {
        this.searchText = 'No se encontraron resultados...';
      }
      this.data = data;

      if(this.primeraVez){
        this.verDetalle(this.data[0].actividad, this.data[0].reservaDias[0].reservas[0])
        this.primeraVez = false
      }
      
    });
  }

  /**
   * Recupera la cantidad de dias para un mes
   * @param month mes
   * @param year anho al que pertenece el mes
   * @returns cantidad de dias del mes en ese anho
   */
  getDiasDelMes(month: number, year: number): number {
    return new Date(year, month, 0).getDate();
  }

  obtenerHorario(){
    var body = {'fechaReserva': this.reservaSeleccionada.anho + '-' + this.reservaSeleccionada.mes + '-' + this.reservaSeleccionada.dia}
     this.reserveService.getHorario(this.actividadSeleccionada.id , body).subscribe(
       (data:any) =>{
         this.listaHorario = data.valor;
       }
     )
   }

  /**
   * Muestra el detalle de una reserva para una actividad
   * @param actividad la actividad a cual pertenece la reserva
   * @param reserva la reserva del cliente
   */
   verDetalle(actividad: any, reserva: any) {
    this.statusList.forEach(element => {
      if (element.nombre == reserva.estado) {
        this.selected = element.id;
      }
    })
    this.actividadSeleccionada = actividad;
    this.reservaSeleccionada = reserva;
    if (!this.matDrawer.opened || this.actividadSeleccionada.id === actividad.id) {
      this.matDrawer.toggle();
    }
    
    this.formReserva = this.fb.group({
      observacion: [this.reservaSeleccionada.observacion],
      cantidad: [this.reservaSeleccionada.cantidadPersonas, Validators.required],
      horario: [this.reservaSeleccionada.horario[0]]
    });
    this.formReserva.valueChanges.subscribe( data => {
      this.habilitarEdit = true;
    });

    this.obtenerHorario()
  }

  /**
   * Abre el formulario para agregar una nueva reserva/evento
   */
  openReservaForm() {
    const config: MatDialogConfig = {
      minWidth: '850px',
      data: this.actividadesFiltroList
    };

    const dialog = this.dialog.open(ReservaFormComponent, config);

    dialog.afterClosed().subscribe((resp) => {
      if (resp != 'close') {
        this.getPlanilla(this.defaultFilter)
      }
    });
  }

  /**
   * Muestra el listado de partipantes para una reserva
   * @param reserva la reserva seleccionada
   */
  verPersonaReserva(reserva = null) {
    const config: MatDialogConfig = {
      minWidth: '1000px',
      minHeight: '600px',
      height: '78vh',
      data: reserva
    };

    this.dialog.open(PersonaListComponent, config);
  }

  /**
   * Copia el enlace en el portapapeles para pegar en donde sea conveniente
   * @param url el enlace a compartir
   */
  public copiarEnlace(url: string): void {
    this.clipboard.copy(url);
    this.snackbar.open('Enlace copiado', 'Aceptar', { duration: 1500 });
  }

  /**
  * Método para eliminar una reserva
  * @param activity: actividad de reserva a ser eliminada
  * @param reserve: reserva a ser eliminada
  */
  deleteReserve(activity: any, reserve: any) {
    const modalConfig: MatDialogConfig = {
      data: {
        title: 'Eliminar Reserva',
        msg: `¿Confirma que desea eliminar la reserva de <b>${activity.nombre}</b>?`,
        acceptBtnMsg: 'Eliminar',
      }
    };

    //pantalla de confirmación previa a la eliminación
    const dialog = this.dialog.open(ModalComponent, modalConfig);

    dialog.afterClosed().subscribe((resp) => {
      if (resp == 'confirm') {
        this.matDrawer.close();
        this.reserveService.deleteReserve(reserve.id)
          .subscribe(
            data => {
              this.getPlanilla(this.defaultFilter)
            });
      }

    });
  }
  /**
   * Método para obtener la lista de estados para una reserva
   */
  getStatus() {
    this.reserveService.getReserveStatus()
      .subscribe(
        data => {
          this.statusList = data;
        });
  }


  /**
   * Método para actualizar el estado de una reserva PENDIENTE|RECHAZADO|CANCELADO|CONFIRMADO
   * @param item: la opción de estado seleccionada 
   */
  uptadeStatus(item: any, allConfirm: boolean = false) {
    let body: any = {};
    if (item) {
      body.estadoId = item.value
    } else {
      body.confirmarBloque = allConfirm;
    }
    this.reserveService.updateReserve(this.reservaSeleccionada.id, body)
      .subscribe(
        data => {
          if (item) {
            this.statusList.forEach(element => {
              if (element.id == item.value) {
                this.reservaSeleccionada.estado = element.nombre;
              }
            });
          } else {
            this.getPlanilla(this.defaultFilter);
            this.matDrawer.close();
          }       
        });
  }
  /**
   * Método para actualizar el estado de una reserva PENDIENTE|RECHAZADO|CANCELADO|CONFIRMADO
   * @param item: la opción de estado seleccionada 
   */
   uptadeReserve() {
    let body: any = this.formReserva.value;
    this.reserveService.updateReserve(this.reservaSeleccionada.id, body)
      .subscribe(
        data => {  
            this.habilitarEdit = false;    
            this.getPlanilla(this.defaultFilter);
            this.matDrawer.close();
        }); 
  }
//Funcion para reenviar el correo a la persona que realizo la reserva
    enviarCorreo(){
      let body: any = this.formReserva.value;
      this.reserveService.getCorreo(this.reservaSeleccionada.id, body)
      .subscribe(
        data => {
          console.log('informacion:',data);
        });
    }

}

export const data = [];
