import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivityService } from '../services/activity.service';

@Component({
  selector: 'app-activity-division',
  templateUrl: './activity-division-form.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityDivisionFormComponent implements OnInit {
  form: FormGroup;
  model: any;
  
  constructor(private fb: FormBuilder,
    public activityService: ActivityService,
    private dialogRef: MatDialogRef<ActivityDivisionFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data = null) {
    this.model = this.data.model ? this.data.model : {};
    
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      nombre: [this.model ? this.model.nombre : '', Validators.required],
      cantidad: [this.model ? this.model.cantidad : '', Validators.required],
      secuencia: [this.model ? this.model.secuencia : '', Validators.required],
      activo: [this.model ? this.model.activo : true, Validators.required]
    });
  }

  close(action: any) {
    this.dialogRef.close(action);
  }

  save() {
    if (this.model.id) {
      this.activityService.updateActivityDivision(this.model.id, this.form.value)
        .subscribe(
          data => {
            this.close(null);
          });
    } else {
      this.activityService.addActivityDivision(this.data.activity.id, this.form.value)
        .subscribe(
          data => {
            this.close(null);
          });
    }
  }
}
