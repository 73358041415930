import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '../interceptor/settings.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(
        private http: HttpClient,
        private settings: SettingsService
    ) { }

    /**
     * Recupera la lista de notificaciones del sistema
     */
    getNotifications(body: any): Observable<any> {
        return this.http.post(`${this.settings.URL_BASE}notificacion/list`, body);
    }
    /**
     * Recupera la cantidad de notificaciones del sistema
     */
    getCantNotifications(): Observable<any>{
        return this.http.get(`${this.settings.URL_BASE}notificacion/contar`);
    }

    readNotification(id:any): Observable<any>{
        return this.http.put(`${this.settings.URL_BASE}notificacion/${id}/leer`,{});
    }
}