import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivityService } from '../services/activity.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-activity',
  templateUrl: './activity-form.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityFormComponent implements OnInit {
  form: FormGroup;
  model: any;
  list: any[];
  iconList: any[] = [
    { id: 1, nombre: 'eco' },
    { id: 2, nombre: 'restaurant_menu' },
    { id: 3, nombre: 'pool' },
    { id: 4, nombre: 'restaurant' },
    { id: 5, nombre: 'fastfood' },
    { id: 6, nombre: 'directions_bike' },
    { id: 7, nombre: 'spa' },
    { id: 8, nombre: 'kayaking' },
    { id: 9, nombre: 'sports_soccer' }
    
  ]
  

  constructor(private fb: FormBuilder,
    public activityService: ActivityService,
    private dialogRef: MatDialogRef<ActivityFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data = null) {
    this.model = this.data.model ? this.data.model : {};
    this.getActivityType();
    setTimeout(()=>{
      //renombrar labels del editor de texto kolkov
      //boton atras
      const undoL  = document.getElementsByClassName('fa-undo');
      undoL[0].parentElement.title = 'Atras';

       //boton negrita
       const boldL  = document.getElementsByClassName('fa-bold');
       boldL[0].parentElement.title = 'Negrita';

        //boton cursiva
        const cursivaL  = document.getElementsByClassName('fa-italic');
        cursivaL[0].parentElement.title = 'Cursiva';

        //boton tachar
        const tachaL  = document.getElementsByClassName('fa-strikethrough');
        tachaL[0].parentElement.title = 'Tachado';

        //boton adelante
        const redoL  = document.getElementsByClassName('fa-repeat');
        redoL[0].parentElement.title = 'Adelante';
        
        //boton subrayado
        const underlineL  = document.getElementsByClassName('fa-underline');
        underlineL[0].parentElement.title = 'Subrayado';

         //boton color de texto
         const textcolorL  = document.getElementsByClassName('fa-font');
         textcolorL[0].parentElement.title = 'Color de texto';

         //boton color de fondo
         const backcolorL  = document.getElementsByClassName('fa-font');
         backcolorL[1].parentElement.title = 'Color de fondo';

          //boton viñeta
          const vinhetaL  = document.getElementsByClassName('fa-list-ul');
          vinhetaL[0].parentElement.title = 'Viñeta';

          //boton ordenar lista
          const orderL  = document.getElementsByClassName('fa-list-ol');
          orderL[0].parentElement.title = 'Ordenar Lista';

           //boton remove
           const removeL  = document.getElementsByClassName('fa-remove');
           removeL[0].parentElement.title = 'Limpiar formato';
 

    },200)
    }
  

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      nombre: [this.model ? this.model.nombre : '', Validators.required],
      icono: [this.model ? this.model.icono : '', Validators.required],
      actividadTipoId: [this.model ? this.model.actividadTipoId : '', Validators.required],
      activo: [this.model ? this.model.activo : true, Validators.required],
      advertenciaCorreo: [this.model ? this.model.advertenciaCorreo : '', Validators.required],
      visibilidadExterior: [this.model ? this.model.visibilidadExterior : true, Validators.required],
    });
  }
  //editor de texto kolkov, ajustes a su configuracion
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '180px',
      maxHeight: 'auto',
      width: '850px',
      minWidth: '850px',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Escriba su texto aqui',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
     //ocultar botones
    toolbarHiddenButtons:[
      ['superscript','justifyLeft','justifyRight','justifyCenter','justifyFull','insertHorizontalRule','toggleEditorMode','strikeThrough','insertUnorderedList','insertOrderedList'],
      ['fontSize','backgroundColorPicker','subscript','insertImage','insertVideo','outdent','indent','align-left','clearFormatting','link','unlink']
    ],
    sanitize: true,
    toolbarPosition: 'top',
    
};

  close(action: any) {
    this.dialogRef.close(action);
  }

  getActivityType() {
    this.activityService.getActivityTypes()
      .subscribe(
        data => {
          this.list = data;
        });
  }

  save() {
    if (this.model.id) {
      this.activityService.updateActivity(this.model.id, this.form.value)
        .subscribe(
          data => {
            this.close(null);
          });
    } else {
      this.activityService.addActivity(this.form.value)
        .subscribe(
          data => {
            this.close(null);
          });
    }
  }

 
}
