import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '../interceptor/settings.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private http: HttpClient,
        private settings: SettingsService
    ) { }

    /**
     * Recupera la lista de usuarios del sistema
     */
    getUsers(body: any): Observable<any> {
        return this.http.post(`${this.settings.URL_BASE}administracion/usuario/list`, body);
    }

    /**
     * Recupera la lista de usuarios del sistema
     */
    getProfiles(): Observable<any> {
        return this.http.get(`${this.settings.URL_BASE}administracion/usuario/rol`);
    }

    /**
    * Método para agregar un usuario de sistema
    * @param body: datos de usuario para dar de alta
    */
    addUser(body: any) {
        return this.http.post(`${this.settings.URL_BASE}administracion/usuario`, body);
    }

    /**
    * Método para eliminar un usuario de sistema
    * @param id: identificador del usuario
    */
    deleteUser(id: any) {
        return this.http.delete(`${this.settings.URL_BASE}administracion/usuario/${id}`);
    }
    
    /**
    * Método para actualizar un usuario de sistema
    * @param id: identificador del usuario
    */
    updateUser(id: any, body: any) {
        return this.http.put(`${this.settings.URL_BASE}administracion/usuario/${id}`, body);
    }

}
