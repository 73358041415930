<div class="image-container set-full-height">
  <div class="container">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="wizard-container">
          <div class="card wizard-card" data-color="primary">
            <div class="wizard-header">
              <div class="row">
                <div class="col-md-4 col-sm-2"></div>
                <div class="col-md-4 col-sm-8">
                  <img src="../../assets/images/los-lagos-sombra.png">
                </div>
                <div class="col-md-4 col-sm-2"></div>
              </div>
              <h3 class="wizard-title"> Servicio de Reservas </h3>
              <h5>Registro de Acompañantes</h5>
            </div>
            <div class="tab-content">
              <div fxLayout="column" fxLayoutGap="20px">
                <div class="row">
                  <div class="col-sm-4">
                  </div>
                  <div class="col-sm-4">
                    <div class="choice active">
                      <div class="icon">
                        <i class="material-icons">{{icono}}</i>
                      </div>
                      <h6>{{actividad}}</h6>
                    </div>
                  </div>
                </div>
                <div class="form-row table-container" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px">
                  <table mat-table [dataSource]="dataSource">

                    <!-- cedula -->
                    <ng-container matColumnDef="cedula">
                      <th mat-header-cell *matHeaderCellDef> Cédula N° </th>
                      <td mat-cell *matCellDef="let e"> {{e.cedula}} </td>
                    </ng-container>

                    <!-- nombre -->
                    <ng-container matColumnDef="nombre">
                      <th mat-header-cell *matHeaderCellDef> Nombre </th>
                      <td mat-cell *matCellDef="let e"> {{e.nombre}} </td>
                    </ng-container>

                    <!-- apellido -->
                    <ng-container matColumnDef="apellido">
                      <th mat-header-cell *matHeaderCellDef> Apellido </th>
                      <td mat-cell *matCellDef="let e"> {{e.apellido}} </td>
                    </ng-container>

                    <!-- celular -->
                    <ng-container matColumnDef="celular">
                      <th mat-header-cell *matHeaderCellDef> Celular </th>
                      <td mat-cell *matCellDef="let e"> {{e.celular}} </td>
                    </ng-container>

                    <!-- correo -->
                    <ng-container matColumnDef="correo">
                      <th mat-header-cell *matHeaderCellDef> Correo </th>
                      <td mat-cell *matCellDef="let e"> {{e.email}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>