 <!-- Grafico de barra vertical -->
  <div class="contenedorGraficos"  fxLayout="row" fxLayoutGap="200px" >
    <div *ngIf="bandera === false">
      <ngx-charts-bar-vertical-stacked
        [view]="vista"
        [scheme]="colorScheme"
        [results]="datos"
        [gradient]="gradiante3"
        [xAxis]="showXAxis3"
        [yAxis]="showYAxis3"
        [legend]="mostrarLegend3"
        [legendTitle]="tituloLegend3"
        [legendPosition]="posicionLegend3"
        [showXAxisLabel]="showXAxisLabel3"
        [showYAxisLabel]="showYAxisLabel3"
        [yAxisLabel]="yAxisLabel3"
        [barPadding]="espacioBarras">
      </ngx-charts-bar-vertical-stacked>
    </div> 
  <div class="contenedorSinDatos" *ngIf="bandera === true">
    <div class="sinDatos"> 
      No hay datos para mostrar
    </div>
  </div>
  </div>  

  