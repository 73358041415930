//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';
import { MaterialModule } from '../core/material.module';
import { DirectivesModule } from '../directives/directives.module';
import { UserFormComponent } from './user-form.component';
import { userRouting } from './user.routing';
import { UserListComponent } from './user-list.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoaderModule } from '../components/loader/loader.module';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        userRouting,
        ComponentsModule,
        DirectivesModule,
        FlexLayoutModule,
        LoaderModule
    ],
    declarations: [
        UserFormComponent,
        UserListComponent
    ],
    providers: [
        
    ],
    exports: [
        UserFormComponent,
        UserListComponent
    ]
})

export class UserModule { }