import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivityService } from '../services/activity.service';

@Component({
  selector: 'app-bloque-form',
  templateUrl: './bloque-form.component.html',
  styleUrls: ['./disponibilidad-bloque-actividad.component.scss']
})
export class BloqueFormComponent implements OnInit {
  form: FormGroup;

  days: any[] = [
    { key: 'L', value: 'LUN', check: true },
    { key: 'M', value: 'MAR', check: true },
    { key: 'MI', value: 'MIE', check: true },
    { key: 'J', value: 'JUE', check: true },
    { key: 'V', value: 'VIE', check: true },
    { key: 'S', value: 'SAB', check: true },
    { key: 'D', value: 'DOM', check: true },
  ]

  constructor(
    private dialogRef: MatDialogRef<BloqueFormComponent>,
    private fb: FormBuilder,
    private activityService: ActivityService,
    @Inject(MAT_DIALOG_DATA) public data = null
  ) {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      fechaDesde: ['', Validators.required],
      fechaHasta: ['', Validators.required],
    });
  }

  ngOnInit() { }

  close(action: any) {
    this.dialogRef.close(action);
  }

  /**
   * Método para agregar bloque en un rango de fechas por actividad
   */
  saveBlock() {
    let list= [];
    this.data.filter((e) => e.check == true && e.valor > 0).forEach( element => {
      list.push({tipo: element.id, cantidad: element.valor});
    });
    let body = this.form.value;
    body.actividad = list;
    let l = this.days.filter((e) => e.check == true);
    body.diasDisponibilidad = l;
    this.activityService.addBlock(body)
      .subscribe(
        data => {
          this.buildForm();
          this.close(null);
        });

  }

  /**
   * Método para seleccionar un registro del listado de días
   * @param item registro seleccionado
   */
  setChange(item: any) {
    item.check = !item.check;
  }

  /**
   * Método para seleccionar un registro del listado de actividades
   * @param item registro seleccionado
   */
   selectItem(item: any, event: any) {
    item.check = !item.check;
  }

  /**
   * Método para habilitar botón de guardar, si seleccionó al menos una actividad y cargó cantidad
   * @returns true|false
   */
  validation() {
    let list = this.data.filter((e) => e.check == true && e.valor > 0);
    return list.length > 0;
  }

  /**
   * Método para setear la cantidad de disponibilidad para una actividad seleccionada
   * @param item registro al cual se le agrega la cantidad
   * @param event evento input donde se ingresa el valor
   */
  cantUpdated(item: any, event: any) {
    item.valor = event.target.value;  
  }

}
