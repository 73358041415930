//angular
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../components/guards/auth.guard';
import { RestaurantAssignmentComponent } from './reserve-assignment.component';
import { RestaurantComponent } from './restaurant.component';

const appRoutes: Routes = [
    { path: 'configuracion', component: RestaurantComponent, canActivate: [AuthGuard] },
    { path: 'asignacion', component: RestaurantAssignmentComponent, canActivate: [AuthGuard] }
]
export const restaurantRouting = RouterModule.forChild(appRoutes);