import { ThrowStmt } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GraficosService } from 'src/app/services/graficos.service';
@Component({
  selector: 'app-grafico-personas',
  templateUrl: './grafico-personas.component.html',
  styleUrls: ['./grafico-personas.component.scss']
})
export class GraficoPersonasComponent implements OnInit {

  constructor(public graficosService: GraficosService) {  
  }

  @Input() fecha: string;
  datos = []
  bandera: boolean;
 //opciones de grafico de barras vertical stackeado
  vista: any[] = [undefined, undefined];
  showXAxis3: boolean = true;
  showYAxis3: boolean = true;
  gradiante3: boolean = true;
  mostrarLegend3: boolean = true;
  showXAxisLabel3: boolean = true;
  showYAxisLabel3: boolean = true;
  yAxisLabel3: string = 'Cantidad de personas';
  posicionLegend3: string = "below";
  espacioBarras: number = 50;
  tituloLegend3: string = 'Personas por dia';

  colorScheme = {
    domain: ['#0390be', '#ffcc00', '#80c31b', '#124b45']
  };

  //metodo para traer los datos para el grafico de pie
  getDatosBarraCombinada(fechaInput){
    const date = new Date(fechaInput)
    const body = {
      fecha: date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
    }
    this.graficosService.getPersonas(body).subscribe(
      data =>{
        this.datos = data;
        if(data[0].sinDatos == true){
          this.bandera = true;
        }else{
          this.bandera = false;
        };  
      })
    }

  ngOnInit(): void {
    this.getDatosBarraCombinada(new Date());
    }

}
