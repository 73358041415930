<div class="">
  <mat-toolbar class="dialog-toolbar" color="primary" fxLayout="row" fxLayoutAlign="space-between center">
    <div>{{data.title}}</div>
    <div>
      <button mat-icon-button (click)="close('close')">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <br>

  <div mat-dialog-content [formGroup]="form" fxLayout="column" fxLayoutGap="20px" fxLayoutGap="20px" fxLayout.xs="column" fxLayout.sm="column">

    <div class="form-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px" fxLayoutGap="20px" fxLayout.xs="column" fxLayout.sm="column">
      <mat-form-field appearance="outline">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="nombre">
        <div class="text-center">
          <small class="text-danger"
            *ngIf="form.controls.nombre.touched && form.controls.nombre.errors?.required">El nombre
            es requerido</small>
        </div>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Apellido</mat-label>
        <input matInput formControlName="apellido">
        <div class="text-center">
          <small class="text-danger"
            *ngIf="form.controls.apellido.touched && form.controls.apellido.errors?.required">El apellido
            es requerido</small>
        </div>
      </mat-form-field>
    </div>

    <div class="form-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px" fxLayoutGap="20px" fxLayout.xs="column" fxLayout.sm="column">
      <mat-form-field appearance="outline">
        <mat-label>N° Cédula</mat-label>
        <input matInput formControlName="cedula">
        <div class="text-center">
          <small class="text-danger"
            *ngIf="form.controls.cedula.touched && form.controls.cedula.errors?.required">El número de cédula
            es requerido</small>
        </div>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Número de Celular</mat-label>
        <input matInput type="number" formControlName="celular">
        <div class="text-center">
          <small class="text-danger"
            *ngIf="form.controls.celular.touched && form.controls.celular.errors?.required">El número de celular
            es requerido</small>
        </div>
      </mat-form-field>
    </div>

    <div class="form-row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" fxLayoutGap="20px" fxLayout.xs="column" fxLayout.sm="column">
    <mat-form-field appearance="outline">
      <mat-label>Correo electrónico</mat-label>
      <input matInput type="email" formControlName="email">
      <div class="text-center">
        <small class="text-danger"
          *ngIf="form.controls.email.touched && form.controls.email.errors?.required">El correo electrónico
          es requerido</small>
      </div>
    </mat-form-field>
    </div>

  </div>
</div>

<br>

<div class="btn-action-container">

  <button class="btn btn-cancel" (click)="close('close')">
    <mat-icon>close</mat-icon>
    Cerrar
  </button>
  <button class="btn btn-general" (click)="save()" [disabled]="form.invalid">
    <mat-icon>save</mat-icon>
    Guardar
  </button>
</div>