import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalComponent } from '../components/modal/modal.component';
import { PersonaFormComponent } from './persona-form.component';
import { PersonaService } from '../services/persona.service';
import { SettingsService } from '../interceptor/settings.service';
import { Page } from '../models/page';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-persona-list',
  templateUrl: './persona-list.component.html',
  styleUrls: ['./drag-drop-reserve.component.scss'],
})
export class PersonaListComponent implements OnInit {
  personaList = [];
  displayedColumns: string[] = ['cedula', 'nombre', 'apellido', 'celular', 'email', 'estado', 'remover', 'cambiar'];
  public dataSource = new MatTableDataSource<any>();
  public pagination: Page = new Page();
  public filter: any = {};
  paginatorRange = this.settingService.PAGINATOR_RANGE;
  form: FormGroup;

  constructor(
    private personaService: PersonaService,
    private dialogRef: MatDialogRef<PersonaListComponent>,
    private dialog: MatDialog,
    public settingService: SettingsService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data = null,
  ) { }

  ngOnInit() {

    this.getParticipantes(this.data, 1);
    this.buildFilterForm();
  }

  /**
   * Construye el formulario
   */
  buildFilterForm() {
    this.form = this.fb.group({
      busquedaGlobal: [''],
    });
  }

  getParticipantes(data = null, page: any) {
    this.filter['pagina'] = page;
    this.filter['texto'] = data.busquedaGlobal;
    this.personaService.getParticipantes(data.id, this.filter).subscribe((data) => {

      if (data != null) {
        this.dataSource.data = data['data'] as any[];
        this.pagination = data['pagination'];
      } else {
        this.pagination = new Page();
      }
    });
  }

  buscarParticipante() {
    this.data.busquedaGlobal = this.form.controls.busquedaGlobal.value
    this.getParticipantes(this.data, 1);
  }

  openForm() {
    const conf: MatDialogConfig = {
      data: this.data,
      minWidth: '800px',
      // minHeight: '700px'
    };
    const dialog = this.dialog.open(PersonaFormComponent, conf);
    dialog.afterClosed().subscribe((resp) => {
      this.getParticipantes(this.data, 1);
    });
  }

  close() {
    this.dialogRef.close();
  }

  /**
  * Método para eliminar un participante de una reserva
  * @param item: participante a ser eliminado
  */
  deleteParticipante(item: any) {
    const modalConfig: MatDialogConfig = {
      data: {
        title: 'Eliminar Participante',
        msg: `¿Confirma que desea eliminar al participante <b>${item.nombre} ${item.apellido}</b>?`,
        acceptBtnMsg: 'Eliminar',
      }
    };

    //pantalla de confirmación previa a la eliminación
    const dialog = this.dialog.open(ModalComponent, modalConfig);

    dialog.afterClosed().subscribe((resp) => {
      if (resp == 'confirm') {
        this.personaService.deleteParticipant(item.id)
          .subscribe(
            data => {
              this.getParticipantes(this.data, 1);
            });
      }

    });
  }

  /**
  * Método para actualizar estado de un participante de una reserva
  * @param item: participante a ser actualizado
  */
  updateParticipant(item: any) {
    const modalConfig: MatDialogConfig = {
      data: {
        title: 'Actualizar Participante',
        msg: `¿Confirma que desea actualizar el estado del participante <b>${item.nombre} ${item.apellido}</b>?`,
        acceptBtnMsg: 'Actualizar',
      }
    };

    //pantalla de confirmación previa a la actualización
    const dialog = this.dialog.open(ModalComponent, modalConfig);

    dialog.afterClosed().subscribe((resp) => {
      if (resp == 'confirm') {
        this.personaService.updateParticipant(item.id)
          .subscribe(
            data => {
              item.confirmado = true;
              //this.getParticipantes(this.data);
            });
      }

    });
  }

  /**
   * Método para realizar acción según evento del paginado.
   * @param event evento del paginado
   */
  public getServerData(event?: PageEvent) {
    let page = 1;

    page = event.pageIndex + 1;

    if (event.pageIndex === 0 && event.previousPageIndex === 1) {
      page = 1;
    }

    this.filter.cantidad_pagina = event.pageSize;
    this.getParticipantes(this.data, page);
    return event;
  }

}
