import { ThrowStmt } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GraficosService } from 'src/app/services/graficos.service';
@Component({
  selector: 'app-grafico-reservas',
  templateUrl: './grafico-reservas.component.html',
  styleUrls: ['./grafico-reservas.component.scss']
})
export class GraficoReservasComponent implements OnInit {

  constructor(public graficosService: GraficosService) {
  }

  @Input() actividades: any;
  @Input() periodo: any;

  //opciones de grafico de barras vertical stackeado
  info: any;
  datos = [];
  multi: any[];
  vista: any[] = [undefined, undefined];
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'año';
  yAxisLabel: string = 'Reservas';
  timeline: boolean = true;
  legendPosition: string = 'below';
  tituloLegend: string = 'Reservas';

  colorScheme = {
    domain: ['#0390be', '#ffcc00', '#80c31b', '#124b45']
  };

  getDatosLinea(datosPeriodo, datosActividad) {
    const body = {
      periodo: datosPeriodo,
      actividadIds: datosActividad
    }
    this.graficosService.getReservas(body).subscribe(
      data => {
        this.datos = [...data];
      })
  }

  ngOnInit(): void {
    this.getDatosLinea(this.periodo, this.actividades);
  }

}
