import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '../interceptor/settings.service';

@Injectable({
    providedIn: 'root'
})
export class PersonaService {

    constructor(
        private http: HttpClient,
        private settings: SettingsService
    ) { }

    /**
     * Recupera la lista de participantes
     * @param actividadId id de la actividad a recuperar participantes
     */
    getParticipantes(actividadId: number, body: any): Observable<any> {
        return this.http.post(`${this.settings.URL_BASE}reserva/actividad/${actividadId}/participante/list`, body);
    }
    /**
    * Método para agregar un acompañante de un cliente a su reserva
    * @param id: identificador de la reserva
    * @param body: datos personales del acompañante
    */
    addParticipant(id: any, body: any) {
        return this.http.post(this.settings.URL_BASE + `reserva/actividad/${id}/participante`, body);
    }
    /**
    * Método para eliminar un participante de una reserva
    * @param id: identificador del participante
    */
    deleteParticipant(id: any) {
        return this.http.delete(this.settings.URL_BASE + `reserva/actividad/participante/${id}`);
    }
    /**
    * Método para actualizar el estado de un participante de una reserva
    * @param id: identificador del participante
    */
    updateParticipant(id: any) {
        return this.http.put(this.settings.URL_BASE + `reserva/actividad/participante/${id}/confirmar`, {});
    }

}
