import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ModalComponent } from '../components/modal/modal.component';
import { SettingsService } from '../interceptor/settings.service';
import { Page } from '../models/page';
import { ActivityService } from '../services/activity.service';
import { ActivityDivisionFormComponent } from './activity-division-form.component';
import { ActivityFormComponent } from './activity-form.component';
import { ActivityHorarioFormComponent } from './activity-horario-form.component';

@Component({
  selector: 'app-activity',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ActivityListComponent implements OnInit {
  displayedColumns: string[] = ['nombre','visibilidadExterior', 'activo', 'actividadTipo', 'usuarioAlta', 'fechaCreacion','advertenciaCorreo', 'remover', 'editar', 'division', 'horario'];
  public dataSource = new MatTableDataSource<any>();
  public pagination: Page = new Page();
  public paginationDivision: Page = new Page();
  public filter: any = {};
  public filterDivision: any = {};
  paginatorRange = this.settingService.PAGINATOR_RANGE;
  stateList: any[];
  activityDivisionList: any[] = [];
  activityScheduleList: any[] = [];
  public filterSchedule: any = {};
  public paginationSchedule: Page = new Page();

  // paginador del template
  @ViewChild(MatPaginator) paginator: MatPaginator;

  form: FormGroup;
  formDivision: FormGroup;
  expandedElement: any | null;
  showItem: any;
  showMore = false;

  constructor(public fb: FormBuilder, public activityService: ActivityService,
    public settingService: SettingsService, private dialog: MatDialog,) {
    this.stateList = [
      { value: '', nombre: 'TODOS' },
      { value: true, nombre: 'SÍ' },
      { value: false, nombre: 'NO' },
    ]
  }

  ngOnInit() {
    this.initForm();
    this.getList(1);
  }

  initForm() {
    this.form = this.fb.group({
      nombre: ['', Validators.nullValidator],
      activo: ['', Validators.nullValidator]
    });

    this.formDivision = this.fb.group({
      nombre: ['', Validators.nullValidator],
      activo: ['', Validators.nullValidator]
    });
  }

  getList(page: number) {
    this.filter = this.form.value;
    this.filter['pagina'] = page;
    this.activityService.getActivities(this.filter)
      .subscribe(
        data => {
          if (data != null) {
            this.dataSource.data = data['data'] as any[];
            this.pagination = data['pagination'];
          } else {
            this.pagination = new Page();
          }
        });
  }

  /**
 * Método para realizar acción según evento del paginado.
 * @param event evento del paginado
 */
  public getServerData(event?: PageEvent) {
    let page = 1;

    page = event.pageIndex + 1;

    if (event.pageIndex === 0 && event.previousPageIndex === 1) {
      page = 1;
    }

    this.filter.cantidad_pagina = event.pageSize;
    this.getList(page);
    return event;
  }

  /**
   * Método para realizar acción según evento del paginado de tabla de sub actividades
   * @param event evento del paginado
   */
  public getServerDataSubActivity(event?: PageEvent, item?: any) {
    let page = 1;

    page = event.pageIndex + 1;

    if (event.pageIndex === 0 && event.previousPageIndex === 1) {
      page = 1;
    }

    this.filterDivision.cantidad_pagina = event.pageSize;
    this.getDivisionActivities(item.id, page);
    return event;
  }

  /**
   * Método para realizar acción según evento del paginado de tabla de horarios de actividades
   * @param event evento del paginado
   */
  public getServerDataHorario(event?: PageEvent, item?: any) {
    let page = 1;

    page = event.pageIndex + 1;

    if (event.pageIndex === 0 && event.previousPageIndex === 1) {
      page = 1;
    }

    this.filterDivision.cantidad_pagina = event.pageSize;
    this.getScheduleActivities(item.id, page);
    return event;
  }
  /**
  * Método para eliminar una actividad del sistema
  * @param item: actividad a ser eliminada
  */
  deleteActivity(item: any) {
    const modalConfig: MatDialogConfig = {
      data: {
        title: 'Eliminar Actividad',
        msg: `¿Confirma que desea eliminar la actividad <b>${item.nombre}</b>?`,
        acceptBtnMsg: 'Eliminar',
      }
    };

    //pantalla de confirmación previa a la eliminación
    const dialog = this.dialog.open(ModalComponent, modalConfig);

    dialog.afterClosed().subscribe((resp) => {
      if (resp == 'confirm') {
        this.activityService.deleteActivity(item.id)
          .subscribe(
            data => {
              this.getList(1);
            });
      }

    });
  }

  /**
  * Método para actualizar datos de una actividad
  * @param item: actividad a ser actualizada
  */
  updateActivity(item: any) {
    const conf: MatDialogConfig = {
      data: {
        model: item,
        title: 'Editar Actividad'
      },
      minWidth: '800px',
      // minHeight: '700px'
    };
    const dialog = this.dialog.open(ActivityFormComponent, conf);
    dialog.afterClosed().subscribe((resp) => {
      if (resp != 'close') {
        this.getList(1);
      }
    });
  }

  add() {
    const conf: MatDialogConfig = {
      data: {
        title: 'Agregar Actividad'
      },
      minWidth: '800px',
      // minHeight: '700px'
    };
    const dialog = this.dialog.open(ActivityFormComponent, conf);
    dialog.afterClosed().subscribe((resp) => {
      if (resp != 'close') {
        this.getList(1);
      }
    });
  }
  /**
   * Método para mostrar u ocultar las divisiones de la actividad seleccionada
   * @param element registro seleccionado de la tabla de actividades
   */
  show(element: any, item) {
    this.showItem = item;
    this.expandedElement = this.expandedElement === element ? null : element;
    if (this.showItem == 'Division') {
      this.getDivisionActivities(element.id, 1);
    } else {
      this.getScheduleActivities(element.id, 1);
    }

  }

  /**
  * Método para obtener las divisiones de la actividad seleccionada
  */
  getDivisionActivities(activityId: any, page: any) {
    this.filterDivision = this.formDivision.value;
    this.filterDivision.pagina = page;
    this.activityService.getDivisionActivities(activityId, this.filterDivision)
      .subscribe(
        data => {
          if (data != null) {
            this.activityDivisionList = data['data'];
            this.paginationDivision = data['pagination'];
          } else {
            this.paginationDivision = new Page();
            this.activityDivisionList = [];
          }
        });
  }

  addDivision(activity: any) {
    const conf: MatDialogConfig = {
      data: {
        activity: activity,
        title: 'Agregar Sub Actividad'
      },
      minWidth: '800px',
      // minHeight: '700px'
    };
    const dialog = this.dialog.open(ActivityDivisionFormComponent, conf);
    dialog.afterClosed().subscribe((resp) => {
      if (resp != 'close') {
        this.getDivisionActivities(activity.id, 1);
      }
    });
  }

  /**
  * Método para eliminar una división de actividad del sistema
  * @param item: división de actividad a ser eliminada
  */
  deleteActivityDivision(activity: any, item: any) {
    const modalConfig: MatDialogConfig = {
      data: {
        title: 'Eliminar Sub Actividad',
        msg: `¿Confirma que desea eliminar la sub actividad <b>${item.nombre}</b>?`,
        acceptBtnMsg: 'Eliminar',
      }
    };

    //pantalla de confirmación previa a la eliminación
    const dialog = this.dialog.open(ModalComponent, modalConfig);

    dialog.afterClosed().subscribe((resp) => {
      if (resp == 'confirm') {
        this.activityService.deleteActivityDivision(item.id)
          .subscribe(
            data => {
              this.getDivisionActivities(activity.id, 1);
            });
      }
    });
  }


  /**
  * Método para actualizar datos de una división de actividad
  * @param item: división de actividad a ser actualizada
  */
  updateActivityDivision(activity: any, item: any) {
    const conf: MatDialogConfig = {
      data: {
        model: item,
        activity: activity,
        title: 'Editar Sub Actividad'
      },
      minWidth: '800px',
      // minHeight: '700px'
    };
    const dialog = this.dialog.open(ActivityDivisionFormComponent, conf);
    dialog.afterClosed().subscribe((resp) => {
      if (resp != 'close') {
        this.getDivisionActivities(activity.id, 1);
      }
    });
  }
  /**
  * Método para obtener las divisiones de la actividad seleccionada
  */
  getScheduleActivities(activityId: any, page: any) {
    this.filterSchedule.pagina = page;
    this.activityService.getScheduleActivities(activityId, this.filterSchedule)
      .subscribe(
        data => {
          if (data != null) {
            this.activityScheduleList = data['data'];
            this.paginationSchedule = data['pagination'];
          } else {
            this.paginationSchedule = new Page();
            this.activityScheduleList = [];
          }
        });
  }

  addSchedule(activity: any) {
    const conf: MatDialogConfig = {
      data: {
        activity: activity,
        title: 'Agregar Horario de Actividad'
      },
      minWidth: '800px',
      // minHeight: '700px'
    };
    const dialog = this.dialog.open(ActivityHorarioFormComponent, conf);
    dialog.afterClosed().subscribe((resp) => {
      if (resp != 'close') {
        this.getScheduleActivities(activity.id, 1);
      }
    });
  }

  /**
  * Método para eliminar un horario de actividad del sistema
  * @param item: horario de actividad a ser eliminada
  */
  deleteActivitySchedule(activity: any, item: any) {
    const modalConfig: MatDialogConfig = {
      data: {
        title: 'Eliminar Horario de Actividad',
        msg: `¿Confirma que desea eliminar el horario de la actividad <b>${activity.nombre}</b>?`,
        acceptBtnMsg: 'Eliminar',
      }
    };

    //pantalla de confirmación previa a la eliminación
    const dialog = this.dialog.open(ModalComponent, modalConfig);

    dialog.afterClosed().subscribe((resp) => {
      if (resp == 'confirm') {
        this.activityService.deleteActivitySchedule(item.id)
          .subscribe(
            data => {
              this.getScheduleActivities(activity.id, 1);
            });
      }
    });
  }


  /**
  * Método para actualizar datos de un horario de actividad
  * @param item: horario de actividad a ser actualizada
  */
  updateActivitySchedule(activity: any, item: any) {
    const conf: MatDialogConfig = {
      data: {
        model: item,
        activity: activity,
        title: 'Editar Horario de Actividad'
      },
      minWidth: '800px',
      // minHeight: '700px'
    };
    const dialog = this.dialog.open(ActivityHorarioFormComponent, conf);
    dialog.afterClosed().subscribe((resp) => {
      if (resp != 'close') {
        this.getScheduleActivities(activity.id, 1);
      }
    });
  }

}
