import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../interceptor/session.service';
import interact from 'interactjs';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ModalComponent } from '../components/modal/modal.component';
import { RestaurantService } from '../services/restaurant.service';
import { MatDrawer } from '@angular/material/sidenav';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.scss']
})
export class RestaurantComponent implements OnInit {

  mesas: any = [];

  seccion: any = [];
  title: any;
  titleText: any;

  selectTable = null;

  // we create an object that contains coordinates 
  menuTopLeftPosition = { x: '0', y: '0' }

  // reference to the MatMenuTrigger in the DOM 
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  currentDate: any;

  // drawer
  @ViewChild(MatDrawer) matDrawer: MatDrawer;
  form: FormGroup;

  currentZone: any;
  dragAndDrop: boolean = false;

  isSelected: boolean = false;

  constructor(private router: Router,
    private datepipe: DatePipe,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public appComponent: AppComponent,
    private sessionService: SessionService,
    private restaurantService: RestaurantService) {
    this.currentDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy hh:mm:ss')
  }

  ngOnInit(): void {
    this.appComponent.showMenu = false;
    this.getZoneTypeList();
    this.buildForm(null);
    var a = this;
    interact('.resize-drag')
      .resizable({
        // resize from all edges and corners
        edges: { left: true, right: true, bottom: true, top: true },

        listeners: {
          move(event) {
            a.dragAndDrop = true;
            var target = event.target
            var x = (parseFloat(target.getAttribute('data-x')) || 0)
            var y = (parseFloat(target.getAttribute('data-y')) || 0)

            // update the element's style
            target.style.width = event.rect.width + 'px'
            target.style.height = event.rect.height + 'px'

            // translate when resizing from top or left edges
            x += event.deltaRect.left
            y += event.deltaRect.top

            target.style.webkitTransform = target.style.transform =
              'translate(' + x + 'px,' + y + 'px)'

            target.setAttribute('data-x', x)
            target.setAttribute('data-y', y)
            //target.textContent = Math.round(event.rect.width) + '\u00D7' + Math.round(event.rect.height)
          }
        },
        modifiers: [
          // keep the edges inside the parent
          interact.modifiers.restrictEdges({
            outer: 'parent'
          }),

          // minimum size
          interact.modifiers.restrictSize({
            min: { width: 120, height: 120 }
          })
        ],

        inertia: true
      })
      .draggable({
        listeners: { move: dragMoveListener },
        inertia: true,
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true
          })
        ]
      })
    function dragMoveListener(event) {
      a.dragAndDrop = true;
      var target = event.target,
        // keep the dragged position in the data-x/data-y attributes
        x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
        y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

      // translate the element
      target.style.webkitTransform =
        target.style.transform =
        'translate(' + x + 'px, ' + y + 'px)';

      // update the posiion attributes
      target.setAttribute('data-x', x);
      target.setAttribute('data-y', y);
    }

  }

  /**
   * Método para actualizar atributo html de un registro dibujado en pantalla
   * @param element elemento DOM cuyo atributo data-x/data-y será actualizado
   * @param data el registro renderizado
   */
  updateElement(element, data) {
    //console.log(element)
    var target = element,
      // keep the dragged position in the data-x/data-y attributes
      x = (parseFloat(target.getAttribute('data-x')) || 0) + data.posicionX,
      y = (parseFloat(target.getAttribute('data-y')) || 0) + data.posicionY;

    // update the element's style
    target.style.width = data.ancho + 'px'
    target.style.height = data.alto + 'px'
    // translate the element
    target.style.webkitTransform =
      target.style.transform =
      'translate(' + x + 'px, ' + y + 'px)';

    // update the posiion attributes
    target.setAttribute('data-x', x);
    target.setAttribute('data-y', y);
  }

  /**
   * Método para inicializar el formulario utilizado para crear o actualizar una mesa
   * @param model el objeto que representa una mesa
   */
  buildForm(model: any) {
    this.form = this.fb.group({
      nombre: [model ? model.nombre : '', Validators.required],
      cantidad: [model ? model.cantidad : '', Validators.required]
    });
  }

  /**
   * Método para agregar una mesa en el mapa
   */
  showForm(text: any) {
    //console.log(text)
    if (text == 'Agregar') {
      this.selectTable = null;
      this.isSelected = true;
    } else {
      if (text == null) {
        this.isSelected = false;
      }
      //this.matDrawer.toggle();
    }

    this.titleText = text;
    this.buildForm(this.selectTable);
    //this.matDrawer.toggle();
  }
  /**
   * Método para agregar o actualizar una mesa en el mapa
   */
  add() {
    if (this.selectTable) {
      // let posX = document.getElementById(this.selectTable.id.toString()).getAttribute('data-x');
      // let posY = document.getElementById(this.selectTable.id.toString()).getAttribute('data-y');
      // let width = document.getElementById(this.selectTable.id.toString()).style.width.split('px')[0];
      // let height = document.getElementById(this.selectTable.id.toString()).style.height.split('px')[0];
      this.selectTable.nombre = this.form.value.nombre;
      this.selectTable.cantidad = this.form.value.cantidad;
      // this.selectTable.posicionX = posX;
      // this.selectTable.posicionY = posY;
      // this.selectTable.alto = height;
      // this.selectTable.ancho = width;
      let model: any = this.selectTable;
      this.restaurantService.updateTable(model)
        .subscribe(
          data => {
            //this.matDrawer.close();
            this.mesas.forEach((element, index) => {
              if (element.id == model.id) {
                this.mesas[index] = model;
              }
            });
          });
    } else {
      let m: any = { nombre: this.form.value.nombre, cantidad: this.form.value.cantidad, mesaZonaId: this.currentZone, posicionX: 0, posicionY: 0, alto: 120, ancho: 120 }
      this.restaurantService.saveTable(m)
        .subscribe(
          data => {
            //this.matDrawer.close();
            m.id = data.id;
            m.usuario = data.usuario;
            this.mesas.push(m);
          });
      //console.log(m)
      //console.log(this.mesas)
    }
    this.showForm(null);
  }

  /**
   * Método para guardar en la DB la organización de las mesas en el mapa
   */
  save() {
    let data = [];
    this.mesas.forEach(element => {
      let posX = document.getElementById(element.id.toString()).getAttribute('data-x');
      let posY = document.getElementById(element.id.toString()).getAttribute('data-y');
      let width = document.getElementById(element.id.toString()).style.width.split('px')[0];
      let height = document.getElementById(element.id.toString()).style.height.split('px')[0];
      element.posicionX = posX;
      element.posicionY = posY;
      element.alto = height;
      element.ancho = width;
      let r = { nombre: element.nombre, cantidad: element.cantidad, posicionX: posX, posicionY: posY, alto: height, ancho: width }
      data.push(r);
    });
    //console.log(this.mesas);
    this.restaurantService.saveAllTable({data: this.mesas})
      .subscribe(
        data => {

        });
    //console.log(data);
  }

  /**
   * Método para mostrar el detalle de una mesa seleccionada
   * @param item mesa seleccionada a ser mostrada
   */
  detail(item: any) {
    this.isSelected = true;
    this.titleText = '';
    //console.log(this.dragAndDrop)
    this.selectTable = item;
    // if (!this.dragAndDrop && (!this.matDrawer.opened || this.selectTable.id === item.id)) {
    //   this.matDrawer.toggle();
    // }
    this.dragAndDrop = false;
  }

  /**
  * Método para eliminar una mesa
  * @param item: mesa a ser eliminada
  */
  deleteTable(item: any) {
    const modalConfig: MatDialogConfig = {
      data: {
        title: 'Eliminar Mesa',
        msg: `¿Confirma que desea eliminar la mesa <b>${item.nombre}</b>?`,
        acceptBtnMsg: 'Eliminar',
      }
    };

    //pantalla de confirmación previa a la eliminación
    const dialog = this.dialog.open(ModalComponent, modalConfig);

    dialog.afterClosed().subscribe((resp) => {
      if (resp == 'confirm') {
        this.restaurantService.deleteTable(item.id)
          .subscribe(
            data => {
              this.mesas.pop(item);
            });
      }
      this.isSelected = false;
      //this.matDrawer.close();
    });
  }

  /**
   * Método para obtener las mesas según la zona seleccionada
   * @param section zona de restaurante
   */
  getSection(section: any) {
    this.isSelected = false;
    this.title = section.nombre;
    this.currentZone = section.id;
    this.getTableForZone(this.currentZone);
  }

  /**
   * Método para obtener la lista de zonas correspondientes al restaurante
   */
  getZoneTypeList() {
    this.restaurantService.getZoneType()
      .subscribe(
        data => {
          this.seccion = data;
          this.title = this.seccion[0].nombre;
          this.currentZone = this.seccion[0].id
          this.getTableForZone(this.currentZone);
        });
  }

  /**
   * Método para obtener la lista de mesas de una zona específica
   * @param id: identificador de la zona
   */
  getTableForZone(id: any) {
    let body = { mesaZonaId: id }
    this.restaurantService.getTable(body)
      .subscribe(
        data => {
          this.mesas = data;
        });
  }

  getDragPosition(item: any) {
    return { x: item.posicionX.toString(), y: item.posicionY.toString() }
  }

  cancel() {
    this.isSelected = false;
  }
}
