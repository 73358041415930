//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';
import { MaterialModule } from '../core/material.module';
import { RestaurantComponent } from './restaurant.component';
import { restaurantRouting } from './restaurant.routing';
import { DirectivesModule } from '../directives/directives.module';
import { RestaurantAssignmentComponent } from './reserve-assignment.component';
import { LoaderModule } from '../components/loader/loader.module';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        restaurantRouting,
        ComponentsModule,
        DirectivesModule,
        LoaderModule
    ],
    declarations: [
        RestaurantComponent,
        RestaurantAssignmentComponent
    ],
    providers: [
        
    ],
    exports: [
        RestaurantComponent,
        RestaurantAssignmentComponent
    ]
})

export class RestaurantModule { }