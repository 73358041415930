import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from '../services/client.service';
import { ReserveService } from '../services/reserve.service';

@Component({
  selector: 'app-client-admin-form',
  templateUrl: './client-admin-form.component.html',
  // styleUrls: ['./client.component.scss']
})
export class ClientAdminFormComponent implements OnInit {
  form: FormGroup;
  model: any

  constructor(private fb: FormBuilder,
    public clientService: ClientService,
    private dialogRef: MatDialogRef<ClientAdminFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data = null) {
    this.model = this.data.model ? this.data.model : {};
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      nombre: [this.model ? this.model.nombre : '', Validators.required],
      apellido: [this.model ? this.model.apellido : '', Validators.required],
      cedula: [this.model ? this.model.cedula : '', Validators.required],
      celular: [this.model ? this.model.celular : '', Validators.required],
      email: [this.model ? this.model.email : '', [Validators.required, Validators.email]]
    });
  }

  close(action: any) {
    this.dialogRef.close(action);
  }

  save() {
    if (this.model.id) {
      this.clientService.updateClient(this.model.id, this.form.value)
        .subscribe(
          data => {
            this.close(null);
          });
    } else {
      this.clientService.addClient(this.form.value)
        .subscribe(
          data => {
            this.close(null);
          });
    }
  }
}
