import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../core/material.module';
import { DraggableDirective } from './draggable.directive';
import { DroppableDirective } from './droppable.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule
  ],
  declarations: [
    DraggableDirective,
    DroppableDirective
  ],
  exports: [
    DraggableDirective,
    DroppableDirective
  ],
  entryComponents: [
    DraggableDirective,
    DroppableDirective
  ]
})
export class DirectivesModule { }
