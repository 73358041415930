<div class="main-content">
  <div class="container-fluid">
    <div class="card">
      <app-loader></app-loader>
      <div class="card-body set-full-height content-body">
        
            <div style="padding: 10px; margin: 10px" class="mat-elevation-z2">
              <div class="style-filter" fxLayout="row-actividad" fxLayoutAlign="space-between center" [formGroup]="form"  fxLayout.xs="column"
              fxLayout.sm="row">

                <div class="fecha-filtro" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column"
                  fxLayout.sm="row">

                  <mat-form-field appearance="outline">
                    <mat-label>Actividades</mat-label>
                    <mat-select #actividadFiltro formControlName="actividadFiltro" multiple
                      (openedChange)="cerrarSelect($event)">
                      <div class="align-center select-all">
                        <button mat-raised-button (click)="marcarTodos()">{{ selectTodosTxt }}</button>
                      </div>
                      <mat-select-trigger>
                        {{ form.controls.actividadFiltro.value ? actividadFiltroCabecera : ''}}
                        <span *ngIf="form.controls.actividadFiltro.value?.length > 1"
                          class="example-additional-selection">
                          (+{{form.controls.actividadFiltro.value.length - 1}})
                        </span>
                      </mat-select-trigger>
                      <mat-option *ngFor="let e of actividadesFiltroList" [value]="e.id">{{e.nombre}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="date-input">
                    <mat-label>Fecha Inicio</mat-label>
                    <input formControlName="fechaInicio" matInput [matDatepicker]="pickerInicio">
                    <mat-datepicker-toggle matSuffix [for]="pickerInicio"></mat-datepicker-toggle>
                    <mat-datepicker #pickerInicio></mat-datepicker>
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="date-input">
                    <mat-label>Fecha Fin</mat-label>
                    <input formControlName="fechaFin" matInput [matDatepicker]="pickerFin">
                    <mat-datepicker-toggle matSuffix [for]="pickerFin"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFin></mat-datepicker>
                  </mat-form-field>

                  <button class="btn btn-general" (click)="buscarActividades()">
                    <mat-icon>search</mat-icon>
                  </button>

                  <button class="btn btn-general" (click)="openForm()">
                    <mat-icon>add</mat-icon>
                    Bloque
                  </button>

                  <button class="btn btn-general" (click)="blockedDate()">
                    <mat-icon>event_busy</mat-icon>
                    Bloqueo de Fecha
                  </button>

                  <button class="btn btn-general" (click)="desbloqueoForm()">
                    <mat-icon>no_encryption</mat-icon>
                    Desbloqueo
                  </button>
                </div>

              </div>

              <div class="custom-table" *ngIf="data.length > 0">

                <div class="row-actividad row-dias">
                  <div class="box-container" *ngFor="let item of diasH">
                    <div class="box box-day" [ngClass]="{'cab-feriado': item.tipoCabecera === 'DIA_FERIADO'}">
                      <div class="item-day">
                        {{item.tipoCabecera === 'TITULO_TABLA' ? item.nombreDia : (item.nombreDia | slice:0:3) + ' ' +
                        (item.dia ? item.dia : '')}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row-actividad" *ngFor="let item of data">
                  <div class="row-actividad">
                    <div class="box-container">

                      <div class="box" style="padding: 0 10px;" fxLayoutAlign="space-around center">
                        <mat-icon>{{ item.actividad.icono }}</mat-icon>
                        <mat-label style="padding: 0 5px;">
                          {{item.actividad.nombre}}
                        </mat-label>
                      </div>
                    </div>

                    <div class="box-container" *ngFor="let e of item.disponibilidadDias">
                      <div class="box" [ngClass]="{'fecha-bloqueada': e.bloqueado}">
                        <div class="item" (click)="reserva.tipo === 'BLOQUE' ? delete(item.actividad, reserva) : ''"
                          *ngFor="let reserva of (e.disponibilidades.length > 1 && masReservas.checked === true ? e.disponibilidades : e.disponibilidades.slice(0, 1))"                         
                          [ngClass]="{'item-pendiente': reserva.tipo === 'DEFECTO', 'item-rechazado': reserva.tipo === 'BLOQUE'}">
                          {{reserva.cantidad}}
                        </div>
                        <mat-checkbox #masReservas class="chk-show-more" [checked]="false">
                          <div *ngIf="e.disponibilidades.length > 1">
                            <div *ngIf="masReservas.checked">
                              VER MENOS <i class="fas fa-fw fa-minus"></i>
                            </div>
                            <div *ngIf="!masReservas.checked">
                              VER MÁS ({{ e.disponibilidades.length - 1 }}) <i class="fas fa-fw fa-plus"></i>
                            </div>
                          </div>
                        </mat-checkbox>
                        <button mat-mini-fab *ngIf="e.bloqueado" (click)="dateUnlock(item.actividad, e)" 
                        [matTooltip]="e.observacion" matTooltipClass="tooltip-style">
                          <mat-icon>event_available</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div *ngIf="data.length === 0">
                <div class="result-text">{{ searchText }}</div>
              </div>

            </div>
          
      </div>

    </div>
  </div>
</div>