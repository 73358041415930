import { ThrowStmt, viewClassName } from '@angular/compiler';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { getMonth } from 'date-fns';
import { GraficosService } from 'src/app/services/graficos.service';
import { DisponibilidadGraficoComponent } from './disponibilidad-grafico/disponibilidad-grafico.component';
import { GraficoParticipantesComponent } from './grafico-participantes/grafico-participantes.component';
import { GraficoPersonasComponent } from './grafico-personas/grafico-personas.component';
import { GraficoReservasComponent } from './grafico-reservas/grafico-reservas.component';
@Component({
  selector: 'app-grafico',
  templateUrl: './grafico.component.html',
  styleUrls: ['./grafico.component.scss']
})
export class GraficoComponent implements OnInit {

  @ViewChild('PD') disponibilidadPD: DisponibilidadGraficoComponent;
  @ViewChild('RE') disponibilidadRE: DisponibilidadGraficoComponent;
  @ViewChild('PF') disponibilidadPF: DisponibilidadGraficoComponent;
  @ViewChild('PI') disponibilidadPI: DisponibilidadGraficoComponent;
  @ViewChild('PA') participantesPA: GraficoParticipantesComponent;
  @ViewChild('PE') personasPE: GraficoPersonasComponent;
  @ViewChild('RES') reservaRES: GraficoReservasComponent;

  form: FormGroup;
  model: any;
  periodos: any[] = [
    { id: 1, nombre: 'Enero' },
    { id: 2, nombre: 'Febrero' },
    { id: 3, nombre: 'Marzo' },
    { id: 4, nombre: 'Abril' },
    { id: 5, nombre: 'Mayo' },
    { id: 6, nombre: 'Junio' },
    { id: 7, nombre: 'Julio' },
    { id: 8, nombre: 'Agosto' },
    { id: 9, nombre: 'Septiembre' },
    { id: 10, nombre: 'Octubre' },
    { id: 11, nombre: 'Noviembre' },
    { id: 12, nombre: 'Diciembre' }

  ]
  @ViewChild('actividadIds') actividadSelect: MatSelect;
  selectTodosTxt = 'Desmarcar todos';
  reservas: any[];
  actividades: any[] = [];
  actividadFiltroCabecera = '';
  // periodoFiltroCabecera = '';
  // datosDefault: any;
  mesBase: number;
  actividadParametro: any[];
  constructor(public graficosService: GraficosService, private fb: FormBuilder, public cdref: ChangeDetectorRef,) {
    this.traerActividad();
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      fecha: [new Date()],
      fechaPersonas: [new Date()],
      fechaParticipantes: [new Date()],
      actividadIds: [''],
      periodo: [new Date().getMonth() + 1],
    });

    this.mesBase = new Date().getMonth() + 1;
    
    this.form.controls.fecha.valueChanges.subscribe(value => {
      this.actualizar(value);
    });
    this.form.controls.fechaPersonas.valueChanges.subscribe(value => {
      this.actualizarPersonas(value);
    });
    this.form.controls.fechaParticipantes.valueChanges.subscribe(value => {
      this.actualizarParticipantes(value);
    });
    this.form.controls.periodo.valueChanges.subscribe(value => {
      this.actualizarPeriodo(value);
    });
    this.form.controls.actividadIds.valueChanges.subscribe(value => {
      if (this.reservaRES) {
        this.actualizarActividad(value);
      }
    });

    this.form.controls.actividadIds.valueChanges.subscribe(value => {
      if (this.form.controls.actividadIds.value) {
        const index = this.actividades.findIndex(t => t.id == this.form.controls.actividadIds.value[0])
        if (index > -1) {
          this.actividadFiltroCabecera = this.actividades[index].nombre;
        }
      }
    });

  }

  traerReservas(body: any) {
    this.graficosService.getReservas(body).subscribe(res => {
    });
  }

  traerActividad() {
    this.graficosService.getActividad().subscribe(data => {
      this.actividades = data;
      this.actividadParametro = this.actividades.map(t => t.id);
      this.form.controls.actividadIds.setValue(this.actividadParametro);
    });
  }

  cerrarSelect(opened: boolean) {
    if (!opened) {
      this.buscarActividades();
    }
  }

  buscarActividades() {
    const filtro = {
      actividadIds: this.form.controls.actividadIds.value,
      periodo: this.form.controls.periodo.value,

    }
    this.traerReservas(filtro)
  }

  /**
   * actualiza los valores de los graficos en el componente hijo
   * @param fecha fecha que se recibe como parametro
   */
  actualizar(fecha) {
    this.disponibilidadPD.getDatosPie(fecha);
    this.disponibilidadRE.getDatosPie(fecha);
    this.disponibilidadPF.getDatosPie(fecha);
    this.disponibilidadPI.getDatosPie(fecha);
  }
  actualizarParticipantes(fecha) {
    this.participantesPA.getDatosBarra(fecha);
  }
  actualizarPersonas(fecha) {
    this.personasPE.getDatosBarraCombinada(fecha);
  }
  actualizarPeriodo(datos) {
    this.reservaRES.getDatosLinea(datos, this.form.controls.actividadIds.value);
  }
  actualizarActividad(datos) {
    this.reservaRES.getDatosLinea(this.form.controls.periodo.value, datos);
  }

  marcarTodos() {
    if (this.form.controls.actividadIds.value.length === this.actividades.length) {
      this.form.controls.actividadIds.setValue([]);
      this.selectTodosTxt = 'Marcar todos';
    } else {
      this.selectTodosTxt = 'Desmarcar todos';
      this.form.controls.actividadIds.setValue(this.actividades.map(t => t.id));
    }
  }

  ngOnInit(): void {

  }

}
