<!-- Grafico de pie -->

<div class="main-content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div>
          <mat-tab-group>
            <mat-tab label="Disponibilidad">

              <div class="row" [formGroup]="form" fxLayout="row" fxLayoutAlign="between center">
                <mat-form-field appearance="outline" class="date-input">
                  <mat-label>Fecha</mat-label>
                  <input formControlName="fecha" matInput [matDatepicker]="pickerFecha">
                  <mat-datepicker-toggle matSuffix [for]="pickerFecha"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFecha></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="respDisGrafico" fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayout.sm="column">
                  <app-disponibilidad-grafico  titulo="PASAR EL DIA" actividad="PD" #PD> </app-disponibilidad-grafico>
                  <app-disponibilidad-grafico  titulo="RESTAURANTE" actividad="RE" #RE> </app-disponibilidad-grafico>
                 
              </div>
              <div class="respDisGrafico" fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayout.sm="column">
                <app-disponibilidad-grafico  titulo="PARRILLAS FIJAS" actividad="PF" #PF> </app-disponibilidad-grafico>
                <app-disponibilidad-grafico  titulo="PILETA" actividad="PI" #PI> </app-disponibilidad-grafico>
              </div>
            </mat-tab>

            <mat-tab label="Participantes">

              <div class="row" [formGroup]="form" fxLayout="row" fxLayoutAlign="between center">
                <mat-form-field appearance="outline" class="date-input">
                  <mat-label>Fecha</mat-label>
                  <input formControlName="fechaParticipantes" matInput [matDatepicker]="pickerFechaParticipantes">
                  <mat-datepicker-toggle matSuffix [for]="pickerFechaParticipantes"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFechaParticipantes></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="respGrafico" fxLayout="row" fxLayoutAlign="center center">
                <app-grafico-participantes #PA> </app-grafico-participantes>
              </div>
            </mat-tab>

            <mat-tab label="Cantidad de Personas">

              <div class="row" [formGroup]="form" fxLayout="row" fxLayoutAlign="between center">
                <mat-form-field appearance="outline" class="date-input">
                  <mat-label>Fecha</mat-label>
                  <input formControlName="fechaPersonas" matInput [matDatepicker]="pickerFechaPersonas">
                  <mat-datepicker-toggle matSuffix [for]="pickerFechaPersonas"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFechaPersonas></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="respGrafico" fxLayout="row" fxLayoutAlign="center center">
                <app-grafico-personas  #PE> 
                </app-grafico-personas>
              </div>
            </mat-tab>

            <mat-tab label="Reservas por dia">

              <div class="row" [formGroup]="form" fxLayout="row" fxLayoutAlign="between center">   
                <mat-form-field appearance="outline">
                  <mat-label>Periodo</mat-label>
                  <mat-select #periodo formControlName="periodo"
                  (openedChange)="cerrarSelect($event)">
                      <mat-option *ngFor="let p of periodos" [value]="p.id">
                          {{p.nombre}}
                      </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label >Tipo de Actividades</mat-label>
                  <mat-select #actividadIds formControlName="actividadIds" multiple
                  (openedChange)="cerrarSelect($event)">
                    <div class="align-center select-all">
                      <button mat-raised-button (click)="marcarTodos()">{{ selectTodosTxt }}</button>
                    </div>
                    <mat-select-trigger>
                      {{form.controls.actividadIds.value ? actividadFiltroCabecera : ''}}
                      <span *ngIf="form.controls.actividadIds.value?.length > 1" class="example-additional-selection">
                        (+{{form.controls.actividadIds.value.length - 1}})
                      </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let t of actividades" [value]="t.id">{{t.nombre}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="respGrafico" fxLayout="row" fxLayoutAlign="center center">
                <app-grafico-reservas *ngIf="actividades.length > 0" [periodo]="mesBase" [actividades]="actividadParametro" #RES> </app-grafico-reservas>
              </div>
            </mat-tab>

            
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>