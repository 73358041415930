import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PersonaService } from '../services/persona.service';
import { ReserveService } from '../services/reserve.service';

@Component({
  selector: 'app-bloqueo-form',
  templateUrl: './bloqueo-form.component.html',
})
export class BloqueoFormComponent implements OnInit {
  form: FormGroup;
  days: any[] = [
    {key: 'L', value: 'LUN', check: true},
    {key: 'M', value: 'MAR', check: true},
    {key: 'MI', value: 'MIE', check: true},
    {key: 'J', value: 'JUE', check: true},
    {key: 'V', value: 'VIE', check: true},
    {key: 'S', value: 'SAB', check: true},
    {key: 'D', value: 'DOM', check: true},
  ]

  constructor(
    private dialogRef: MatDialogRef<BloqueoFormComponent>,
    private fb: FormBuilder,
    public reserveService: ReserveService,
    private personaService: PersonaService,
    @Inject(MAT_DIALOG_DATA) public data = null
  ) {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      tipoActividad: ['', Validators.required],
      fechaDesde: ['', Validators.required],
      fechaHasta: ['', Validators.required],
      observacion: ''
    });
  }

  ngOnInit() { }

  close(action: any) {
    this.dialogRef.close(action);
  }

  /**
   * Método para bloquear un rango de fechas por actividad
   */
  saveBlocked() {
    let l = this.days.filter((e) => e.check == true);
    let body = this.form.value;
    body.diasBloqueo = l;
    this.reserveService.dateLock(body)
      .subscribe(
        data => {
          this.buildForm();
          this.close(null);       
        }); 
  }

  /**
   * Método para seleccionar un registro del listado de días
   * @param item registro seleccionado
   */
  setChange(item: any) {
    item.check = !item.check;
  }

}
