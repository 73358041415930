import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../interceptor/settings.service';
import { SessionService } from '../interceptor/session.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthenticationService {

    constructor(
        private http: HttpClient,
        private settings: SettingsService,
        private sessionService: SessionService) {
    }

    login(body: any) {
        //se encripta la clave con md5
        let passwordMd5 = Md5.hashStr(body.password);
        body.password = passwordMd5.toString();

        return this.http.post(`${this.settings.URL_BASE}login`, body);
    }

    //si el usuario esta logueado
    isAuthenticated() {
        return this.sessionService.existSession();
    }

    /**
     * Método para enviar un correo electrónico para reseteo de contraseña
     * @param body correo donde enviar el link de reseteo de contraseña
     * @returns 
     */
    passReset(body: any) {
        return this.http.post(`${this.settings.URL_BASE}usuario/clave/reset`, body);
    }

    /**
     * Método para reseteo de contraseña
     * @param body nueva contraseña
     * @param token el token del usuario
     * @returns 
     */
    confirmPassReset(body: any, token: any): Observable<any> {
        return this.http.post(`${this.settings.URL_BASE}usuario/clave/reset/token?token=${token}`, body);
    }
}