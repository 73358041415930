<div class="">
  <mat-toolbar class="dialog-toolbar" color="primary" fxLayout="row" fxLayoutAlign="space-between center">
    <div>Notificaciones</div>
    <div>
      <button mat-icon-button (click)="close('close')">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <br>

  <div mat-dialog-content fxLayout="column" fxLayoutGap="5px">
    <app-loader></app-loader>
    <div>
      <cdk-virtual-scroll-viewport #scroller itemSize="72" class="content">
        <mat-list>
          <div class="mensaje" *ngIf="listItems?.length === 0">
            <h2>No hay notificaciones para mostrar </h2>
          </div> 
         <div  *ngIf="listItems?.length > 0">
            <ng-container *cdkVirtualFor="let item of listItems" >
              <mat-list-item class="pointer" (click)="redirect(item)">
                <div matLine >
                  <mat-chip-list aria-label="Nuevo" *ngIf="item?.isNew">
                    <mat-chip color="primary" selected (click)="ocultarChip(item)">Nuevo</mat-chip>
                  </mat-chip-list>
                  <b> {{item.notificacionTipo}}</b>
                  <span>{{item.fechaHora}}</span>
                </div>             
                <div matLine >
                  {{item.mensaje}}
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
            </ng-container>
          </div>   
        </mat-list>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>

<br>

<div class="btn-action-container">

  <button class="btn btn-cancel" (click)="close('close')">
    <mat-icon>close</mat-icon>
    Cerrar
  </button>
</div>