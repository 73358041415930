//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';
import { MaterialModule } from '../core/material.module';
import { DirectivesModule } from '../directives/directives.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoaderModule } from '../components/loader/loader.module';
import { activityRouting } from './activity.routing';
import { ActivityListComponent } from './activity-list.component';
import { ActivityFormComponent } from './activity-form.component';
import { ActivityDivisionFormComponent } from './activity-division-form.component';
import { ActivityHorarioFormComponent } from './activity-horario-form.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        activityRouting,
        ComponentsModule,
        DirectivesModule,
        FlexLayoutModule,
        LoaderModule,
        NgxMaterialTimepickerModule,
        AngularEditorModule,
    ],
    declarations: [
        ActivityListComponent,
        ActivityFormComponent,
        ActivityDivisionFormComponent,
        ActivityHorarioFormComponent
    ],
    providers: [
        
    ],
    exports: [
        ActivityListComponent,
        ActivityFormComponent,
        ActivityDivisionFormComponent,
        ActivityHorarioFormComponent
    ]
})

export class ActivityModule { }