import {NgModule} from "@angular/core";
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatStepperModule} from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatDatepicker, MatDatepickerModule} from '@angular/material/datepicker';
import { MatSnackBarModule } from "@angular/material/snack-bar";
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatPaginatorIntl, MatPaginatorModule } from "@angular/material/paginator";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { GetEsPaginatorIntl } from "../utils/paginator-es";
import { ScrollingModule } from "@angular/cdk/scrolling";
import {CdkTableModule} from '@angular/cdk/table';

@NgModule({
  imports: [
  CommonModule, 
  MatToolbarModule,
  MatButtonModule, 
  MatCardModule,
  MatInputModule,
  MatDialogModule,
  MatTableModule,
  MatMenuModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatStepperModule,
  MatTooltipModule,
  MatGridListModule,
  MatListModule,
  MatSelectModule,
  MatExpansionModule,
  MatCheckboxModule,
  MatDatepickerModule,
  DragDropModule,
  MatSidenavModule,
  MatPaginatorModule,
  MatSlideToggleModule,
  CdkTableModule,
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    MatDatepicker,
    {
      provide: MatPaginatorIntl, useValue: GetEsPaginatorIntl()
    }
  ],
  exports: [
  CommonModule,
   MatToolbarModule, 
   MatButtonModule, 
   MatCardModule, 
   MatInputModule, 
   MatDialogModule, 
   MatTableModule, 
   MatMenuModule,
   MatIconModule,
   MatProgressSpinnerModule,
   MatStepperModule,
   MatTooltipModule,
   MatGridListModule,
   MatListModule,
   MatSelectModule,
   MatExpansionModule,
   MatCheckboxModule,
   MatDatepickerModule,
   MatSnackBarModule,
   DragDropModule,
   MatSidenavModule,
   MatPaginatorModule,
   MatSlideToggleModule,
   ScrollingModule,
   CdkTableModule
   ],
})
export class MaterialModule { }