<!-- Grafico de pie -->

<div class="contenedorGraficos" [ngClass]="{'bg-color-amarillo': actividad == 'PD', 'bg-color-verde': actividad == 'RE', 'bg-color-naranja': actividad == 'PI'}" fxLayout="row" fxLayoutGap="200px"  >
  <div *ngIf="bandera === false">
      <ngx-charts-pie-chart
        [view]="vista"
        [scheme]="colorScheme"
        [results]="datos"
        [gradient]="gradiante"
        [legend]="mostrarLegend"
        [legendTitle]="titulo"
        [legendPosition]="posicionDescripcion"
        [labels]="mostrarEtiqueta"
        [doughnut]="esDona"
        [animations]="animaciones"
        [tooltipDisabled]="deshabilitarTooltip"
        [maxLabelLength]="maxLabelLength"
        > 
      </ngx-charts-pie-chart>
      </div>
      <div class="contenedorSinDatos" *ngIf="bandera === true">
        <div class="sinDatosDisponibilidad" fxLayout="row" fxLayoutGap="100px"> 
          No hay datos para mostrar
        </div>
      </div>
    </div>