import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivityService } from '../services/activity.service';
import { RestaurantService } from '../services/restaurant.service';

@Component({
  selector: 'app-activity-horario',
  templateUrl: './activity-horario-form.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityHorarioFormComponent implements OnInit {
  form: FormGroup;
  model: any;
  turnoList: any[] = [];

  constructor(private fb: FormBuilder,
    public activityService: ActivityService,
    private restaurantService: RestaurantService,
    private dialogRef: MatDialogRef<ActivityHorarioFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data = null) {
    this.model = this.data.model ? this.data.model : {};

  }

  ngOnInit() {
    this.getTurnos();
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      horario: [this.model ? this.model.horario : '', Validators.required],
      actividadHorarioTurnoId: [this.model ? this.model.actividadHorarioTurnoId : '', Validators.required],
      secuencia: [this.model ? this.model.secuencia : '', Validators.required]
    });
  }

  close(action: any) {
    this.dialogRef.close(action);
  }

  save() {
    if (this.model.id) {
      this.activityService.updateActivitySchedule(this.model.id, this.form.value)
        .subscribe(
          data => {
            this.close(null);
          });
    } else {
      this.activityService.addActivitySchedule(this.data.activity.id, this.form.value)
        .subscribe(
          data => {
            this.close(null);
          });
    }
  }

  /**
   * Método para obtener la lista de horarios para el restaurante
   */
  getTurnos() {
    this.restaurantService.getHorario()
      .subscribe(
        data => {
          this.turnoList = data;
        });
  }

}
