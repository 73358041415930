import { Component, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface Data {
  id: number;
  icono: string;
  nombre: string;
  secuencia: number;
}

@Component({
  selector: 'wi-radio-icon-list',
  templateUrl: 'radio-icon-list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WizardFormRadioIconListInputComponent),
      multi: true
    }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => WizardFormRadioIconListInputComponent),
      multi: true,
    }
  ]
})
export class WizardFormRadioIconListInputComponent implements ControlValueAccessor, Validator {

  private _choice: any = '';
  private propagateValidator: (value: any) => void;
  private propagateChange: (value: any) => void;
  private propagateTouch: (value: any) => void;

  @Input() data: Data;
  @Input() additional: any;

  private _disabled: boolean | null;
  @Input('attr.disabled') set disabled(value: boolean) {
    this._disabled = value ? value : null;
  }

  get disabled() {
    return this._disabled;
  }


  get choice(): any {
    if (this.additional.length > 0) {
      this.additional.forEach(element => {
        if (this._choice == element.id) {
          this._choice = null;
        }
      });
    }

    return this._choice;
  }

  set choice(value: any) {
    if (this._choice !== value) {
      this._choice = value;
      if (this.propagateChange) {
        this.propagateChange(this.choice);
      }
    }
  }


  constructor(private _snackBar: MatSnackBar) {

  }

  public writeValue(value: any): void {
    if (value) {
      this.choice = value;
    }
  }

  public registerOnChange(fn: (value: any) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: (value: any) => void) {
    this.propagateTouch = fn;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.propagateValidator = fn;
  }

  validate(control: AbstractControl): { [key: string]: any } {
    return this.choice && this.choice === 'Unknown' ?
      {
        'valid': true, // not passed test
      } : null;
  }

  selectChoice(choice: any) {
    if (choice.hover == undefined) {
      let msg = 'Actividad seleccionada! Clic en botón "SIGUIENTE"';
      this.choice = choice.id;
      if (this.additional.length > 0) {
        this.additional.forEach(element => {
          if (choice.id == element.id) {
            msg = 'La actividad seleccionada ya está reservada!';
            this.choice = null;
          }
        });
      }
      this._snackBar.open(msg, 'CERRAR', {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    } else {
      this._snackBar.open(choice.hover, 'CERRAR', {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }
}
