import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { map, pairwise, filter, throttleTime, timeout } from 'rxjs/operators';
import { NotificacionList } from '../models/notificacion-list';
import { NotificationService } from '../services/notification.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { ReserveService } from '../services/reserve.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NotificationListComponent implements OnInit, AfterViewInit {

  listItems = [];
  dataSource: Array<NotificacionList> = [];
  @ViewChild('scroller') scroller!: CdkVirtualScrollViewport;
  datos = {
    pagina: 1,
    cantPagina: 10
  }
  cantidad = 1

  constructor(private dialogRef: MatDialogRef<NotificationListComponent>, private ngZone: NgZone, public notificationService: NotificationService, private cdref: ChangeDetectorRef,
    private router: Router, public reserveService: ReserveService, public activatedRoute: ActivatedRoute) {
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit(): void {
    this.traerNotificaciones();
  }

  onContentScrolled() {

  }
  
/* funcion que se ejecuta al hacer scroll*/
  ngAfterViewInit() {
    this.scroller.elementScrolled().pipe(
      map(() => this.scroller.measureScrollOffset('bottom')),
      pairwise(),
      filter(([y1, y2]) => (y2 < y1 && y2 < 140)),
      throttleTime(200)
    ).subscribe(() => {
      this.ngZone.run(() => {
        this.traerNotificaciones();
      });
    })
  }
/*
  funcion para traer las notificaciones del be
  se valida que mientras que la lista sea menor a la cantidad de notificaciones del be se recuperen los registros
  listItems acumula las nuevas notificaciones 
*/
  traerNotificaciones(){
    if (this.listItems.length < this.cantidad) {
    this.notificationService.getNotifications(this.datos).subscribe(res=>{
      this.dataSource = res.data;
      this.cantidad = res.pagination.cantidad;
      this.datos.pagina = this.datos.pagina + 1
        this.listItems = [...this.listItems, ...this.dataSource];
           })
      };
  }

  close(action: any) {
    this.dialogRef.close(action);
  }

  //funcion para ocultar el matchip al hacerle click 
  ocultarChip(item:any){
    this.notificationService.readNotification(item.id).subscribe(res=>{
      item.isNew = false;
    });
  }
  //funcion que se utiliza en caso de estar posicionado sobre la ruta a la cual se hara la redireccion
  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }
  redirect(item: any): any {
    const parametro: NavigationExtras = {
      state: item,
    };
    this.close(null)
    //en caso que este posicionado sobre planilla/reserva al dar click en la notificacion
    if(this.router.url === '/planilla/reserva'){
        this.reloadComponent()
        this.router.navigate(['planilla/reserva'], parametro);
    }
    else{
      this.router.navigate(['planilla/reserva'], parametro);
    }
  }
}
