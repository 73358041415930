import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '../interceptor/settings.service';

@Injectable({
    providedIn: 'root'
})
export class ClientService {

    constructor(
        private http: HttpClient,
        private settings: SettingsService
    ) { }

    /**
    * Método para obtener todos los clientes registrados en el sistema
    */
    getAllClients(body: any): Observable<any> {
        return this.http.post(this.settings.URL_BASE + `cliente/list`, body);
    }

    /**
    * Método para agregar un cliente de sistema
    * @param body: datos de cliente para dar de alta
    */
    addClient(body: any) {
        return this.http.post(`${this.settings.URL_BASE}cliente`, body);
    }

    /**
    * Método para eliminar un cliente de sistema
    * @param id: identificador del cliente
    */
    deleteClient(id: any) {
        return this.http.delete(`${this.settings.URL_BASE}cliente/${id}`);
    }

    /**
    * Método para actualizar un cliente de sistema
    * @param id: identificador del cliente
    */
    updateClient(id: any, body: any) {
        return this.http.put(`${this.settings.URL_BASE}cliente/${id}`, body);
    }

}
