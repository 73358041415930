<div class="wrapper">
    <div *ngIf="(authenticationService.isAuthenticated() && showMenu)" class="sidebar" data-color="secundary"
        data-background-color="dark">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background"></div>
    </div>
    <div class="main-panel" [class.w-full]="!authenticationService.isAuthenticated() || !showMenu" [class.ml-0]="!showMenu">
        <div *ngIf="authenticationService.isAuthenticated()">
            <app-navbar></app-navbar>
        </div>
        <!-- <alert></alert> -->
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </div>

</div>
