<div class="main-content login-page mt-0 p-0">
  <div class="row login-box">
    <app-loader></app-loader>
    <div class="row image-container-style">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <img src="../../assets/images/los-lagos-sombra.png">
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-4 plr-100">
      <mat-card class="example-card p30">
        <mat-card-header>
          <mat-card-title class="card-title">INICIAR SESIÓN </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="form" class="example-form">
            <table class="example-full-width" cellspacing="0">
              <tr>
                <td>
                  <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Usuario</mat-label>
                    <input class="upper-case" matInput placeholder="Usuario" type="text" formControlName="username"
                      name="usuario" required>
                    <mat-icon matSuffix>person</mat-icon>
                  </mat-form-field>
                  <div>
                    <small class="text-danger"
                      *ngIf="form.controls.username.touched && form.controls.username.errors?.required">El usuario
                      es requerido</small>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Contraseña</mat-label>
                    <input matInput placeholder="Contraseña" formControlName="password"
                      [type]="hidePass ? 'text' : 'password'" name="password" required>
                    <mat-icon matSuffix (click)="hidePass = !hidePass" type="button" tabindex="-1"
                      [matTooltip]="hidePass ? 'Ocultar Contraseña' : 'Mostrar Contraseña'">
                      {{ hidePass ? 'visibility' : 'visibility_off' }}</mat-icon>
                  </mat-form-field>
                  <div>
                    <small class="text-danger"
                      *ngIf="form.controls.password.touched && form.controls.password.errors?.required">La contraseña es
                      requerida</small>
                  </div>
                </td>
              </tr>
            </table>
          </form>
        </mat-card-content>
        <mat-card-actions>
          <div class="button-container">
            <button class="btn btn-general" (click)="login()" [disabled]="form.invalid">INGRESAR <mat-icon>
                keyboard_arrow_right</mat-icon></button><br>
            <div class="link-style">
              <a [routerLink]="['../reseteo']">Olvidé mi Contraseña</a>
            </div>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>