import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '../interceptor/settings.service';

@Injectable({
  providedIn: 'root',
})
export class ReserveService {

  constructor(
    private http: HttpClient,
    private settings: SettingsService
  ) { }

  /**
  * Método para obtener el listado de tipos de reserva
  * @param body fecha de reserva seleccionada
  */
  getReserveType(body: any): Observable<any> {
    return this.http.post(this.settings.URL_BASE + 'reserva/actividad', body);
  }

  /**
  * Método para obtener información adicional de un tipo de reserva
  * @param id: identificador del tipo de reserva
  * @param body: contiene la fecha de reserva seleccionada
  */
  getReserveAvailable(id: any, body: any) {
    return this.http.post(this.settings.URL_BASE + `reserva/actividad/${id}/cantidad`, body);
  }

  getHorario(id: any, body: any) {
    return this.http.post(this.settings.URL_BASE + `reserva/division/${id}/cantidad`, body);
  }

  /**
  * Método para verificar la disponibilidad de una reserva de un tipo en una fecha/hora seleccionada
  * @param id: identificador del tipo de reserva
  * @param body: contiene la fecha/hora de reserva seleccionada
  */
  reserveVerification(id: any, body: any) {
    return this.http.post(this.settings.URL_BASE + `reserva/actividad/${id}/disponibilidad`, body);
  }

  /**
  * Método para guardar una reserva
  * @param body: contiene los datos para una reserva
  */
  saveReserve(body: any) {
    return this.http.post(this.settings.URL_BASE + `reserva/actividad/cliente`, body);
  }

  /**
  * Método para obtener un cliente según su número de cédula
  * @param document: número de cédula del cliente
  */
  getClient(document: any) {
    return this.http.get(this.settings.URL_BASE + `cliente/${document}`);
  }
  /**
  * Método para agregar acompañante de un cliente
  * @param body: objeto json con información de la persona acompañante
  */
  saveClient(body: any) {
    return this.http.post(this.settings.URL_BASE + `reserva/actividad/cliente/participante`, body);
  }
  /**
  * Método para obtener la lista de acompañantes de un cliente
  * @param token: token asociado a la actividad de la reserva del cliente
  */
  getParticipants(token: any): Observable<any> {
    return this.http.get(this.settings.URL_BASE + `reserva/actividad/participante/${token}`);
  }

  getDivision(): Observable<any> {
    return this.http.get(
      this.settings.URL_BASE + `reserva/actividad/division`
    );
  }

  getPlanilla(body = null): Observable<any> {
    return this.http.post(
      this.settings.URL_BASE + `reserva/planilla`,
      body
    );
  }

  getActividadesFiltro(): Observable<any> {
    return this.http.get(this.settings.URL_BASE + 'reserva/actividad/division');
  }

  /**
  * Método para eliminar una reserva
  * @param id: identificador de la actividad reservada
  */
  deleteReserve(id: any) {
    return this.http.delete(this.settings.URL_BASE + `reserva/actividad/${id}`);
  }

  /**
  * Método para obtener los estados para una reserva
  */
  getReserveStatus(): Observable<any> {
    return this.http.get(this.settings.URL_BASE + `reserva/actividad/estado`);
  }

  /**
  * Método para actualizar una reserva
  * @param id: identificador de la reservada
  * @param body: atributo de la reserva a ser actualizado
  */
  updateReserve(id: any, body: any) {
    return this.http.put(this.settings.URL_BASE + `reserva/actividad/${id}`, body);
  }

  /**
  * Método para guardar una reserva desde la planilla, con token
  * @param body: contiene los datos para una reserva
  */
  saveReserveWithToken(body: any) {
    return this.http.post(this.settings.URL_BASE + `reserva/division/cliente`, body);
  }

  /**
  * Método para obtener información adicional de un tipo de reserva, desde la planilla. Con token
  * @param id: identificador del tipo de reserva
  * @param body: contiene la fecha de reserva seleccionada
  */
  getReserveAvailableWithToken(id: any, body: any) {
    return this.http.post(this.settings.URL_BASE + `reserva/division/${id}/cantidad`, body);
  }

  /**
  * Método para verificar la disponibilidad de una reserva de un tipo en una fecha/hora seleccionada
  * Utilizado desde la planilla. Con token
  * @param id: identificador del tipo de reserva
  * @param body: contiene la fecha/hora de reserva seleccionada
  */
  reserveVerificationWithToken(id: any, body: any) {
    return this.http.post(this.settings.URL_BASE + `reserva/division/${id}/disponibilidad`, body);
  }

  /**
   * Método para bloqueo de fechas en un rango, por actividad
   * @param body rango de fechas a ser bloqueadas por actividad
   * @returns HTTP/1.1 201 Created || HTTP/1.1 500: Internal server error
   */
  dateLock(body: any) {
    return this.http.post(`${this.settings.URL_BASE}fecha/actividad/bloqueo`, body);
  }

  /**
   * Método para desbloqueo de fecha, por actividad
   * @param id identificador de bloqueo
   * @returns HTTP/1.1 204 No Content || HTTP/1.1 500: Internal server error
   */
  dateUnlock(id: any) {
    return this.http.delete(`${this.settings.URL_BASE}fecha/actividad/bloqueo/${id}`);
  }

  /**
   * Método para obtener la lista de actividades disponibles para una fecha específica
   * @param body objeto con la información de la fecha
   * @returns HTTP/1.1 200 OK lista | HTTP/1.1 500 Internal Server Error
   */
  getActivityWithToken(body: any): Observable<any> {
    return this.http.post(this.settings.URL_BASE + 'reserva/division/multiple', body);
  }

  /**
   * Método para obtener la lista de todas las actividades
   * @returns HTTP/1.1 200 OK lista | HTTP/1.1 500 Internal Server Error
   */
  getAllActivity(): Observable<any> {
    return this.http.get(this.settings.URL_BASE + 'actividad');
  }

  /**
   * Método para desbloqueo de disponibilidad y de fechas en un rango, por actividad
   * @param body rango de fechas a ser desbloqueadas por actividad
   * @returns HTTP/1.1 201 Created || HTTP/1.1 500: Internal server error
   */
   unLock(body: any) {
    return this.http.put(`${this.settings.URL_BASE}fecha/actividad/desbloqueo`, body);
  }

  /**
   * Método para actualizar un bloqueo de fecha, por actividad
   * @param body atributo a actualizar
   * @returns HTTP/1.1 201 Created || HTTP/1.1 500: Internal server error
   */
   updateDate(body: any, fechaBloqueoId: number) {
    return this.http.put(`${this.settings.URL_BASE}fecha/actividad/bloqueo/${fechaBloqueoId}/observacion`, body);
  }

  //Metodo para reenviar un correo a la persona que realizo la reserva
  getCorreo(id:any, body:any){
    return this.http.post(this.settings.URL_BASE + `reserva/division/${id}/correo`, body);
  }
}
