import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../services/sidebar.service';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/interceptor/session.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NotificationListComponent } from 'src/app/notification/notification-list.component';
import { interval } from 'rxjs';


export const ROUTES: any[] = [];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuList: any[];
  currentUser: any = {};
  cantidad: number;

  constructor(
    public menuService: MenuService,
    private router: Router,
    public sessionService: SessionService,
    public notificationService: NotificationService,
    private dialog: MatDialog,
  ) {
    //this.currentUser = JSON.parse(this.constant.getItemLocalStorage('currentUser'));
  }

  ngOnInit() {
    // this.menuList = [
    //   { id: 1, label: 'Planilla', end_point: '/planilla', icon: 'book_online' },
    //   { id: 2, label: 'Mapa de Restaurante', end_point: '/restaurante', icon: 'dashboard_customize' },
    //   { id: 3, label: 'Asignar Reserva', end_point: '/asignacion', icon: 'addchart' },
    //   //{id: 2, label: 'Reserva', end_point: '/reserva', icon: 'person'},

    // ]
    this.menuService.getMenus()
      .subscribe(
        //caso exitoso.
        data => {
          this.menuList = data;
          console.log(this.menuList)
          this.menuList.map(
            menu => {
              ROUTES.push(menu);
            });
        });

        this.cantNotificaciones();

        const noti = interval(30000);
        noti.subscribe(()=>{
            this.cantNotificaciones();
        });
  }

  isMobileMenu() {
    return this.menuService.isMobileMenu();
  }

  goToChangePass() {
    this.router.navigate(['cambiar/clave']);
  }

  getNotifications() {
    const conf: MatDialogConfig = {
        minWidth: '400px',
        minHeight: 'auto',
        
    };
    const dialog = this.dialog.open(NotificationListComponent, conf);
    //this.router.navigate(['noti']);
}

cantNotificaciones(){
  this.notificationService.getCantNotifications().subscribe(res=>{
    this.cantidad = res.cantidad;
   });
  } 

}
