import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PersonaService } from '../services/persona.service';
import { ReserveService } from '../services/reserve.service';

@Component({
  selector: 'app-desbloqueo-fecha-form',
  templateUrl: './desbloqueo-fecha-form.component.html',
})
export class DesbloqueoFechaFormComponent implements OnInit {
  form: FormGroup;
  msg: string;


  constructor(
    private dialogRef: MatDialogRef<DesbloqueoFechaFormComponent>,
    private fb: FormBuilder,
    public reserveService: ReserveService,
    private personaService: PersonaService,
    @Inject(MAT_DIALOG_DATA) public data = null
  ) {

    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      observacion: [this.data ? this.data.data.date.observacion : '', Validators.nullValidator],
    });
  }

  ngOnInit() {

  }

  close(action: any) {
    this.dialogRef.close(action);
  }

  /**
   * Método para actualizar la observación de bloqueo de fechas por actividad
   */
  update() {
    let body = this.form.value;
    this.reserveService.updateDate(body, this.data.data.date.fechaBloqueoId)
      .subscribe(
        data => {
          this.buildForm();
          this.close(null);
        });
  }
  /**
   * Método para desbloquear una fecha por actividad
   */
  save() {
    this.reserveService.dateUnlock(this.data.data.date.fechaBloqueoId)
      .subscribe(
        data => {
          this.close(null);
        });
  }

  /**
   * Método para seleccionar un registro del listado de días
   * @param item registro seleccionado
   */
  setChange(item: any) {
    item.check = !item.check;
  }

}
