import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from '../components/modal/modal.component';
import { SettingsService } from '../interceptor/settings.service';
import { Page } from '../models/page';
import { ReserveService } from '../services/reserve.service';
import { UserService } from '../services/user.service';
import { UserFormComponent } from './user-form.component';

@Component({
  selector: 'app-user',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserListComponent implements OnInit {
  displayedColumns: string[] = ['username', 'nombre', 'apellido', 'correo', 'usuarioRol', 'activo', 'fechaAlta', 'remover', 'editar'];
  public dataSource = new MatTableDataSource<any>();
  public pagination: Page = new Page();
  public filter: any = {};
  paginatorRange = this.settingService.PAGINATOR_RANGE;
  userProfileList: any[];

  // paginador del template
  @ViewChild(MatPaginator) paginator: MatPaginator;

  form: FormGroup;

  constructor(public fb: FormBuilder, public userService: UserService,
    public settingService: SettingsService, private dialog: MatDialog,) {
    this.getUserProfileList();
  }

  ngOnInit() {
    this.initForm();
    this.getList(1);
  }

  initForm() {
    this.form = this.fb.group({
      nombre: ['', Validators.nullValidator],
      apellido: ['', Validators.nullValidator],
      username: ['', Validators.nullValidator],
      usuarioRolId: ['', Validators.nullValidator],
      activo: ['', Validators.nullValidator],
    });
  }

  getList(page: number) {
    this.filter = this.form.value;
    this.filter['pagina'] = page;
    this.userService.getUsers(this.filter)
      .subscribe(
        data => {
          if (data != null) {
            this.dataSource.data = data['data'] as any[];
            this.pagination = data['pagination'];
          } else {
            this.pagination = new Page();
          }
        });
  }

  getUserProfileList() {
    this.userService.getProfiles()
      .subscribe(
        data => {
          this.userProfileList = data;
        });
  }

  /**
 * Método para realizar acción según evento del paginado.
 * @param event evento del paginado
 */
  public getServerData(event?: PageEvent) {
    let page = 1;

    page = event.pageIndex + 1;

    if (event.pageIndex === 0 && event.previousPageIndex === 1) {
      page = 1;
    }

    this.filter.cantidad_pagina = event.pageSize;
    this.getList(page);
    return event;
  }

  /**
* Método para eliminar un usuario del sistema
* @param item: usuario a ser eliminado
*/
  deleteUser(item: any) {
    const modalConfig: MatDialogConfig = {
      data: {
        title: 'Eliminar Usuario',
        msg: `¿Confirma que desea eliminar al usuario <b>${item.nombre} ${item.apellido}</b>?`,
        acceptBtnMsg: 'Eliminar',
      }
    };

    //pantalla de confirmación previa a la eliminación
    const dialog = this.dialog.open(ModalComponent, modalConfig);

    dialog.afterClosed().subscribe((resp) => {
      if (resp == 'confirm') {
        this.userService.deleteUser(item.id)
          .subscribe(
            data => {
              this.getList(1);
            });
      }

    });
  }

  /**
  * Método para actualizar datos de un usuario
  * @param item: usuario a ser actualizado
  */
  updateUser(item: any) {
    const conf: MatDialogConfig = {
      data: {
        data: this.userProfileList,
        model: item,
        title: 'Editar Usuario'
      },
      minWidth: '800px',
      // minHeight: '700px'
    };
    const dialog = this.dialog.open(UserFormComponent, conf);
    dialog.afterClosed().subscribe((resp) => {
      if (resp != 'close') {
        this.getList(1);
      }
    });
  }

  addUser() {
    const conf: MatDialogConfig = {
      data: {
        data: this.userProfileList,
        title: 'Agregar Usuario'
      },
      minWidth: '800px',
      // minHeight: '700px'
    };
    const dialog = this.dialog.open(UserFormComponent, conf);
    dialog.afterClosed().subscribe((resp) => {
      if (resp != 'close') {
        this.getList(1);
      }
    });
  }
}
