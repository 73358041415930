import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { WizardFormComponent } from '../wizardform/wizard-form.component';
import { ReserveService } from '../services/reserve.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalComponent } from '../components/modal/modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { CalendarView, CalendarMonthViewDay } from 'angular-calendar';
import { Subject } from 'rxjs';
import { element } from 'protractor';

@Component({
  selector: 'app-reserve',
  //changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './reserve.component.html',
  styleUrls: ['../wizardform/wizard-form.component.scss'],
  animations: [
    trigger('pictureChange', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('shown => hidden', animate('100ms')),
      transition('hidden => shown', animate('3000ms')),
    ])
  ]
})
export class ReserveComponent {

  public reserveForm: FormGroup;
  public minDate = new Date();
  type: any = {};
  reserveList: any[] = [];
  reserve: any = {};
  action: string;
  infoData: any = {};
  available: any = {};
  isNotValid: boolean = true;
  dateSelected: any;
  pasarDia: any = '';

  public reservaTypeOptions: any = [];
  public reserveActiveList: any = [];

  stepListMap: Map<string, any> = new Map<string, any>();

  @ViewChild(WizardFormComponent) wfc: WizardFormComponent;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  locale: string = 'es';

  refresh: Subject<any> = new Subject();

  activeDayIsOpen: boolean = false;

  data: any = {};

  constructor(public fb: FormBuilder, public reserveService: ReserveService,
    private dialog: MatDialog, private _snackBar: MatSnackBar, private datepipe: DatePipe,) {
    localStorage.removeItem('session');
    this.createForm();
  }

  dayClicked(date: CalendarMonthViewDay): void {
    //console.log('dayclicked.......', date.date)
    this.openSnackBar('Fecha seleccionada! Clic en botón "SIGUIENTE"', 'CERRAR')
    if (this.dateSelected) {
      delete this.dateSelected.cssClass;
    }
    this.reserveForm.controls['fechaReserva'].value.fechaReserva = date.date
    date.cssClass = 'cal-day-selected';
    this.dateSelected = date
    this.getReserveType();
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach((day) => {
      if (!this.dateIsValid(day.date)) {
        day.cssClass = 'cal-disabled';
      }
    });
  }

  dateIsValid(date: Date): boolean {
    return this.datepipe.transform(date, 'yyyy-MM-dd') >= this.datepipe.transform(this.minDate, 'yyyy-MM-dd');
  }

  public createForm() {
    this.reserveList = [];
    this.reserve = {};
    this.type = {};
    this.action = '';
    this.reserveActiveList = [];
    this.stepListMap = new Map<string, any>();
    if (this.dateSelected) {
      delete this.dateSelected.cssClass;
    }
    this.reserveForm = this.fb.group({
      fechaReserva: this.fb.group({
        fechaReserva: [this.datepipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required],
      }),
      type: this.fb.group({
        type: ['', Validators.required],
      }),
      form: this.initForm(null),
      reserveCant: this.fb.group({
        cantidadReserva: ['', Validators.required],
        horarioReserva: ['', Validators.nullValidator],
      })
    });
    this.getReserveType();
  }

  public displayBootstrapClass(field: string) {
    if (this.reserveForm.controls['type'].value.type) {
      this.getTypeReservation();
    }
    return WizardFormComponent.displayBootstrapClass(this.reserveForm, field);
  }

  /**
  * Funcion para obtener la lista de tipos de reservas existentes
  */
  getReserveType() {
    let d = this.reserveForm.value.fechaReserva.fechaReserva
    let body = { fechaReserva: this.datepipe.transform(d, 'yyyy-MM-dd') };
    this.reserveService.getReserveType(body)
      .subscribe(
        data => {
          this.data = data;
          this.reservaTypeOptions = data.data;
        });
  }

  getTypeReservation(): any {
    let type = this.reserveForm.controls['type'].value.type;
    this.reservaTypeOptions.forEach(element => {
      if (element.id == type) {
        this.type = element;
      }
    });
  }

  onSubmit(event) {
    let r = {
      id: this.type.id,
      nombre: this.type.nombre,
      cantidad: this.reserveForm.value.reserveCant.cantidadReserva,
      tipo: this.infoData.tipo,
      horario: this.infoData.tipo == 'time' || this.infoData.tipo == 'listMulti' ? this.reserveForm.value.reserveCant.horarioReserva : []
    };
    if (this.action == 'close' || this.action == '') {
      this.reserveList.pop();
    }
    this.reserveList.push(r);
    let d = this.reserveForm.value.fechaReserva.fechaReserva
    this.reserve = {
      fechaReserva: this.datepipe.transform(d, 'yyyy-MM-dd'),
      tipoReserva: this.reserveList,
      cedula: this.reserveForm.value.form.cedula.toString(),
      nombre: this.reserveForm.controls['form'].value.nombre,
      apellido: this.reserveForm.controls['form'].value.apellido,
      celular: this.reserveForm.controls['form'].value.celular,
      email: this.reserveForm.controls['form'].value.email,
      observacion: this.reserveForm.controls['form'].value.observacion
    }
    this.reserveConfirm();
  }

  /**
  * Método para confirmar reserva o agregar una nueva reserva antes de confirmar la actual
  **/
  reserveConfirm() {
    let type = "";
    this.reserve.tipoReserva.forEach(element => {
      type = type + `<br><b>Tipo Reserva: </b>${element.nombre}
                    <b>  Cantidad: </b>${element.cantidad}`
      if (element.horario.length > 0) {
        type = type + `<b>  Horario: </b>${element.horario}`
      }
      if (element.observacion == undefined) {
        element.observacion = this.reserveForm.controls['form'].value.observacion;
      }
      type = type + `<b>  Observación: </b>${element.observacion}`
    });
    this.reserveActiveList = this.reserve.tipoReserva;
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      data: {
        title: 'Confirmación de Reserva',
        msg: `<br>
<b>Fecha de Reserva: </b>${this.reserve.fechaReserva}
        ${type}
        <br><b>Número de Cédula: </b>${this.reserveForm.controls['form'].value.cedula}
        <br><b>Nombre: </b>${this.reserveForm.controls['form'].value.nombre}
        <br><b>Apellido: </b>${this.reserveForm.controls['form'].value.apellido}
        <br><b>Número de Celular: </b>${this.reserveForm.controls['form'].value.celular}
        <br><b>Correo Electrónico: </b>${this.reserveForm.controls['form'].value.email}
        <br>¿Qué desea hacer, <b>Agregar otra Reserva</b> o <b>Confirmar Reserva</b>?`,
        acceptBtnMsg: 'CONFIRMAR RESERVA',
        cancelBtnMsg: 'AGREGAR RESERVA'
      }
    };

    this.dialog.open(ModalComponent, dialogConfig).afterClosed().subscribe(resp => {
      this.action = resp;
      if (resp) {
        if (resp == 'add') {
          this.reserveForm.value.type.type = '';
          this.reserveForm.value.reserveCant.horarioReserva = '';

          this.reserveForm.controls['form'] = this.initForm(this.reserveForm.controls['form'].value);
          //this.reserveForm.controls['reserveCant'].value.cantidadReserva = '';
          this.available = {};
          this.type = {};
          this.wfc.addReserve(2);
        } else if (resp == 'confirm') {
          this.reserveService.saveReserve(this.reserve)
            .subscribe(
              data => {

                this.wfc.resetActive();
                this.createForm();
                this.openSnackBar('Reserva Exitosa. Verifique su correo ✔✔', 'CERRAR');
              }, error => {
                this.action = 'close';
              });
        }
      }
    });
}
  openSnackBar(title: any, action: any) {
    this._snackBar.open(title, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  async getInfo() {
    this.available = {};
    let id = this.type.id;
    let d = this.reserveForm.value.fechaReserva.fechaReserva;
    let body = { fechaReserva: this.datepipe.transform(d, 'yyyy-MM-dd') };
    //console.log(body);
    await this.reserveService.getReserveAvailable(id, body)
      .subscribe(
        data => {
          this.infoData = data;
          //this.reserveForm.controls['reserveCant'].value.controls['cantidadReserva'].setValidators(Validators.minLength(this.infoData.mostrarMinimo ? this.infoData.cantidadMinima : 1))
        });
        
        if(this.type.id === 1){
          this.pasarDia = '';
          }else{
            this.pasarDia = null;
         }

  }

  validation() {
    this.available = {};
    let id = this.type.id;
    let d = this.reserveForm.value.fechaReserva.fechaReserva;
    let body = { fechaReserva: this.datepipe.transform(d, 'yyyy-MM-dd'), tipo: this.infoData.tipo, horario: this.reserveForm.value.reserveCant.horarioReserva };
    this.reserveService.reserveVerification(id, body)
      .subscribe(
        data => {
          this.available = data;
          if (this.available.cantidadDisponible > 0) {
            this.isNotValid = false;
          } else {
            this.openSnackBar('No hay reservas disponibles, seleccione otro horario', 'CERRAR')
          }
        });
  }

  searchClient() {
    //console.log('buscar cliente')
    this.reserveService.getClient(this.reserveForm.value.form.cedula)
      .subscribe(
        data => {
          let client = data['cliente'];
          //console.log(client)
          if (client) {
            //this.reserveForm.controls['form'].value.nombre = client.nombre;
            ////console.log(this.reserveForm.controls['form'].value.nombre)
            //this.reserveForm.value.form.nombre = client.nombre;
            //this.reserveForm.value.form.apellido = client.apellido;
            //this.reserveForm.value.form.celular = client.celular;
            //this.reserveForm.value.form.email = client.email;

            this.reserveForm.controls['form'] = this.initForm(client);
          }
        });
  }

  initForm(data: any) {
    return this.fb.group({
      cedula: [data ? data.cedula : '', [Validators.minLength(5), Validators.required]],
      nombre: [data ? data.nombre : '', [Validators.required, Validators.minLength(3)]],
      apellido: [data ? data.apellido : '', [Validators.required, Validators.minLength(3)]],
      celular: [data ? data.celular : '', [Validators.required, Validators.minLength(10)]],
      email: [data ? data.email : '', [
        Validators.email,
        Validators.required
      ]],
      observacion: ''
    });
  }

  /**
   * Método para validar valor ingresado como cantidad de reserva vs cantidad disponible
   * @param event evento del valor ingresado
   */
  validationValue(event: any) {
    let cant = event.key;
    if (this.reserveForm.value.reserveCant.cantidadReserva != null) {
      cant = this.reserveForm.value.reserveCant.cantidadReserva.toString() + event.key;
    }
    /*if (this.infoData.mostrarMinimo) {
      if (parseInt(cant) < this.infoData.cantidadMinima) {
        event.preventDefault();
        this.openSnackBar('Debe reservar al menos para ' + this.infoData.cantidadMinima + ' personas', 'CERRAR')
      }
    }*/

    if (this.infoData.tipo == 'time' || this.infoData.tipo == 'listMulti') {
      if (parseInt(cant) > this.available.cantidadDisponible) {
        event.preventDefault();
        this.openSnackBar('No contamos con disponibilidad para ' + cant + ' personas', 'CERRAR')
      }
    } else {
      if (parseInt(cant) > this.infoData.cantidadDisponible) {
        event.preventDefault();
        this.openSnackBar('No contamos con disponibilidad para ' + cant + ' personas', 'CERRAR')
      }
    }

  }

  mySelections: string[];

  changed() {
    //console.log(this.reserveForm.controls['reserveCant'].value.horarioReserva)
    if (this.reserveForm.value.reserveCant.horarioReserva.length < 3) {
      this.mySelections = this.reserveForm.value.reserveCant.horarioReserva;
    } else {
      this.reserveForm.value.reserveCant.horarioReserva = this.mySelections;
    }
  }

  /**
 * Método para validar valor ingresado como cantidad de reserva vs cantidad disponible
 * @param event evento del valor ingresado
 */
  minValidation(event: any) {
    //console.log(this.reserveForm.value.reserveCant.cantidadReserva)
    let cant = event.target.value;

    if (this.infoData.mostrarMinimo) {
      if (parseInt(cant) < this.infoData.cantidadMinima) {
        this.reserveForm.value.reserveCant.cantidadReserva = null

        //console.log(this.reserveForm.value.reserveCant.cantidadReserva)
        this.openSnackBar('Debe reservar al menos para ' + this.infoData.cantidadMinima + ' personas', 'CERRAR');
        //event.target.value = null;
        event.preventDefault();
      }
    }
  }
  /**
   * Método ejecutado al avanzar a cada step, para validar en cada paso los campos requeridos
   * no dejar avanzar sino cumple los requeridos.
   * @param event el step activo
   */
  onStepChanged(event) {
    //mapa que guarda cada objeto step con el key del título
    this.stepListMap.set(event.title, event);
    //console.log(this.type);
    if (event.title == 'Cantidad de Persona' && !this.type.id) {
      this.wfc.activeStep = this.stepListMap.get('Tipo de Reserva');
    } else {
      if (event.title == 'Formulario de Reserva' && (this.reserveForm.value.reserveCant.cantidadReserva == '' || this.reserveForm.value.reserveCant.cantidadReserva == null)) {
        this.wfc.activeStep = this.stepListMap.get('Cantidad de Persona');
      }
    }
  }
}
