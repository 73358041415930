import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '../interceptor/settings.service';

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {

  constructor(
    private http: HttpClient,
    private settings: SettingsService
  ) { }

  /**
  * Método para obtener el listado de mesas de una zona dada
  * @param body el identificador de la zona
  */
  getTable(body: any): Observable<any> {
    return this.http.post(this.settings.URL_BASE + 'mesa/list', body);
  }

  /**
  * Método para guardar una mesa
  * @param body: contiene los datos para una mesa
  */
  saveTable(body: any): Observable<any> {
    return this.http.post(this.settings.URL_BASE + `mesa`, body);
  }

  /**
  * Método para guardar las mesas ubicadas en el mapa.
  * @param body: contiene los datos para una mesa
  */
  saveAllTable(body: any): Observable<any> {
    return this.http.put(this.settings.URL_BASE + `mesa/all`, body);
  }

  /**
  * Método para guardar una mesa actualizada
  * @param body: contiene los datos de una mesa
  */
  updateTable(body: any) {
    return this.http.put(this.settings.URL_BASE + `mesa/${body.id}`, body);
  }

  /**
  * Método para eliminar una mesa
  * @param id: identificador de la mesa a eliminar
  */
  deleteTable(id: any) {
    return this.http.delete(this.settings.URL_BASE + `mesa/${id}`);
  }

  /**
  * Método para obtener la lista de zonas donde ubicar las mesas
  */
  getZoneType() {
    return this.http.get(this.settings.URL_BASE + `mesa/zona`);
  }

  /**
  * Método para obtener la lista de horarios disponibles para el restaurante
  */
  getHorario(): Observable<any> {
    return this.http.get(`${this.settings.URL_BASE}restaurante/horario`);
  }

  /**
  * Método para obtener el listado de reservas de restaurante
  * @param body filtro para obtener el listaddo
  */
  getRestaurantReserve(body: any): Observable<any> {
    return this.http.post(this.settings.URL_BASE + 'restaurante/reserva/list', body);
  }

  /**
  * Método para obtener el listado de mesas de una zona dada para asignar
  * @param body el identificador de la zona
  */
  getRestaurantTable(body: any): Observable<any> {
    return this.http.post(this.settings.URL_BASE + 'restaurante/mesa/list', body);
  }

  /**
  * Método para actualizar una reserva asignada a una mesa
  * @param body: contiene el identificador de una mesa
  *  @param id identificador de la reserva
  */
  updateReserveOfTable(id: any, body: any): Observable<any> {
    return this.http.put(this.settings.URL_BASE + `restaurante/reserva/${id}/mesa`, body);
  }

  /**
  * Método para actualizar una mesa desasignar o liberar.
  * @param body: contiene el estado de la mesa
  */
  updateAsignacion(body: any, id: any): Observable<any> {
    return this.http.put(this.settings.URL_BASE + `restaurante/reserva/${id}/estado`, body);
  }

  /**
  * Método para bloquear una mesa.
  * @param body: contiene información de mesa para su bloqueo
  */
  bloquearMesa(body: any): Observable<any> {
    return this.http.post(this.settings.URL_BASE + `restaurante/reserva/mesa/bloquear`, body);
  }
}
