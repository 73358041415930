import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalComponent } from '../components/modal/modal.component';
import { BloqueoFormComponent } from '../drag-drop-reserve/bloqueo-form.component';
import { ActivityService } from '../services/activity.service';
import { ReserveService } from '../services/reserve.service';
import { BloqueFormComponent } from './bloque-form.component';
import { DesbloqueoFechaFormComponent } from './desbloqueo-fecha-form.component';
import { DesbloqueoFormComponent } from './desbloqueo-form.component';

@Component({
  selector: 'app-disponibilidad-bloque-actividad',
  templateUrl: './disponibilidad-bloque-actividad.component.html',
  styleUrls: ['./disponibilidad-bloque-actividad.component.scss']
})
export class DisponibilidadBloqueActividadComponent implements OnInit {

  dias = [
    { nombre: 'Actividad / Días', diaNumero: null, tipoCabecera: 'TITULO_TABLA' },
    { nombre: 'Mie', diaNumero: 6, tipoCabecera: '' },
    { nombre: 'Jue', diaNumero: 7, tipoCabecera: '' },
    { nombre: 'Vie', diaNumero: 8, tipoCabecera: '' },
    { nombre: 'Sab', diaNumero: 9, tipoCabecera: 'DIA_FERIADO' },
    { nombre: 'Dom', diaNumero: 10, tipoCabecera: 'DIA_FERIADO' },
    { nombre: 'Lun', diaNumero: 11, tipoCabecera: '' },
    { nombre: 'Mar', diaNumero: 12, tipoCabecera: '' },
  ];

  data: any = null;

  fechaActual = {
    dia: null,
    mes: null,
    anho: null,
  };

  diasH = [];

  form: FormGroup;

  searchText = 'Buscando registros...';

  url = '';
  weekday = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];

  actividadesFiltroList = [];
  actividadFiltroCabecera = '';
  selectTodosTxt = 'Desmarcar todos';

  @ViewChild('actividadFiltro') actividadSelect: MatSelect;

  defaultFilter: any;
  statusList: any[];
  selected: any;
  hasError: boolean = false;

  constructor(
    private fb: FormBuilder,
    private reserveService: ReserveService,
    private activityService: ActivityService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private clipboard: Clipboard
  ) {

    this.data = data;

    this.fechaActual.dia = new Date().getDate();
    this.fechaActual.mes = new Date().getMonth() + 1;
    this.fechaActual.anho = new Date().getFullYear();

    this.buildFilterForm();
  }

  ngOnInit(): void {
    this.obtenerActividadesFiltro();
  }

  /**
   * Construye el formulario
   */
  buildFilterForm() {
    this.form = this.fb.group({
      visual: ['nombre'],
      fechaInicio: [],
      fechaFin: [],
      actividadFiltro: [],
      busquedaGlobal: [''],
    });

    // se setean por defecto la fecha de inicio y fecha de fin con una diferencia de 7 dias
    this.form.controls.fechaInicio.setValue(new Date(Date.UTC(this.fechaActual.anho, this.fechaActual.mes - 1, this.fechaActual.dia, 8)));
    this.form.controls.fechaFin.setValue(new Date(Date.UTC(this.fechaActual.anho, this.fechaActual.mes - 1, this.fechaActual.dia + 6, 8)));

    // por cada cambio en el selector de actividades se actualiza el texto con datos comprimidos en el selector
    this.form.controls.actividadFiltro.valueChanges.subscribe(value => {

      if (this.form.controls.actividadFiltro.value) {
        const index = this.actividadesFiltroList.findIndex(e => e.id == this.form.controls.actividadFiltro.value[0])
        if (index > -1) {
          this.actividadFiltroCabecera = this.actividadesFiltroList[index].nombre;
        }
        
      }

    });

  }

  /**
   * Marca/desmarca todos los elementos del selector de tipos de altividades
   */
  marcarTodos() {
    if (this.form.controls.actividadFiltro.value.length === this.actividadesFiltroList.length) {
      this.form.controls.actividadFiltro.setValue([]);
      this.selectTodosTxt = 'Marcar todos';
    } else {
      this.selectTodosTxt = 'Desmarcar todos';
      this.form.controls.actividadFiltro.setValue(this.actividadesFiltroList.map(e => e.id));
    }
  }

  /**
   * Recupera las actividades disponibles para filtrar
   */
  obtenerActividadesFiltro() {
    // se recuperan actividades para el filtro
    this.reserveService.getActividadesFiltro().subscribe(data => {

      this.actividadesFiltroList = data;

      const actividadesId = this.actividadesFiltroList.map(e => e.id);
      this.form.controls.actividadFiltro.setValue(actividadesId);

      // setear estas fechas al inicio
      this.defaultFilter = {
        fechaDesde: this.form.controls.fechaInicio.value,
        fechaHasta: this.form.controls.fechaFin.value,
        tipoActividad: actividadesId,
      };
      this.getPlanilla(this.defaultFilter);

    });
  }

  /**
   * Se ejecuta al cerrar el select
   * @param opened
   */
  cerrarSelect(opened: boolean) {
    if (!opened) {
      this.buscarActividades();
    }
  }

  /**
   * Arma el filtro y recupera la nueva planilla
   */
  buscarActividades() {


    if (this.form.controls.fechaInicio.value && !this.form.controls.fechaFin.value ||
      !this.form.controls.fechaInicio.value && this.form.controls.fechaFin.value) {
      this.snackbar.open('Debe seleccionar Fecha de Inicio y Fecha de Fin', 'Entendido', { duration: 2000 })
      return;
    }

    const inicio = new Date(this.form.controls.fechaInicio.value);
    const fin = new Date(this.form.controls.fechaFin.value);
    const filtro = {
      fechaDesde: `${inicio.getMonth() + 1}-${inicio.getDate()}-${inicio.getFullYear()}`,
      fechaHasta: `${fin.getMonth() + 1}-${fin.getDate()}-${fin.getFullYear()}`,
      tipoActividad: this.form.controls.actividadFiltro.value,
      busquedaGlobal: this.form.controls.busquedaGlobal.value
    }
    this.defaultFilter = filtro;

    this.getPlanilla(filtro);

  }

  /**
   * Recupera la planilla a mostrar
   * @param filtro filtro para recuperar contenido especifico
   */
  getPlanilla(filtro = null) {

    this.searchText = 'Buscando registros...';

    // ceramos el listado de dias
    this.data = [];

    // ceramos el header
    this.diasH = [];
    this.activityService.getDisponibilidadBloqueActividad(filtro).subscribe(data => {
      if (data.length > 0) {
        this.diasH = data[0].disponibilidadDias.map(e => {
          e['day'] = new Date(Date.UTC(e.anho, e.mes - 1, e.dia, 8));
          e['nombreDia'] = this.weekday[e.day.getDay()];
          e['tipoCabecera'] = this.weekday[e.day.getDay()] === 'Domingo' ? 'DIA_FERIADO' : this.weekday[e.day.getDay()] === 'Sábado' ? 'DIA_FERIADO' : ''
          return e;
        });
        this.diasH = [...[{ dia: null, nombreDia: 'Actividad / Días', diaKey: null, tipoCabecera: 'TITULO_TABLA' }], ...this.diasH]
      } else {
        this.searchText = 'No se encontraron resultados...';
      }
      this.data = data;
    });
  }

  /**
   * Recupera la cantidad de dias para un mes
   * @param month mes
   * @param year anho al que pertenece el mes
   * @returns cantidad de dias del mes en ese anho
   */
  getDiasDelMes(month: number, year: number): number {
    return new Date(year, month, 0).getDate();
  }


  /**
   * Abre el formulario para agregar una disponibilidad en bloque
   */
  openForm() {
    const config: MatDialogConfig = {
      width: '500px',
      minHeight: '65vh',
      data: this.actividadesFiltroList
    };

    const dialog = this.dialog.open(BloqueFormComponent, config);

    dialog.afterClosed().subscribe((resp) => {
      
      if (resp != 'close') {
        this.getPlanilla(this.defaultFilter)
      }
    });
  }

  /**
  * Método para eliminar una reserva
  * @param activity: actividad de reserva a ser eliminada
  * @param reserve: reserva a ser eliminada
  */
  delete(activity: any, block: any) {
    const modalConfig: MatDialogConfig = {
      data: {
        title: 'Eliminar Bloque',
        msg: `¿Confirma que desea eliminar la disponibilidad en bloque de <b>${activity.nombre}</b>?`,
        acceptBtnMsg: 'Eliminar',
      }
    };

    //pantalla de confirmación previa a la eliminación
    const dialog = this.dialog.open(ModalComponent, modalConfig);

    dialog.afterClosed().subscribe((resp) => {
      if (resp == 'confirm') {
        this.activityService.deleteBlock(block.id)
          .subscribe(
            data => {
              this.getPlanilla(this.defaultFilter)
            });
      }
    });
  }

  blockedDate() {
    const config: MatDialogConfig = {
      width: '500px',
      minHeight: '65vh',
      data: this.actividadesFiltroList
    };

    const dialog = this.dialog.open(BloqueoFormComponent, config);

    dialog.afterClosed().subscribe((resp) => {
      
      if (resp != 'close') {
        this.getPlanilla(this.defaultFilter);
      }
    });

  }
  /**
   * Método para desbloquear una actividad en una fecha específica
   * @param activity actividad a ser desbloqueada
   * @param date fecha específica a desbloquear
   */
  dateUnlock(activity: any, date: any) {  
    const modalConfig: MatDialogConfig = {
      data: {
        data: {activity: activity, date: date},
      }
    };

    //pantalla de confirmación previa a la eliminación
    const dialog = this.dialog.open(DesbloqueoFechaFormComponent, modalConfig);

    dialog.afterClosed().subscribe((resp) => {
      if (resp != 'close') {
        this.getPlanilla(this.defaultFilter);
      }
    });

  }

  /**
   * Abre el formulario para agregar una disponibilidad en bloque
   */
  desbloqueoForm() {
    const config: MatDialogConfig = {
      width: '500px',
      minHeight: '65vh',
      data: this.actividadesFiltroList
    };

    const dialog = this.dialog.open(DesbloqueoFormComponent, config);

    dialog.afterClosed().subscribe((resp) => {
      if (resp != 'close') {
        this.getPlanilla(this.defaultFilter)
      }
    });
  }
}

export const data = [];

