<div class="">
    <mat-toolbar class="dialog-toolbar" color="primary" fxLayout="row" fxLayoutAlign="space-between center">
        <div>{{data.title}}</div>
        <div>
            <button mat-icon-button (click)="close('close')">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>

    <br>

    <div mat-dialog-content [formGroup]="form" fxLayout="column" fxLayoutGap="20px" fxLayout.xs="column" fxLayout.sm="column">

        <div class="form-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px" fxLayout.xs="column" fxLayout.sm="column">
            <mat-form-field appearance="outline">
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="nombre">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Cantidad</mat-label>
                <input matInput type="number" formControlName="cantidad">
            </mat-form-field>
        </div>

        <div class="form-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px" fxLayout.xs="column" fxLayout.sm="column">
            <mat-form-field appearance="outline">
                <mat-label>Secuencia</mat-label>
                <input matInput type="number" formControlName="secuencia">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Activo</mat-label>
                <mat-select formControlName="activo">
                    <mat-option [value]="true">
                        SÍ
                    </mat-option>
                    <mat-option [value]="false">
                        NO
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>

<br>

<div class="btn-action-container">

    <button class="btn btn-cancel" (click)="close('close')">
        <mat-icon>close</mat-icon>
        Cerrar
    </button>
    <button class="btn btn-general" (click)="save()" [disabled]="form.invalid">
        <mat-icon>save</mat-icon>
        Guardar
    </button>
</div>