<div class="">
    <mat-toolbar class="dialog-toolbar" color="primary" fxLayout="row" fxLayoutAlign="space-between center">
        <div>Nueva Reserva</div>
        <div>
            <button mat-icon-button (click)="close()" [mat-dialog-close]="close">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>

    <br>

    <div mat-dialog-content [formGroup]="form" fxLayout="column" fxLayoutGap="20px" fxLayout.xs="column" fxLayout.sm="column">

        <div class="form-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px" fxLayout.xs="column" fxLayout.sm="column">
            <mat-form-field appearance="outline">
                <mat-label>Fecha de Reserva</mat-label>
                <input matInput  formControlName="fechaReserva" [matDatepicker]="pickerReserva"
                    (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)">
                <mat-datepicker-toggle matSuffix [for]="pickerReserva"></mat-datepicker-toggle>
                <mat-datepicker #pickerReserva></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Actividad</mat-label>
                <mat-select formControlName="tipoReserva" [disabled]="disponible"
                    (selectionChange)="selectionChange($event)">
                    <mat-option *ngFor="let l of list" [value]="l.id">{{l.nombre}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="form-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px" fxLayout.xs="column" fxLayout.sm="column">
            <div fxLayout="column">
                <mat-form-field appearance="outline" *ngIf="infoData.tipo == 'listMulti'">
                    <mat-label>Horario Reserva</mat-label>
                    <mat-select formControlName="horarioReserva" #mySelect multiple
                        (openedChange)="closeSelect($event)">
                        <mat-option *ngFor="let l of infoData.valor" [value]="l.valor">{{l.valor}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" *ngIf="infoData.tipo == 'time'">
                    <mat-label>Horario Reserva</mat-label>
                    <mat-select formControlName="horarioReserva" #myHoursSelect (selectionChange)="validation()">
                        <mat-option *ngFor="let l of infoData.valor" [value]="l.valor">{{l.valor}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>Cantidad de Personas</mat-label>
                        <input matInput min="1"
                            [max]="available.cantidadDisponible ? available.cantidadDisponible : infoData.cantidadDisponible"
                            type="number" formControlName="cantidadReserva"
                            [readonly]="cantDisponible">
                    </mat-form-field>
                    <small *ngIf="infoData.tipo == 'normal' || available.cantidadDisponible > 0"
                        class="text-danger">Cantidad máxima disponible: {{available.cantidadDisponible ?
                        available.cantidadDisponible : infoData.cantidadDisponible }}</small>
                </div>
            </div>
            <mat-form-field appearance="outline">
                <mat-label>Número de Cédula</mat-label>
                <input matInput min="6" type="number" [readonly]="cantDisponible" formControlName="cedula" (blur)="searchClient()">
            </mat-form-field>
        </div>
        <div class="form-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px" fxLayout.xs="column" fxLayout.sm="column">
            <mat-form-field appearance="outline">
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="nombre" [readonly]="cantDisponible">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Apellido</mat-label>
                <input matInput formControlName="apellido" [readonly]="cantDisponible">
            </mat-form-field>
        </div>
        <div class="form-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px" fxLayout.xs="column" fxLayout.sm="column">
            <mat-form-field appearance="outline">
                <mat-label>Número de Celular</mat-label>
                <input matInput formControlName="celular" [readonly]="cantDisponible">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Correo Electrónico</mat-label>
                <input matInput type="email" formControlName="email" [readonly]="cantDisponible">
            </mat-form-field>
        </div>
        <mat-form-field appearance="outline">
            <mat-label>Observación</mat-label >
            <textarea matInput formControlName="observacion" [readonly]="cantDisponible"></textarea>
        </mat-form-field>

    </div>
</div>

<br>

<div class="btn-action-container">

    <button class="btn btn-cancel" (click)="close()" [mat-dialog-close]="close">
        <mat-icon>close</mat-icon>
        Cerrar
    </button>
    <button class="btn btn-general" [disabled]="form.invalid" (click)="saveReserve()">
        <mat-icon>save</mat-icon>
        Agregar
    </button>

</div>