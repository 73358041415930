import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordFormComponent implements OnInit {
  form: FormGroup;
  model: any = {};
  token: any;
  hidePass = false;
  hideconfirmPass = false;

  constructor(private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public authenticationService: AuthenticationService,
    private _snackBar: MatSnackBar) {
    localStorage.removeItem('session');
  }

  ngOnInit() {
    this.token = this.activeRoute.snapshot.paramMap.get('token');
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      password: ['', Validators.compose([
        Validators.minLength(6),
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$')
      ])],
      confirmPassword: ['', Validators.compose([
        Validators.required
      ])],
    }, { validators: this.checkPasswords });
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;
  
    return password === confirmPassword ? null : { notSame: true }     
  }

  save() {
    if (this.form.valid) {
      this.model = { password: this.form.value.password };
      this.authenticationService.confirmPassReset(this.model, this.token)
        .subscribe(
          data => {
            let msg = 'Reseteo Exitoso. Inicie sesión con su nueva contraseña ✔✔';
            if (data && !data.success) {
              msg = 'Advetencia. ' + data.message;
            }
            this.buildForm();
            this.openSnackBar(msg, 'CERRAR');
            this.router.navigate(['login']);
          }, error => {
            this.openSnackBar('Advertencia. ' + error.message, 'CERRAR');
          });
    } else {
      this.openSnackBar('Advertencia. Complete todos los datos', 'CERRAR');
    }
  }

  openSnackBar(title: any, action: any) {
    this._snackBar.open(title, action, {
      duration: 20000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
