import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ModalComponent } from '../components/modal/modal.component';
import { SettingsService } from '../interceptor/settings.service';
import { Page } from '../models/page';
import { ClientService } from '../services/client.service';
import { ClientAdminFormComponent } from './client-admin-form.component';

@Component({
  selector: 'app-client-admin',
  templateUrl: './client-admin-list.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientAdminListComponent implements OnInit {
  
  displayedColumns: string[] = ['cedula', 'nombre', 'apellido', 'celular', 'correo', 'bloqueado', 'fechaAlta', 'remover', 'editar', 'bloquear'];
  public dataSource = new MatTableDataSource<any>();
  public pagination: Page = new Page();
  public filter: any = {};
  paginatorRange = this.settingService.PAGINATOR_RANGE;
  selectList: any[] = [
    {key: null, value: 'TODOS'},
    {key: true, value: 'SÍ'},
    {key: false, value: 'NO'},
  ]

  // paginador del template
  @ViewChild(MatPaginator) paginator: MatPaginator;

  form: FormGroup;

  constructor(public fb: FormBuilder, public clientSevice: ClientService,
    public settingService: SettingsService, private dialog: MatDialog,) {
  }

  ngOnInit() {
    this.initForm();
    this.getList(1);
  }

  initForm() {
    this.form = this.fb.group({
      nombre: ['', Validators.nullValidator],
      apellido: ['', Validators.nullValidator],
      cedula: ['', Validators.nullValidator],
      bloqueado: [null, Validators.nullValidator]
    });
  }

  getList(page: number) {
    this.filter = this.form.value;
    this.filter['pagina'] = page;
    this.filter['cantPagina'] = 10; 
    this.clientSevice.getAllClients(this.filter)
      .subscribe(
        data => {
          if (data != null) {
            this.dataSource.data = data['data'] as any[];
            this.pagination = data['pagination'];
          } else {
            this.pagination = new Page();
          }
        });
  }

  /**
   * Método para realizar acción según evento del paginado.
   * @param event evento del paginado
   */
  public getServerData(event?: PageEvent) {
    let page = 1;

    page = event.pageIndex + 1;

    if (event.pageIndex === 0 && event.previousPageIndex === 1) {
      page = 1;
    }

    this.filter.cantidad_pagina = event.pageSize;
    this.getList(page);
    return event;
  }

  /**
  * Método para eliminar un cliente del sistema
  * @param item: cliente a ser eliminado
  */
  deleteClient(item: any) {
    const modalConfig: MatDialogConfig = {
      data: {
        title: 'Eliminar Cliente',
        msg: `¿Confirma que desea eliminar al cliente <b>${item.nombre} ${item.apellido}</b>?`,
        acceptBtnMsg: 'Eliminar',
      }
    };

    //pantalla de confirmación previa a la eliminación
    const dialog = this.dialog.open(ModalComponent, modalConfig);

    dialog.afterClosed().subscribe((resp) => {
      if (resp == 'confirm') {
        this.clientSevice.deleteClient(item.id)
          .subscribe(
            data => {
              this.getList(1);
            });
      }

    });
  }

  /**
  * Método para actualizar datos de un cliente
  * @param item: cliente a ser actualizado
  */
  updateClient(item: any) {
    const conf: MatDialogConfig = {
      data: {
        model: item,
        title: 'Editar Cliente'
      },
      minWidth: '800px',
      // minHeight: '700px'
    };
    const dialog = this.dialog.open(ClientAdminFormComponent, conf);
    dialog.afterClosed().subscribe((resp) => {
      if (resp != 'close') {
        this.getList(1);
      }
    });
  }

  addClient() {
    const conf: MatDialogConfig = {
      data: {
        title: 'Agregar Cliente'
      },
      minWidth: '800px',
      // minHeight: '700px'
    };
    const dialog = this.dialog.open(ClientAdminFormComponent, conf);
    dialog.afterClosed().subscribe((resp) => {
      if (resp != 'close') {
        this.getList(1);
      }
    });
  }

  updateState(item: any) {
    item.bloqueado = !item.bloqueado;
    this.clientSevice.updateClient(item.id, item)
        .subscribe(
          data => {
            this.getList(1);
          });
  }
}
