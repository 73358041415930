//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//routers
import { loginRouting } from './login.routing';
import { ComponentsModule } from '../components/components.module';
import { MaterialModule } from '../core/material.module';
import { LoginComponent } from './login.component';
import { AuthenticationService } from '../services/authentication.service';
import { LoaderModule } from '../components/loader/loader.module';
import { ForgetPasswordComponent } from './forget-password.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        loginRouting,
        ComponentsModule,
        LoaderModule
    ],
    declarations: [
        LoginComponent,
        ForgetPasswordComponent
    ],
    providers: [
        AuthenticationService
    ],
    exports: [
        LoginComponent,
        ForgetPasswordComponent
    ]
})

export class LoginModule { }