import { MatPaginatorIntl } from '@angular/material/paginator';

/**
 * Cambia el texto de 'cantidad' del paginador a español
 * @param page página
 * @param pageSize cantidad de ítems por página
 * @param length longitud
 */
const esRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) { return `0 DE ${length}`; }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // calculo para la longitud de la lista
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} DE ${length}`;
}

/**
 * Configuración a español
 */
export function GetEsPaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = 'CANTIDAD POR PÁGINA:';
    paginatorIntl.nextPageLabel = 'PÁGINA SIGUIENTE';
    paginatorIntl.previousPageLabel = 'PÁGINA ANTERIOR';
    paginatorIntl.firstPageLabel = 'PRIMER PÁGINA';
    paginatorIntl.lastPageLabel = 'ÚLTIMA PÁGINA';
    paginatorIntl.getRangeLabel = esRangeLabel;

    return paginatorIntl;
}
