<div class="main-content">
  <div class="container-fluid">
    <app-loader></app-loader>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6" *ngFor="let s of seccion">
        <div class="card card-stats card-section" (click)="getSection(s)">
          <div class="card-header card-header-warning card-header-icon">
            <div class="card-icon" [class]="s.color">
              <i class="material-icons">{{s.icono}}</i>
            </div>
            <p class="card-category">{{s.nombre}}</p>
            <h3 class="card-title">{{s.cantidadMesas}}<small>Mesas</small></h3>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-map">
      <mat-drawer-container>
        <mat-drawer mode="side" opened #drawer class="detalle-box">
          <div *ngIf="isSelected">
            <div *ngIf="selectTable">
              <mat-toolbar color="primary" class="btn-general" fxLayout="row" fxLayoutAlign="space-between center">               
                <div>
                  Detalle de Mesa
                  <h6>{{title}}</h6>
                </div>
              </mat-toolbar>

              <div class="p-15 actividad-content">
                <h3 class="text-detail">Número de Mesa: <span>{{selectTable.nombre}}</span></h3>
                <h3 class="text-detail">Capacidad:<span> {{selectTable.cantidad}} personas</span></h3>
                <h3 class="text-detail">Creado por: <span>{{selectTable.usuario}}</span></h3>

                <div fxLayout="row-actividad" fxLayoutAlign="center center">
                  <button class="btn btn-cancel" (click)="deleteTable(selectTable)">
                    <mat-icon>delete</mat-icon>
                    Eliminar
                  </button>
                  <button mat-raised-button class="btn btn-general" (click)="showForm('Editar')">
                    <mat-icon>edit</mat-icon>
                    Editar
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="!selectTable || titleText == 'Editar'">

              <div class="p-15 actividad-content">
                <div [formGroup]="form" fxLayout="column" fxLayoutGap="20px">


                  <div class="col-md-12 p-0">
                    <mat-form-field appearance="outline">
                      <mat-label>Número de Mesa</mat-label>
                      <input matInput formControlName="nombre">
                    </mat-form-field>
                    <div>
                      <small class="text-danger"
                        *ngIf="form.controls.nombre.touched && form.controls.nombre.errors?.required">El nombre
                        es requerido</small>
                    </div>
                  </div>
                  <div class="col-md-12 p-0">
                    <mat-form-field appearance="outline">
                      <mat-label>Capacidad</mat-label>
                      <input matInput formControlName="cantidad">
                    </mat-form-field>
                    <div>
                      <small class="text-danger"
                        *ngIf="form.controls.cantidad.touched && form.controls.cantidad.errors?.required">La cantidad
                        es requerida</small>
                    </div>
                  </div>

                </div>

                <div fxLayout="row-actividad" fxLayoutAlign="center center">
                  <button class="btn btn-cancel" (click)="cancel()">
                    <mat-icon>clear</mat-icon>
                    Cancelar
                  </button>
                  <button mat-raised-button class="btn btn-general" (click)="add()">
                    <mat-icon>check</mat-icon>
                    Confirmar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!isSelected">
            <mat-toolbar color="primary" class="btn-general" fxLayout="row" fxLayoutAlign="space-between center">               
              <div>
                Detalle de Mesa
                <h6>{{title}}</h6>
              </div>
            </mat-toolbar>
            <div class="actividad-content">
            <h3><span class="material-icons">
              double_arrow
              </span>Puede agregar una mesa en la ubicación que desee</h3>
              <h3><span class="material-icons">
                double_arrow
                </span>Puede arrastrar y soltar cada mesa en la ubicación que desee</h3>
            <h3><span class="material-icons">
              double_arrow
              </span>Puede hacer clic en cada mesa para ver más detalles</h3>
          </div>
          </div>
        </mat-drawer>
        <mat-drawer-content class="box-content">
          <div class="card-header ">
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <h5 class="card-title">
                  MAPA DE {{title}}
                </h5>
              </div>
              <div class="col-md-4 text-right">
                <button class="btn btn-cancel" (click)="showForm('Agregar')">Agregar Mesa</button>
                <button class="btn btn-cancel" (click)="save()">Guardar</button>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="row min-h">
              <div class="resize-drag" *ngFor="let m of mesas" [id]="m.id" (click)="detail(m)"
                [ngStyle]="{'width.px': m.ancho, 'height.px': m.alto, transform: 'translate(' + m.posicionX + 'px, ' + m.posicionY + 'px)'}"
                [attr.data-x]="m.posicionX" [attr.data-y]="m.posicionY">
                {{m.nombre}} ({{m.cantidad}})
              </div>
              <!-- <div class="example-boundary min-h">
                <div *ngFor="let m of mesas" [id]="m.id" class="table-drag" cdkDragBoundary=".example-boundary" cdkDrag
                [ngStyle]="{'width.px': m.ancho, 'height.px': m.alto, transform: 'translate(' + m.posicionX + 'px, ' + m.posicionY + 'px)'}"
                [attr.data-x]="m.posicionX"
                [attr.data-y]="m.posicionY">
                {{m.nombre}} ({{m.cantidad}})
                </div>
              </div> -->
            </div>
          </div>

        </mat-drawer-content>
      </mat-drawer-container>
    </div>
  </div>
</div>