<div class="">
    <mat-toolbar class="dialog-toolbar" color="primary" fxLayout="row" fxLayoutAlign="space-between center">
        <div>Agregar Participante</div>
        <div>
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>

    <br>

    <div mat-dialog-content [formGroup]="form" fxLayout="column"  fxLayoutGap="20px" fxLayoutGap="20px" fxLayout.sm="column">

        <div class="form-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px" fxLayout.xs="column"fxLayout.sm="column">
            <mat-form-field appearance="outline">
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="nombre">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Apellido</mat-label>
                <input matInput formControlName="apellido">
            </mat-form-field>
        </div>

        <div class="form-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px" fxLayout.xs="column"fxLayout.sm="column">
            <mat-form-field appearance="outline">
                <mat-label>N° Cedula</mat-label>
                <input matInput formControlName="cedula">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>N° Celular</mat-label>
                <input matInput number formControlName="celular">
            </mat-form-field>
        </div>
        <div class="form-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px" fxLayout.xs="column"fxLayout.sm="column">
        <mat-form-field appearance="outline">
            <mat-label>Correo electrónico</mat-label>
            <input matInput formControlName="email">
        </mat-form-field>
        </div>
        <!-- <div class="form-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px">
        </div> -->

    </div>
</div>

<br>

<div class="btn-action-container">

    <button class="btn btn-cancel" (click)="close()">
        <mat-icon>close</mat-icon>
        Cerrar
    </button>
    <button class="btn btn-general" (click)="saveParticipant()" [disabled]="form.invalid">
        <mat-icon>save</mat-icon>
        Agregar
    </button>
</div>