<div class="">
    <mat-toolbar class="dialog-toolbar" color="primary" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="cabecera"> Disponibilidad en Bloque</div>
        <div>
            <button mat-icon-button (click)="close('close')">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>

    <br>

    <div mat-dialog-content [formGroup]="form" fxLayout="column" fxLayoutGap="20px">
        <mat-form-field appearance="outline">
            <mat-label>Fecha Desde</mat-label>
            <input matInput formControlName="fechaDesde" [matDatepicker]="pickerInicio">
            <mat-datepicker-toggle matSuffix [for]="pickerInicio"></mat-datepicker-toggle>
            <mat-datepicker #pickerInicio></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Fecha Hasta</mat-label>
            <input matInput formControlName="fechaHasta" [matDatepicker]="pickerFin">
            <mat-datepicker-toggle matSuffix [for]="pickerFin"></mat-datepicker-toggle>
            <mat-datepicker #pickerFin></mat-datepicker>
        </mat-form-field>
        <section>
            <mat-checkbox *ngFor="let d of days" color="primary" [checked]="d.check" (change)="setChange(d)">{{d.value}}
            </mat-checkbox>
        </section>
        <section>
            <div class="row" *ngFor="let l of data">
                <div class="col-md-4">
                    <mat-checkbox color="primary" [id]="l.id" (change)="selectItem(l,$event.checked)">{{l.nombre}}</mat-checkbox>
                </div>
                <div class="col-md-8">
                    <mat-form-field appearance="outline">
                        <mat-label>Cantidad</mat-label>
                        <input matInput type="number" [(value)]="l.valor" (keyup)="cantUpdated(l, $event)" (change)="cantUpdated(l, $event)" [readonly]="!l.check">
                    </mat-form-field>
                </div>
            </div>
        </section>
    </div>
</div>

<br>

<div class="btn-action-container">

    <button class="btn btn-cancel" (click)="close('close')">
        <mat-icon>close</mat-icon>
        Cerrar
    </button>
    <button class="btn btn-general" (click)="saveBlock()" [disabled]="form.invalid || !validation()">
        <mat-icon>save</mat-icon>
        Guardar
    </button>
</div>