<div class="">
    <mat-toolbar class="dialog-toolbar" color="primary" fxLayout="row" fxLayoutAlign="space-between center">
        <div>Actualizar Fecha</div>
        <div>
            <button mat-icon-button (click)="close('close')">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>

    <br>

    <div mat-dialog-content [formGroup]="form" fxLayout="column" fxLayoutGap="20px">    
        <mat-form-field appearance="outline">
            <mat-label>Observación</mat-label>
            <textarea matInput formControlName="observacion"></textarea>
        </mat-form-field>
        <div>¿Qué desea hacer, actualizar observación o desbloquear la actividad <b>{{data.data.activity.nombre}}</b> de fecha <b>{{data.data.date.dia}}/{{data.data.date.mes}}</b>?</div>
        
    </div>
</div>

<br>

<div class="btn-action-container">

    <button class="btn btn-cancel" (click)="close('close')">
        <mat-icon>close</mat-icon>
        Cerrar
    </button>
    <button class="btn btn-general" (click)="update()" [disabled]="form.invalid">
        <mat-icon>save</mat-icon>
        Guardar
    </button>
    <button class="btn btn-cancel" (click)="save()">
        <mat-icon>no_encryption</mat-icon>
        Desbloquear
    </button>
</div>