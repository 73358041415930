<div class="image-container set-full-height">
  <div class="container">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="wizard-container">
          <div class="card wizard-card" data-color="primary">
            <div class="wizard-header">
              <div class="row">
                <div class="col-md-4 col-sm-2"></div>
                <div class="col-md-4 col-sm-8">
                  <img src="../../assets/images/los-lagos-sombra.png">
                </div>
                <div class="col-md-4 col-sm-2"></div>
              </div>
              <h3 class="wizard-title"> Reseteo de Contraseña </h3>
              <h5>Complete el Formulario</h5>
            </div>
            <div class="tab-content">
              <div [formGroup]="form" fxLayout="column" fxLayoutGap="20px">
                <mat-form-field appearance="outline">
                  <mat-label>Contraseña</mat-label>
                  <input [type]="hidePass ? 'text' : 'password'" matInput formControlName="password">
                  <mat-icon matSuffix (click)="hidePass = !hidePass" type="button" tabindex="-1"
                    [matTooltip]="hidePass ? 'Ocultar Contraseña' : 'Mostrar Contraseña'">
                    {{ hidePass ? 'visibility' : 'visibility_off' }}</mat-icon>
                </mat-form-field>
                <div class="text-center">
                  <small class="text-danger"
                    *ngIf="form.controls.password.touched && form.controls.password.errors?.required">La contraseña
                    es requerida</small>
                  <br>
                  <small class="text-danger"
                    *ngIf="form.controls.password.dirty && form.controls.password.errors?.minlength">La contraseña
                    debe tener mínimo 6 caracteres</small>
                  <br>
                  <small class="text-danger"
                    *ngIf="form.controls.password.dirty && form.controls.password.errors?.pattern">La contraseña debe
                    tener al menos una letra mayúscula, una letra minúscula y un número. No se permiten caracteres
                    especiales</small>
                </div>

                <mat-form-field appearance="outline">
                  <mat-label>Confirmación de Contraseña</mat-label>
                  <input [type]="hideconfirmPass ? 'text' : 'password'" matInput formControlName="confirmPassword">
                  <mat-icon matSuffix (click)="hideconfirmPass = !hideconfirmPass" type="button" tabindex="-1"
                    [matTooltip]="hideconfirmPass ? 'Ocultar Contraseña' : 'Mostrar Contraseña'">
                    {{ hideconfirmPass ? 'visibility' : 'visibility_off' }}</mat-icon>
                </mat-form-field>
                <div class="text-center">
                  <small class="text-danger"
                    *ngIf="form.controls.confirmPassword.touched && form.controls.confirmPassword.errors?.required">La
                    confirmación de contraseña
                    es requerida</small>
                  <br>
                  <small class="text-danger"
                  *ngIf="form.controls.confirmPassword.dirty && form.hasError('notSame')">Las
                    contraseñas no coinciden</small>
                </div>
              </div>
              <div class="text-center dialog-btn-container pb-15">
                <button class="btn btn-general" [disabled]="form.invalid" (click)="save()">
                  <mat-icon>check</mat-icon>
                  CONFIRMAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>