<mat-toolbar color="secondary" class="dialog-toolbar">

    <div>
        <span class="toolbar-title">{{ title }} </span>
    </div>

    <span class="toolbar-spacer"></span>

    <button mat-icon-button [mat-dialog-close]="close" tabindex="-1">
        <mat-icon>clear</mat-icon>
    </button>
</mat-toolbar>

<div class="main-content">
    <div mat-dialog-content class="row">
        <div [innerHtml]="msg"></div>
    </div>
</div>

<div class="row">
    <div class="col-md-12 text-center dialog-btn-container">
        <button [mat-dialog-close]="add" class="btn btn-cancel">
            {{ cancelBtnMsg }}
        </button>
        <button [mat-dialog-close]="confirm" class="btn btn-general">
            {{ acceptBtnMsg }}
        </button>
    </div>
</div>