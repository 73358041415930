<mat-toolbar class="dialog-toolbar" color="primary" fxLayout="row" fxLayoutAlign="space-between center">
  <div>Participantes de la reserva {{ data.codigo }}</div>
  <div>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-toolbar>

<br>

<div class="" mat-dialog-content>
  <div>
    <!--<div>Total participantes: 10</div>
    <div>Total confirmados: 3</div>
    <div>Total sin cofirmar: 7</div>-->

    <div [formGroup]="form" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="fecha-filtro" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="row" fxLayout.sm="row">
        <mat-form-field appearance="outline">
          <mat-label>Buscar coincidencia</mat-label>
          <input #busqueda formControlName="busquedaGlobal" matInput (keyup.enter)="buscarParticipante()">
          <button mat-button *ngIf="busqueda.value" matSuffix mat-icon-button aria-label="Clear"
            (click)="form.controls.busquedaGlobal.setValue(null)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <button class="btn btn-general" (click)="buscarParticipante()">
          <mat-icon>search</mat-icon>
        </button>

        <button class="btn btn-general" (click)="openForm()">
          <mat-icon>group_add</mat-icon>Agregar participante
        </button>
      </div>
    </div>

  </div>

  <div class="t-container">
    <table mat-table [dataSource]="dataSource">

      <!-- cedula -->
      <ng-container matColumnDef="cedula">
        <th mat-header-cell *matHeaderCellDef> Cedula N° </th>
        <td mat-cell *matCellDef="let e"> {{e.cedula}} </td>
      </ng-container>

      <!-- nombre -->
      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let e"> {{e.nombre}} </td>
      </ng-container>

      <!-- apellido -->
      <ng-container matColumnDef="apellido">
        <th mat-header-cell *matHeaderCellDef> Apellido </th>
        <td mat-cell *matCellDef="let e"> {{e.apellido}} </td>
      </ng-container>

      <!-- celular -->
      <ng-container matColumnDef="celular">
        <th mat-header-cell *matHeaderCellDef> Celular </th>
        <td mat-cell *matCellDef="let e"> {{e.celular}} </td>
      </ng-container>

      <!-- email -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Correo </th>
        <td mat-cell *matCellDef="let e"> {{e.email}} </td>
      </ng-container>

      <!-- estado -->
      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef> Estado </th>
        <td mat-cell *matCellDef="let e"> {{ e.confirmado ? 'Confirmado' : 'No confirmado' }} </td>
      </ng-container>

      <!-- estado -->
      <ng-container matColumnDef="remover">
        <th mat-header-cell *matHeaderCellDef> Remover </th>
        <td mat-cell *matCellDef="let e">
          <button mat-menu-item (click)="deleteParticipante(e)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="cambiar">
        <th mat-header-cell *matHeaderCellDef> Cambiar Estado </th>
        <td mat-cell *matCellDef="let e">
          <button mat-menu-item (click)="updateParticipant(e)">
            <mat-icon>update</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [length]="pagination.cantidad" [pageSize]="pagination.por_pagina" [pageSizeOptions]="paginatorRange"
    (page)="getServerData($event)">
  </mat-paginator>
</div>


<br>

<div fxLayout="row" fxLayoutAlign="space-around center">
  <button class="btn btn-cancel" (click)="close()">
    <mat-icon>close</mat-icon>
    Cerrar
  </button>
</div>