import { ThrowStmt } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GraficosService } from 'src/app/services/graficos.service';
@Component({
  selector: 'app-grafico-participantes',
  templateUrl: './grafico-participantes.component.html',
  styleUrls: ['./grafico-participantes.component.scss']
})
export class GraficoParticipantesComponent implements OnInit {

  constructor(public graficosService: GraficosService) {  
  }

  @Input() fecha: string;
  datos = []
  bandera: boolean;
 // opciones de grafico de barras vertical
  view: any[] = [undefined, undefined];
  showXAxis2: boolean = true;
  showYAxis2: boolean = true;
  gradiante2: boolean = true;
  mostrarLegend2: boolean = true;
  posicionLegend2: string = "below";
  showXAxisLabel2: boolean = true;
  showYAxisLabel2: boolean = true;
  yAxisLabel2: string = 'Cantidad de Participantes';
  tituloLegend2: string = 'Participantes';
  showDataLabel2: boolean = true;
  round: boolean = false;

  colorScheme = {
    domain: ['#0390be', '#ffcc00', '#80c31b', '#124b45']
  };

  //metodo para traer los datos para el grafico de pie
  getDatosBarra(fechaInput){
    const date = new Date(fechaInput)
    const body = {
      fecha: date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
    }
    this.graficosService.getParticipantes(body).subscribe(
      data =>{
        this.datos = data;
        if(data[0].sinDatos == true){
          this.bandera = true;
        }else{
          this.bandera = false;
        };  
      })
    }

    onResize(event) { this.view = [event.target.innerWidth - 900, 280 ]; }

  ngOnInit(): void {
    this.getDatosBarra(new Date());
    }

}
