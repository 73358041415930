import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '../interceptor/settings.service';

@Injectable({
    providedIn: 'root'
})
export class ActivityService {

    constructor(
        private http: HttpClient,
        private settings: SettingsService
    ) { }

    /**
     * Recupera la lista de actividades del sistema
     */
    getActivities(body: any): Observable<any> {
        return this.http.post(`${this.settings.URL_BASE}configuracion/actividad/list`, body);
    }

    /**
    * Método para agregar una actividad en el sistema
    * @param body: datos de actividad para dar de alta
    */
    addActivity(body: any) {
        return this.http.post(`${this.settings.URL_BASE}configuracion/actividad`, body);
    }

    /**
    * Método para eliminar una actividad del sistema
    * @param id: identificador de la actividad
    */
    deleteActivity(id: any) {
        return this.http.delete(`${this.settings.URL_BASE}configuracion/actividad/${id}`);
    }

    /**
    * Método para actualizar una actividad en el sistema
    * @param id: identificador de la actividad
    */
    updateActivity(id: any, body: any) {
        return this.http.put(`${this.settings.URL_BASE}configuracion/actividad/${id}`, body);
    }

    /**
     * Recupera la lista de actividades del sistema
     */
    getActivityTypes(): Observable<any> {
        return this.http.get(`${this.settings.URL_BASE}configuracion/actividad/tipo`);
    }

    /**
     * Método para obtener la lista de actividades disponibles en bloque
     * @param body filtro de la lista
     * @returns HTTP/1.1 200 OK list | HTTP/1.1 500 Internal Server Error
     */
    getDisponibilidadBloqueActividad(body = null): Observable<any> {
        return this.http.post(
            `${this.settings.URL_BASE}disponibilidad/bloque/planilla`,
            body
        );
    }
    /**
     * Método para agregar bloque de fechas en un rango, por actividad
     * @param body bloque por actividad
     * @returns HTTP/1.1 201 Created || HTTP/1.1 500: Internal server error
     */
    addBlock(body: any) {
        return this.http.post(`${this.settings.URL_BASE}disponibilidad/bloque`, body);
    }

    /**
     * Método para eliminar bloque de fecha, por actividad
     * @param id identificador de la actividad
     * @returns HTTP/1.1 204 No Content || HTTP/1.1 500: Internal server error
     */
    deleteBlock(id: any) {
        return this.http.delete(`${this.settings.URL_BASE}disponibilidad/bloque/${id}`);
    }

    /**
     * Recupera la lista con paginación de actividades por división, del sistema
     * @param id identificador de la actividad padre
     * @param body filtro para obtener la lista
     */
    getDivisionActivities(id: any, body: any): Observable<any> {
        return this.http.post(`${this.settings.URL_BASE}configuracion/actividad/${id}/division/list`, body);
    }
    /**
    * Método para agregar una devisión de actividad en el sistema
    * @param body: datos de división de actividad para dar de alta
    * @param id identificador de la actividad padre
    */
    addActivityDivision(id: any, body: any) {
        return this.http.post(`${this.settings.URL_BASE}configuracion/actividad/${id}/division`, body);
    }

    /**
    * Método para eliminar una división de actividad del sistema
    * @param id: identificador de la división de actividad
    */
    deleteActivityDivision(id: any) {
        return this.http.delete(`${this.settings.URL_BASE}configuracion/actividad/division/${id}`);
    }

    /**
    * Método para actualizar una división de actividad en el sistema
    * @param id: identificador de la división de actividad
    */
    updateActivityDivision(id: any, body: any) {
        return this.http.put(`${this.settings.URL_BASE}configuracion/actividad/division/${id}`, body);
    }
    /**
     * Recupera la lista con paginación de horario de actividad, del sistema
     * @param id identificador de la actividad padre
     * @param body filtro para obtener la lista
     */
    getScheduleActivities(id: any, body: any): Observable<any> {
        return this.http.post(`${this.settings.URL_BASE}configuracion/actividad/${id}/horario/list`, body);
    }
    /**
    * Método para agregar un horario de actividad en el sistema
    * @param body: datos de división de actividad para dar de alta
    * @param id identificador de la actividad padre
    */
    addActivitySchedule(id: any, body: any) {
        return this.http.post(`${this.settings.URL_BASE}configuracion/actividad/${id}/horario`, body);
    }

    /**
    * Método para eliminar un horario de actividad del sistema
    * @param id: identificador del horario de actividad
    */
    deleteActivitySchedule(id: any) {
        return this.http.delete(`${this.settings.URL_BASE}configuracion/actividad/horario/${id}`);
    }

    /**
    * Método para actualizar un hoario de actividad en el sistema
    * @param id: identificador del horario de actividad
    */
    updateActivitySchedule(id: any, body: any) {
        return this.http.put(`${this.settings.URL_BASE}configuracion/actividad/horario/${id}`, body);
    }
}
