<div class="main-content">
  <div class="container-fluid">
    <div class="card">
      <app-loader></app-loader>
      <div class="card-body set-full-height content-body">
        <mat-drawer-container class="example-container">
          <mat-drawer position="end" mode="over" #drawer class="detalle-box">
            <div *ngIf="reservaSeleccionada">
              <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  <button mat-icon-button (click)="drawer.toggle()">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
                <div>
                  Cod: {{ reservaSeleccionada.codigo }}
                </div>
              </mat-toolbar>

              <div class="actividad-content" fxLayout="column">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
                  <h2>{{ actividadSeleccionada.nombre }}</h2>
                  <h2>{{ reservaSeleccionada.dia + '/' + reservaSeleccionada.mes + '/' + reservaSeleccionada.anho }}
                  </h2>
                </div>

                <div class="item estado-detalle"
                  [ngClass]="{'item-pendiente': reservaSeleccionada.estado === 'PENDIENTE', 'item-confirmado': reservaSeleccionada.estado === 'CONFIRMADO', 'item-cancelado': reservaSeleccionada.estado === 'CANCELADO', 'item-rechazado': reservaSeleccionada.estado === 'RECHAZADO'}">
                  <mat-select [(value)]="selected" (selectionChange)="uptadeStatus($event)">
                    <mat-option *ngFor="let l of statusList" [value]="l.id">{{l.nombre}}</mat-option>
                  </mat-select>
                </div>
                <div *ngIf="reservaSeleccionada.estado === 'PENDIENTE'" fxLayout="row"
                  fxLayoutAlign="center center">
                  <button class="btn btn-general" (click)="uptadeStatus(null, true)">
                    <mat-icon>check</mat-icon>
                    Confirmar todas las reservas
                  </button>
                </div>
                <h3 class="text-detail">Reservado por: <span>{{ reservaSeleccionada.nombre }}</span></h3>

                <!-- <h3 class="text-detail">Agendado el: <span>{{ reservaSeleccionada.fechaReserva | date:'dd/MM/yyyy HH:mm'
                    }}</span></h3> -->

                 <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
                    <button class="btn btn-general" (click)="enviarCorreo()">
                      <mat-icon>email</mat-icon>
                      Reenviar correo
                     </button>
                     <button class="btn btn-general" (click)="verPersonaReserva(reservaSeleccionada)">
                      <mat-icon>person</mat-icon>
                      Ver lista de personas
                    </button>
                </div>
                
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
                  <button class="btn btn-general" (click)="copiarEnlace(reservaSeleccionada.enlace)">
                    <mat-icon>link</mat-icon>
                    Copiar enlace de invitación
                  </button>
                  <button class="btn btn-general" (click)="copiarEnlace(reservaSeleccionada.enlaceLista)">
                    <mat-icon>link</mat-icon>
                    Copiar enlace de lista de invitados
                  </button>
                </div>


                <br>
                

                <!-- <div class="text-detail" > -->
                  <div class="horarioss" mat-dialog-content [formGroup]="formReserva" *ngIf="reservaSeleccionada.horario.length === 1">
                    <mat-form-field appearance="outline">
                      <mat-label>Horario</mat-label>
                      <mat-select formControlName="horario" style="text-decoration-color: black">
                        <mat-option *ngFor="let l of listaHorario" [value]="l.valor">
                          {{l.valor}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>    
                <!-- </div> -->

                <div mat-dialog-content [formGroup]="formReserva">
                  <mat-form-field appearance="outline">
                    <mat-label>Cantidad de Personas </mat-label>
                    <input matInput min="1"
                            type="number" formControlName="cantidad">
                  </mat-form-field>
                </div>
                <div mat-dialog-content [formGroup]="formReserva">
                  <mat-form-field appearance="outline">
                    <mat-label>Observación</mat-label>
                    <textarea matInput formControlName="observacion"></textarea>
                  </mat-form-field>
                </div>
                <br>
                <div fxLayout="row" fxLayoutAlign="center center">
                  <button class="btn btn-cancel" (click)="deleteReserve(actividadSeleccionada, reservaSeleccionada)">
                    <mat-icon>delete</mat-icon>
                    Eliminar
                  </button>
                  <button class="btn btn-general" [disabled]="!habilitarEdit || formReserva.invalid " (click)="uptadeReserve()">
                    <mat-icon>mode_edit</mat-icon>
                    Editar
                  </button>
                </div>
              </div>

            </div>
          </mat-drawer>
          <mat-drawer-content>
            <div style="padding: 10px; margin: 10px" class="mat-elevation-z2">
              <div class="style-filter" fxLayout="row" fxLayoutAlign="space-between center"
                [formGroup]="form">

                <div class="fecha-filtro" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="row"
                  fxLayout.sm="row">

                  <mat-form-field appearance="outline">
                    <mat-label>Actividades</mat-label>
                    <mat-select #actividadFiltro formControlName="actividadFiltro" multiple
                      (openedChange)="cerrarSelect($event)">
                      <div class="align-center select-all">
                        <button mat-raised-button (click)="marcarTodos()">{{ selectTodosTxt }}</button>
                      </div>
                      <mat-select-trigger>
                        {{ form.controls.actividadFiltro.value ? actividadFiltroCabecera : ''}}
                        <span *ngIf="form.controls.actividadFiltro.value?.length > 1"
                          class="example-additional-selection">
                          (+{{form.controls.actividadFiltro.value.length - 1}})
                        </span>
                      </mat-select-trigger>
                      <mat-option *ngFor="let e of actividadesFiltroList" [value]="e.id">{{e.nombre}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="date-input">
                    <mat-label>Visualización</mat-label>
                    <mat-select formControlName="visual">
                      <mat-option *ngFor="let tipoVisual of tipoVisualList" [value]="tipoVisual.key">
                        {{tipoVisual.value}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="date-input">
                    <mat-label>Fecha Inicio</mat-label>
                    <input formControlName="fechaInicio" matInput [matDatepicker]="pickerInicio">
                    <mat-datepicker-toggle matSuffix [for]="pickerInicio"></mat-datepicker-toggle>
                    <mat-datepicker #pickerInicio></mat-datepicker>
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="date-input">
                    <mat-label>Fecha Fin</mat-label>
                    <input formControlName="fechaFin" matInput [matDatepicker]="pickerFin">
                    <mat-datepicker-toggle matSuffix [for]="pickerFin"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFin></mat-datepicker>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>Buscar coincidencia</mat-label>
                    <input #busqueda formControlName="busquedaGlobal" matInput (keyup.enter)="buscarActividades()">
                    <button mat-button *ngIf="busqueda.value" matSuffix mat-icon-button aria-label="Clear"
                      (click)="form.controls.busquedaGlobal.setValue(null)">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>

                  <button class="btn btn-general" (click)="buscarActividades()">
                    <mat-icon>search</mat-icon>
                  </button>

                  <button class="btn btn-general" (click)="openReservaForm()">
                    <mat-icon>add</mat-icon>
                    Evento
                  </button>

                </div>

              </div>

              <div cdkDropListGroup cdkDropListOrientation="horizontal" class="custom-table" *ngIf="data.length > 0">

                <div class="row-actividad row-dias">
                  <div class="box-container" *ngFor="let item of diasH">
                    <div class="box box-day" [ngClass]="{'cab-feriado': item.tipoCabecera === 'DIA_FERIADO'}">
                      <div class="item-day">
                        {{item.tipoCabecera === 'TITULO_TABLA' ? item.nombreDia : (item.nombreDia | slice:0:3) + ' ' +
                        (item.dia ? item.dia : '')}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row-actividad" *ngFor="let item of data">
                  <div class="row-actividad">
                    <div class="box-container">

                      <div class="box" style="padding: 0 10px;" fxLayoutAlign="space-around center">
                        <mat-icon>{{ item.actividad.icono }}</mat-icon>
                        <mat-label style="padding: 0 5px;">
                          {{item.actividad.nombre}}
                        </mat-label>
                      </div>
                    </div>

                    <div class="box-container" *ngFor="let e of item.reservaDias">
                      <div cdkDropList [cdkDropListData]="e" class="box" (cdkDropListDropped)="drop($event)"
                        (cdkDropListEntered)="moviendo($event, item.actividad, e.dia)"
                        cdkDropListOrientation="horizontal">
                        <div class="item"
                          *ngFor="let reserva of (e.reservas.length > 1 && masReservas.checked === true ? e.reservas : e.reservas.slice(0, 1))"
                          cdkDrag (click)="verDetalle(item.actividad, reserva)"
                          (cdkDragDropped)="soltarElemento($event, reserva, item.actividad)"
                          [ngClass]="{'item-codigo': form.controls.visual.value === 'codigo',
                          'item-cantidad-persona': form.controls.visual.value === 'cantidadPersonas', 'item-pendiente': reserva.estado === 'PENDIENTE', 'item-confirmado': reserva.estado === 'CONFIRMADO', 'item-cancelado': reserva.estado === 'CANCELADO', 'item-rechazado': reserva.estado === 'RECHAZADO'}">
                          {{reserva[form.controls.visual.value]}}
                        </div>
                        <mat-checkbox #masReservas class="chk-show-more" [checked]="false">
                          <div *ngIf="e.reservas.length > 1">
                            <div *ngIf="masReservas.checked">
                              VER MENOS <i class="fas fa-fw fa-minus"></i>
                            </div>
                            <div *ngIf="!masReservas.checked">
                              VER MÁS ({{ e.reservas.length - 1 }}) <i class="fas fa-fw fa-plus"></i>
                            </div>
                          </div>
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div *ngIf="data.length === 0">
                <div class="result-text">{{ searchText }}</div>
              </div>

            </div>
          </mat-drawer-content>
        </mat-drawer-container>
      </div>

    </div>
  </div>
</div>