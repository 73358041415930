<div class="logo">
    <a class="simple-text">
        <div class="logo-img">
            <img src="../../../assets/images/los-lagos-sombra.png" />
        </div>
        
    </a>
</div>
<!-- <div class="logo info-style hidden-ld hidden-md">
    <a class="simple-text">
        {{currentUser.nombre}} {{currentUser.apellido}}
    </a>
</div> -->
<div class="sidebar-wrapper">
    <div *ngIf="isMobileMenu()">
        <!-- <form class="navbar-form">
      <span class="bmd-form-group">
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Buscar...">
          <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
            <i class="material-icons">search</i>
            <div class="ripple-container"></div>
          </button>
        </div>
      </span>
    </form> -->
        <ul class="nav navbar-nav nav-mobile-menu">
            <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)"> 
                    <button mat-menu-item (click)="sessionService.logout()">
                        <mat-icon>exit_to_app</mat-icon>
                        <span>SALIR</span>
                    </button>  
                </a>
                <a  mat-menu-item class="modal" (click)="getNotifications()">
                    <i class="material-icons">notifications</i>
                    <span class="notification">{{ cantidad }}</span>
                    
                    <!-- <span>NOTIFICACIONES</span> -->

                </a>
            </li>
                    <!-- <mat-icon>exit_to_app</mat-icon> -->
            <!-- <li class="nav-item">
                <a class="nav-link" (click)="goToChangePass()" href="javascript:void(0)">
                    <i class="material-icons">dialpad</i>
                    <p>
                        <span class="d-lg-none d-md-block">Cambiar Contraseña</span>
                    </p>
                </a>
            </li>-->
            <!-- <li class="nav-item dropdown">
            <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="material-icons">notifications</i>
                <span class="notification">5</span>
                <p>
                    <span class="d-lg-none d-md-block">Some Actions</span>
                </p>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" href="#">Mike John responded to your email</a>
                <a class="dropdown-item" href="#">You have 5 new tasks</a>
                <a class="dropdown-item" href="#">You're now friend with Andrew</a>
                <a class="dropdown-item" href="#">Another Notification</a>
                <a class="dropdown-item" href="#">Another One</a>
            </div>
        </li> -->
        <!--     <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)">
                    <i class="material-icons">person</i>
                    <p>
                        <span class="d-lg-none d-md-block">Sonia Laviosa</span>
                    </p>
                </a>
            </li>-->
        </ul>
    </div>
    <ul class="nav" *ngFor="let m of menuList">
    <li class="line dk"></li>
    <li class="menu-style hidden-folded padder m-t m-b-sm text-muted text-xs">
      <span>{{m.label}}</span>
    </li>
    <li class="line dk"></li>
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let sm of m.sub_menus" class="nav-item">
            <a class="nav-link" [routerLink]="[m.end_point + sm.end_point]">
                <i class="material-icons">{{sm.icon}}</i>
                <p>{{sm.label}}</p>
            </a>
        </li>
    </ul>
  </ul>
    <!-- <ul class="nav">
        <li routerLinkActive="active" *ngFor="let m of menuList" class="nav-item">
            <a class="nav-link" [routerLink]="[m.end_point]">
                <i class="material-icons">{{m.icon}}</i>
                <p>{{m.label}}</p>
            </a>
        </li>
    </ul> -->
    <!-- <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
            <a class="nav-link" [routerLink]="[menuItem.end_point]">
                <i class="material-icons">{{menuItem.icon}}</i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
    </ul> -->
</div>