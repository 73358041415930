import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

    title: string;
    msg: string;
    cancelBtnMsg: string;
    acceptBtnMsg: string;
    confirm: string = "confirm";
    add: string = "add";
    close: string = "close";

    constructor(
        public dialogRef: MatDialogRef<ModalComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData
    ) {
        this.setValues(this.dialogData);
    }


    /**
     * Inicia el modal con valores rebidos como parametros o en caso
     * contrario, muestra los valores por defecto
     * @param data datos recibidos como parametros
     */
    setValues(data = null) {

        const titleDefault = 'Atención';
        const msgDefault = '¿Confirma que desea realizar la operación?';
        const acceptDefault = 'ACEPTAR';
        const cancelDefault = 'CANCELAR';

        if (!data) {
            this.title = titleDefault;
            this.msg = msgDefault;
            this.acceptBtnMsg = acceptDefault;
            this.cancelBtnMsg = cancelDefault;
            return;
        }

        this.title = data.title ? data.title : titleDefault;
        this.msg = data.msg ? data.msg : msgDefault;
        this.acceptBtnMsg = data.acceptBtnMsg ? data.acceptBtnMsg : acceptDefault;
        this.cancelBtnMsg = data.cancelBtnMsg ? data.cancelBtnMsg : cancelDefault;

    }
}
