import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable(
    {providedIn: 'root'}
)
export class SnackbarService {

    constructor(
        private snackbar: MatSnackBar
    ) {}

    showMessage(message: string, action: string = 'CERRAR', duration: number = 20000): void {
        this.snackbar.open(message, action, { duration });
    }

    noMessage(): void {
        this.snackbar.dismiss();
    }

}
