import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { SessionService } from '../interceptor/session.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public form: FormGroup;
  hidePass = false;
  constructor(private router: Router,
    public formBuilder: FormBuilder,
    public authenticationService: AuthenticationService,
    private sessionService: SessionService,
  ) { 
    localStorage.removeItem('session');
  }

  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Método para validación de formulario de login
   */
  initForm(): void {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  /**
  * Función para el inicio de sesión del usuario, con usuario y contraseña
  */
  login() {
    if (this.form.invalid) {
      return;
    }
    //llama al service para iniciar sesion.       
    this.authenticationService.login(this.form.getRawValue())
      .subscribe((response: any) => {
        // login exitoso.
        //console.log(response);
        this.sessionService.setSession(response);
        this.router.navigate(['planilla/reserva']);
      });
  }
}
