<div >
  <mat-toolbar class="dialog-toolbar" color="primary" fxLayout="row" fxLayoutAlign="space-between center">
      <div>{{data.title}}</div>
      <div>
          <button mat-icon-button (click)="close('close')">
              <mat-icon>close</mat-icon>
          </button>
      </div>
  </mat-toolbar>

  <br>

  <div class="prueba" mat-dialog-content [formGroup]="form" fxLayout="column"  fxLayoutGap="20px" fxLayout.sm="column">
  <!-- <div class="fecha-filtro" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column"fxLayout.sm="column">   -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px" fxLayout.xs="column"fxLayout.sm="column">
          <mat-form-field appearance="outline">
              <mat-label>Nombre</mat-label>
              <input matInput formControlName="nombre">
          </mat-form-field>

          <mat-form-field appearance="outline">
              <mat-label>Apellido</mat-label>
              <input matInput formControlName="apellido">
          </mat-form-field>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px" fxLayout.xs="column"fxLayout.sm="column">
          <mat-form-field appearance="outline">
              <mat-label>N° Cédula</mat-label>
              <input matInput formControlName="username">
          </mat-form-field>

          <mat-form-field appearance="outline">
              <mat-label>Rol</mat-label>
              <mat-select formControlName="usuarioRolId">
                <mat-option *ngFor="let l of data.data" [value]="l.id">{{l.nombre}}</mat-option>
            </mat-select>
          </mat-form-field>
      </div>
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" fxLayout.xs="column"fxLayout.sm="column">
      <mat-form-field appearance="outline">
          <mat-label>Correo electrónico</mat-label>
          <input matInput formControlName="email">
      </mat-form-field>
    </div>
  </div>
</div>

<br>

<div class="btn-action-container">

  <button class="btn btn-cancel" (click)="close('close')">
      <mat-icon>close</mat-icon>
      Cerrar
  </button>
  <button class="btn btn-general" (click)="saveUser()" [disabled]="form.invalid">
      <mat-icon>save</mat-icon>
      Guardar
  </button>
</div>
