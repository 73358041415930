<div class="main-content login-page mt-0 p-0">
  <div class="row login-box">
    <app-loader></app-loader>
    <div class="row image-container-style">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <img src="../../assets/images/los-lagos-sombra.png">
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-4 plr-100">
      <mat-card class="example-card p30">
        <mat-card-header>
          <mat-card-title class="card-title">OLVIDÉ MI CLAVE </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="form" class="example-form">
            <table class="example-full-width" cellspacing="0">
              <tr>
                <td>
                  <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Correo Electrónico</mat-label>
                    <input class="upper-case" matInput placeholder="Ingrese su correo electrónico" type="email"
                      formControlName="email" name="email" required>
                    <mat-icon matSuffix>contact_mail</mat-icon>
                  </mat-form-field>
                  <div>
                    <small class="text-danger"
                      *ngIf="form.controls.email.touched && form.controls.email.errors?.required">El correo electrónico
                      es requerido</small>
                  </div>
                </td>
              </tr>
            </table>
          </form>
        </mat-card-content>
        <mat-card-actions>
          <div class="button-container">
            <button class="btn btn-general" (click)="reset()" [disabled]="form.invalid">ENVIAR <mat-icon>
                keyboard_arrow_right</mat-icon></button>
            <button class="btn" (click)="cancel()">CANCELAR <mat-icon>
                keyboard_arrow_right</mat-icon></button>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>