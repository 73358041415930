<div class="image-container set-full-height">
  <div class="container">
    <div class="row">
      <app-loader></app-loader>
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="wizard-container">
          <wi-form class="card wizard-card" data-color="primary" title="Servicio de Reservas"
            subTitle="Una opción Única" [formGroup]="reserveForm" (submit)="onSubmit($event)"
            (onStepChanged)="onStepChanged($event)"
            style="display: inline-block;">
            <wi-step title="Fecha de Reserva" formGroupName="fechaReserva" class="tab-content">
              <!-- <h4 class="info-text"> Seleccione la fecha que desea reservar</h4>-->
              <div class="row">
                <div class="col-md-12">
                  <div class="row text-center">
                    <div class="col-md-12">
                      <h3 class="date-title">{{ viewDate | calendarDate:(view + 'ViewTitle'):'es' }}</h3>
                      <small class="date-subtitle">{{reserveForm.controls['fechaReserva'].value.fechaReserva | date:
                        "dd/MM/yyyy" }}</small>
                    </div>
                  </div>
                  <div class="row text-center">
                    <div class="col-md-12">
                      <div class="btn-group">
                        <div class="btn btn-general" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate">
                          ANTERIOR
                        </div>
                        <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
                          HOY
                        </div>
                        <div class="btn btn-general" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">
                          SIGUIENTE
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div [ngSwitch]="view">
                    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [locale]="locale"
                      [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
                      (beforeViewRender)="beforeMonthViewRender($event)">
                    </mwl-calendar-month-view>
                  </div>
                </div>
              </div>
            </wi-step>
            <wi-step title="Tipo de Reserva" formGroupName="type" class="tab-content">
              <h4 class="info-text" *ngIf="data.success === false">{{data.message}}</h4>
              <div class="row">
                <wi-radio-icon-list class="form-group w-full" name="type" [data]="reservaTypeOptions"
                  formControlName="type" [ngClass]="displayBootstrapClass('type.type')"
                  [additional]="reserveActiveList"
                  [attr.disabled]="reserveForm.controls['type'].value.type!==''">
                </wi-radio-icon-list>
              </div>
            </wi-step>
            <wi-step title="Cantidad de Persona" formGroupName="reserveCant" class="tab-content" (onNext)="getInfo()">
              <!-- <h4 class="info-text">Cantidad Disponible: {{infoData.cantidadDisponible}}</h4> -->
              <div class="row box-r">
                <div class="col-sm-4 col-responsive">
                  <div class="choice">
                    <div class="icon">
                      <i class="material-icons">today</i>
                    </div>
                    <h6>{{reserveForm.controls['fechaReserva'].value.fechaReserva | date: "dd/MM/yyyy" }}</h6>
                  </div>
                </div>
                <div class="col-sm-4 col-responsive" *ngIf="reserveForm.controls['type'].value.type !== ''">
                  <div class="choice active">
                    <div class="icon">
                      <i class="material-icons">{{type.icono}}</i>
                    </div>
                    <h6>{{type.nombre}}</h6>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="col-sm-12" *ngIf="infoData.tipo == 'listMulti'">
                    <mat-form-field appearance="outline">
                      <mat-label>Horario Reserva</mat-label>
                      <mat-select formControlName="horarioReserva" #mySelect multiple>
                        <mat-option *ngFor="let l of infoData.valor" [value]="l.valor"
                        [disabled]="reserveForm.controls['reserveCant'].value.horarioReserva.length > 2 && !reserveForm.controls['reserveCant'].value.horarioReserva.includes(l.valor)">{{l.valor}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12" *ngIf="infoData.tipo == 'time'">
                    <mat-form-field appearance="outline"
                      [ngClass]="displayBootstrapClass('reserveCant.horarioReserva')">
                      <mat-label>Horario Reserva</mat-label>
                      <mat-select formControlName="horarioReserva" #myHoursSelect >
                        <mat-option *ngFor="let l of infoData.valor" [value]="l.valor">{{l.valor}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12 text-center dialog-btn-container pb-15"
                    *ngIf="(infoData.tipo == 'time' || infoData.tipo == 'listMulti') && (reserveForm.controls['reserveCant'].value.horarioReserva !== '' && reserveForm.controls['reserveCant'].value.horarioReserva.length > 0)">
                    <button class="btn btn-general" (click)="validation()">
                      VALIDAR DISPONIBILIDAD
                    </button>
                  </div>
                  <div class="col-sm-12" *ngIf="infoData.tipo == 'normal' || available.cantidadDisponible > 0">

                    <mat-form-field appearance="outline"
                      [ngClass]="displayBootstrapClass('reserveCant.cantidadReserva')">
                      <mat-label>Cantidad de Personas</mat-label>
                      <input matInput [min]="infoData.mostrarMinimo ? infoData.cantidadMinima : 1"
                        [max]="available.cantidadDisponible ? available.cantidadDisponible : infoData.cantidadDisponible"
                        type="number" formControlName="cantidadReserva"
                        (keypress)="validationValue($event)" (blur)="minValidation($event)">
                    </mat-form-field>
                    <small *ngIf="infoData.mostrarMinimo" class="text-danger">Cantidad mínima: {{infoData.cantidadMinima}} personas</small>
                    <small *ngIf="infoData.mostrarMaximo" class="text-danger">Cantidad máxima de personas disponible: {{available.cantidadDisponible ?
                      available.cantidadDisponible : infoData.cantidadDisponible }}</small>
                  </div>
                </div>
              </div>
            </wi-step>
            <wi-step title="Formulario de Reserva" formGroupName="form" class="tab-content">
              <div class="row">
                <div class="col-sm-12">
                  <div class="row" [formGroup]="reserveForm.controls['form']">
                    <div class="col-sm-4">
                      <mat-form-field appearance="outline" [ngClass]="displayBootstrapClass('form.cedula')">
                        <mat-label>Número de Cédula</mat-label>
                        <input matInput min="6" type="number" formControlName="cedula" (blur)="searchClient()">
                      </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                      <mat-form-field appearance="outline" [ngClass]="displayBootstrapClass('form.nombre')">
                        <mat-label>Nombre</mat-label>
                        <input matInput type="text" formControlName="nombre">
                      </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                      <mat-form-field appearance="outline" [ngClass]="displayBootstrapClass('form.apellido')">
                        <mat-label>Apellido</mat-label>
                        <input matInput type="text" formControlName="apellido">
                      </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                      <mat-form-field appearance="outline" [ngClass]="displayBootstrapClass('form.celular')">
                        <mat-label>Número de Celular</mat-label>
                        <input matInput type="text" formControlName="celular">
                      </mat-form-field>
                    </div>
                    <div class="col-sm-8">
                      <mat-form-field appearance="outline" [ngClass]="displayBootstrapClass('form.email')">
                        <mat-label>Correo Electrónico</mat-label>
                        <input matInput type="email" formControlName="email">
                      </mat-form-field>
                    </div>

                    
                      <div *ngIf="pasarDia !== null" class="col-sm-12 ">
                         <b> <span class="textoObservacion"> IMPORTANTE:</span> Recuerde que al reservar para pasar el día no incluye  parrillas fijas ni reservas para el restaurant. Para ello debe reservar esas actividades</b><br><br>
                      </div>
                    

                    <div class="col-sm-12">
                      <mat-form-field appearance="outline" [ngClass]="displayBootstrapClass('form.observacion')">
                        <mat-label>Observación</mat-label>
                        <textarea matInput formControlName="observacion"></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4 col-responsive">
                      <div class="choice">
                        <div class="icon">
                          <i class="material-icons">today</i>
                        </div>
                        <h6>{{reserveForm.controls['fechaReserva'].value.fechaReserva | date: "dd/MM/yyyy" }}</h6>
                      </div>
                    </div>
                    <div class="col-sm-4 col-responsive" *ngIf="reserveForm.controls['type'].value.type !== ''">
                      <div class="choice active">
                        <div class="icon">
                          <i class="material-icons">{{type.icono}}</i>
                        </div>
                        <h6>{{type.nombre}}</h6>
                      </div>
                    </div>
                    <div class="col-sm-4 col-responsive" *ngIf="reserveForm.controls['reserveCant'].value.cantidadReserva !== ''">
                      <div class="choice">
                        <div class="icon">
                          <h6 class="cant-style material-icons">{{reserveForm.controls['reserveCant'].value.cantidadReserva}}</h6>
                        </div>
                        <h6>Reservado</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </wi-step>
          </wi-form>
        </div>
      </div>
    </div>
  </div>
</div>