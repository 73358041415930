import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReserveComponent } from './reserve/reserve.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'reserva',
    pathMatch: 'full',
  }, {
    path: 'reserva',
    component: ReserveComponent,
  },
  {
    path: 'planilla',
    loadChildren: () => import('./drag-drop-reserve/drag-drop-reserve.module').then( m => m.DragDropReserveModule)
  },
  {
    path: 'planilla',
    loadChildren: () => import('./disponibilidad-bloque/disponibilidad-bloque-actividad.module').then( m => m.DisponibilidadBloqueActividadModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./graficos/grafico.module').then( m => m.GraficoModule)
  },
  {
    path: 'restaurante',
    loadChildren: () => import('./restaurant/restaurant.module').then( m => m.RestaurantModule)
  },
  {
    path: 'resportes',
    loadChildren: () => import('./report/report.module').then( m => m.ReportModule)
  },
  {
    path: 'configuracion',
    loadChildren: () => import('./user/user.module').then( m => m.UserModule)
  },
  {
    path: 'configuracion',
    loadChildren: () => import('./activity/activity.module').then( m => m.ActivityModule)
  },
  {
    path: 'configuracion',
    loadChildren: () => import('./client/client.module').then( m => m.ClientModule)
  },
  {
    path: 'configuracion',
    loadChildren: () => import('./rol/rol.module').then( m => m.RolModule)
  },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
