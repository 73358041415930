<div class="">
    <mat-toolbar class="dialog-toolbar" color="primary" fxLayout="row" fxLayoutAlign="space-between center">
        <div>{{data.title}}</div>
        <div>
            <button mat-icon-button (click)="close('close')">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>

    <br>

    <div mat-dialog-content [formGroup]="form" fxLayout="column" fxLayoutGap="20px">

        <div class="form-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px">
            <mat-form-field appearance="outline">
                <mat-label>Horario</mat-label>
                <input matInput type="time" formControlName="horario">
            </mat-form-field>
        </div>
        <div class="form-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px">
            <mat-form-field appearance="outline">
                <mat-label>Turno</mat-label>
                <mat-select formControlName="actividadHorarioTurnoId">
                    <mat-option *ngFor="let l of turnoList" [value]="l.id">
                        {{l.horario}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="form-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px">
            <mat-form-field appearance="outline">
                <mat-label>Secuencia</mat-label>
                <input matInput type="number" formControlName="secuencia">
            </mat-form-field>
        </div>
    </div>
</div>

<br>

<div class="btn-action-container">

    <button class="btn btn-cancel" (click)="close('close')">
        <mat-icon>close</mat-icon>
        Cerrar
    </button>
    <button class="btn btn-general" (click)="save()" [disabled]="form.invalid">
        <mat-icon>save</mat-icon>
        Guardar
    </button>
</div>