<footer class="footer ">
    <div class="container-fluid">
        <div class="copyright">
            Copyright
            &copy;
            {{test | date: 'yyyy'}}
            <a>RASP S.A.</a>
        </div>
    </div>
</footer>
