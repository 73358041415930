//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';
import { MaterialModule } from '../core/material.module';
import { DirectivesModule } from '../directives/directives.module';
import { changePasswordRouting } from './change-password.routing';
import { ChangePasswordFormComponent } from './change-password-form.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        changePasswordRouting,
        ComponentsModule,
        DirectivesModule
    ],
    declarations: [
        ChangePasswordFormComponent
    ],
    providers: [
        
    ],
    exports: [
        ChangePasswordFormComponent
    ]
})

export class ChangePasswordModule { }