import { ThrowStmt } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GraficosService } from 'src/app/services/graficos.service';
@Component({
  selector: 'app-disponibilidad-grafico',
  templateUrl: './disponibilidad-grafico.component.html',
  styleUrls: ['./disponibilidad-grafico.component.scss']
})
export class DisponibilidadGraficoComponent implements OnInit {

  constructor(public graficosService: GraficosService,private fb: FormBuilder) {  
  }

  form: FormGroup;
  model: any;
  @Input() actividad: string;
  @Input() titulo: string;
  bandera: boolean;
  // opciones de grafico de pie
  vista: any[] = [550, 400];
  gradiante: boolean = true;
  mostrarLegend: boolean = true;
  mostrarEtiqueta: boolean = true;
  esDona: boolean = true;
  posicionDescripcion: string = 'below';
  animaciones: boolean = true;
  deshabilitarTooltip: boolean = false;
  showLegend: boolean = true;
  datos: [];
  maxLabelLength: number = 25;
  colorScheme = {
    domain: ['#0390be', '#ffcc00', '#80c31b', '#124b45']
  };

  //metodo para traer los datos para el grafico de pie
  getDatosPie(fechaInput){
    const date = new Date(fechaInput)
    const body = {
      actividad: this.actividad,
      fecha: date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
    }
    this.graficosService.getDisponibilidad(body).subscribe(
      data =>{
        this.datos = data;
        if(data[0].value === 0 && data[1].value === 0){
          this.bandera = true;
        }else{
          this.bandera = false;
        };  
      })
    }

  ngOnInit(): void {
    this.getDatosPie(new Date());
    }

}
