import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import interact from 'interactjs';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ModalComponent } from '../components/modal/modal.component';
import { MatDrawer } from '@angular/material/sidenav';
import { RestaurantService } from '../services/restaurant.service';
import { CdkDragDrop, copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AppComponent } from '../app.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SnackbarService } from '../interceptor/snackbar.service';

@Component({
  selector: 'app-reserve-assignment',
  templateUrl: './reserve-assignment.component.html',
  styleUrls: ['./restaurant.component.scss']
})
export class RestaurantAssignmentComponent implements OnInit {

  mesas: any = [];
  seccion: any = [];
  reserveList: any = [];
  title: any;
  titleText: any;

  selectTable = null;
  panelOpenState = false;
  forzarAsignacion: boolean = false;

  // we create an object that contains coordinates 
  menuTopLeftPosition = { x: '0', y: '0' }

  // reference to the MatMenuTrigger in the DOM 
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  currentDate: any;

  // drawer
  @ViewChild(MatDrawer) matDrawer: MatDrawer;


  currentZone: any;
  dragAndDrop: boolean = false;

  form: FormGroup;
  formReserva: FormGroup;

  horarioList: any[];
  filter: any = {};
  defaultHour: any = '';
  action: any;

  constructor(private router: Router,
    private datepipe: DatePipe,
    private fb: FormBuilder,
    public appComponent: AppComponent,
    private snackbarService: SnackbarService,
    private restaurantService: RestaurantService,
    private dialog: MatDialog) {
    this.currentDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy hh:mm:ss')
    this.buildForm();
  }

  ngOnInit(): void {
    this.appComponent.showMenu = false;
    this.getZoneTypeList();
    var a = this;
    interact('.resize-drag')
      .resizable({
        // resize from all edges and corners
        edges: { left: true, right: true, bottom: true, top: true },

        listeners: {
          move(event) {
            a.dragAndDrop = true;
            var target = event.target
            var x = (parseFloat(target.getAttribute('data-x')) || 0)
            var y = (parseFloat(target.getAttribute('data-y')) || 0)

            // update the element's style
            target.style.width = event.rect.width + 'px'
            target.style.height = event.rect.height + 'px'

            // translate when resizing from top or left edges
            x += event.deltaRect.left
            y += event.deltaRect.top

            target.style.webkitTransform = target.style.transform =
              'translate(' + x + 'px,' + y + 'px)'

            target.setAttribute('data-x', x)
            target.setAttribute('data-y', y)
            //target.textContent = Math.round(event.rect.width) + '\u00D7' + Math.round(event.rect.height)
          }
        },
        modifiers: [
          // keep the edges inside the parent
          interact.modifiers.restrictEdges({
            outer: 'parent'
          }),

          // minimum size
          interact.modifiers.restrictSize({
            min: { width: 50, height: 50 }
          })
        ],

        inertia: true
      })
      .draggable({
        listeners: { move: dragMoveListener },
        inertia: true,
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true
          })
        ]
      })
    function dragMoveListener(event) {
      a.dragAndDrop = true;
      var target = event.target,
        // keep the dragged position in the data-x/data-y attributes
        x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
        y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

      // translate the element
      target.style.webkitTransform =
        target.style.transform =
        'translate(' + x + 'px, ' + y + 'px)';

      // update the posiion attributes
      target.setAttribute('data-x', x);
      target.setAttribute('data-y', y);
    }
  }

  buildForm() {
    this.form = this.fb.group({
      fecha: new Date(),
      horario: this.defaultHour
    });
    this.formReserva = this.fb.group({
      nombre: '',
      horario: ''
    });
    //console.log(this.form.value)

  }

  drop(event: CdkDragDrop<any[]>) {
    //console.log(event)


    this.reserveList[event.previousIndex].asociado = true;
    this.mesas[event.currentIndex].cliente = event.previousContainer.data[event.previousIndex].cliente
    this.mesas.forEach(element => {
      var target = document.getElementById(element.id.toString());
      // keep the dragged position in the data-x/data-y attributes
      var x = element.posicionX;
      var y = element.posicionY;

      // translate the element
      target.style.webkitTransform =
        target.style.transform =
        'translate(' + x + 'px, ' + y + 'px)';

    })
  }
  /**
   * Método para actualizar atributo html de un registro dibujado en pantalla
   * @param element elemento DOM cuyo atributo data-x/data-y será actualizado
   * @param data el registro renderizado
   */
  updateElement(element, data) {
    //console.log(element)
    var target = element,
      // keep the dragged position in the data-x/data-y attributes
      x = (parseFloat(target.getAttribute('data-x')) || 0) + data.posicionX,
      y = (parseFloat(target.getAttribute('data-y')) || 0) + data.posicionY;

    // update the element's style
    target.style.width = data.ancho + 'px'
    target.style.height = data.alto + 'px'
    // translate the element
    target.style.webkitTransform =
      target.style.transform =
      'translate(' + x + 'px, ' + y + 'px)';

    // update the posiion attributes
    target.setAttribute('data-x', x);
    target.setAttribute('data-y', y);
  }

  /**
   * Método para mostrar el detalle de una mesa seleccionada
   * @param item mesa seleccionada a ser mostrada
   */
  detail(item: any) {
    //console.log(this.dragAndDrop)
    this.selectTable = item;
    if (!this.dragAndDrop && (!this.matDrawer.opened || this.selectTable.id === item.id)) {
      this.matDrawer.toggle();
    }
    this.dragAndDrop = false;
  }


  /**
   * Método para obtener las mesas según la zona seleccionada
   * @param section zona de restaurante
   */
  getSection(section: any) {
    this.title = section.nombre;
    this.currentZone = section.id;
    this.getTableForZone(this.currentZone);
    this.getRestaurantReserve(1);
  }

  /**
   * Método para obtener la lista de zonas correspondientes al restaurante
   */
  getZoneTypeList() {
    this.restaurantService.getZoneType()
      .subscribe(
        data => {
          this.seccion = data;
          this.title = this.seccion[0].nombre;
          this.currentZone = this.seccion[0].id;
          this.getHorario();
        });
  }

  /**
   * Método para obtener la lista de mesas de una zona específica
   * @param id: identificador de la zona
   */
  getTableForZone(id: any) {
    let body = this.form.value;
    body.fecha = this.datepipe.transform(this.form.value.fecha, 'yyyy-MM-dd');
    body.mesaZonaId = id;
    this.restaurantService.getRestaurantTable(body)
      .subscribe(
        data => {
          this.mesas = data;
        });
  }

  /**
   * Método ejecutado al seleccionar una fecha, para habilitar el selector de actividades
   * @param event el valor seleccionado
   */
  dateChange(event: MatDatepickerInputEvent<Date>) {
    this.getRestaurantReserve(1);
    this.getTableForZone(this.currentZone);
  }

  selectedTable(r: any, event: any) {
    //console.log(r);
    //console.log(event);
    let body = { mesaId: event, forzarReserva: this.forzarAsignacion };
    this.restaurantService.updateReserveOfTable(r.id, body)
      .subscribe(
        data => {
          if (data && data.consultarForzar) {
            this.forzarAsignacion = true;
            this.forzaeReserva(r, event, data.message);
          } else {
            this.forzarAsignacion = false;
            this.getRestaurantReserve(1);
            this.getTableForZone(this.currentZone);
          }
        },
        error => {
          //console.log(error)
          this.forzarAsignacion = false;
          this.snackbarService.showMessage(error.error.message, 'CERRAR', 2500);
        });
  }
  /**
   * Método para obtener la lista de horarios para el restaurante
   */
  getHorario() {
    this.restaurantService.getHorario()
      .subscribe(
        data => {
          this.horarioList = data;
          // let hora = this.datepipe.transform(this.currentDate, 'hh');
          // let min = this.datepipe.transform(this.currentDate, 'mm');
          // if (parseInt(min) > 0 && parseInt(min) <= 30) {
          //   this.form.value.horario = `${hora}:30:00`
          // } else {
          //   hora = (parseInt(hora) + 1).toString();
          //   this.defaultHour = `${hora}:00:00`
          // }
          this.form.controls.horario.setValue(this.horarioList[0].horario);
          this.getRestaurantReserve(1);
          this.getTableForZone(this.currentZone);
        });
  }

  /**
   * Método para obtener la lista de reservas correspondientes al restaurante
   */
  getRestaurantReserve(page: any) {

    this.filter = this.formReserva.value;
    this.filter.pagina = page;
    this.filter.mesaZonaId = this.currentZone;
    this.filter.fecha = this.datepipe.transform(this.form.value.fecha, 'yyyy-MM-dd');
    //console.log(this.filter)
    this.restaurantService.getRestaurantReserve(this.filter)
      .subscribe(
        data => {
          if (data != null) {
            this.reserveList = data['data'] as any[];
            //this.pagination = data['pagination'];
          } else {
            //this.pagination = new Page();
          }
        });
  }

  selectedHour(event: any) {
    this.getTableForZone(this.currentZone);
  }

  updateReserve(item: any) {
    const dialogConfig: MatDialogConfig = {
      data: {
        title: `Actualizar Mesa: ${item.mesa}`,
        msg: `
        <br>¿Qué desea hacer, <b>Finalizar Reserva</b> o <b>Liberar Mesa</b>?`,
        acceptBtnMsg: 'LIBERAR MESA',
        cancelBtnMsg: 'FINALIZAR RESERVA'
      }
    };

    this.dialog.open(ModalComponent, dialogConfig).afterClosed().subscribe(resp => {
      if (resp) {
        if (resp == 'add') {
          this.action = 'FINALIZADA';
        } else if (resp == 'confirm') {
          this.action = 'DISPONIBLE';
        }
        if (resp == 'add' || resp == 'confirm') {
          this.restaurantService.updateAsignacion({ estado: this.action }, item.reservaId)
            .subscribe(
              data => {
                this.getRestaurantReserve(1);
                this.getTableForZone(this.currentZone);
                this.snackbarService.showMessage('Actualización Exitosa ✔✔', 'CERRAR');
              });
        }
      }
    });
  }

  /**
   * Método para bloquear o desbloquear una mesa
   * @param item registro de mesa
   */
  bloquearMesa(item: any) {
    let title = 'Bloquear';
    if (item.estado == 'Bloqueada') {
      title = 'Desbloquear';
    }
    const dialogConfig: MatDialogConfig = {
      data: {
        title: `${title} Mesa: ${item.mesa}`,
        msg: `
        <br>¿Está seguro que desea ${title.toLocaleLowerCase()} la mesa?`,
        acceptBtnMsg: 'ACEPTAR',
        cancelBtnMsg: 'CANCELAR'
      }
    };
    let body = this.form.value;
    body.mesaId = item.id;

    this.dialog.open(ModalComponent, dialogConfig).afterClosed().subscribe(resp => {
      if (resp == 'confirm') {
        if (item.estado == 'Bloqueada') {
          body.estado = 'DESBLOQUEADA'
          this.restaurantService.updateAsignacion(body, item.reservaId)
            .subscribe(
              data => {
                this.init();
              });
        } else {
          this.restaurantService.bloquearMesa(body)
            .subscribe(
              data => {
                this.init();
              });
        }
      }
    });
  }

  init() {
    this.getRestaurantReserve(1);
    this.getTableForZone(this.currentZone);
    this.snackbarService.showMessage('Actualización Exitosa ✔✔', 'CERRAR');
  }

  /**
   * Método para forzar la asignación de una reserva a una mesa
   * @param item registro de mesa
   */
  forzaeReserva(item: any, event: any, msg: string) {

    const dialogConfig: MatDialogConfig = {
      data: {
        title: `Forzar Reserva`,
        msg: `
        <br> ${msg}
        <br>¿Está seguro que desea forzar la reserva?`,
        acceptBtnMsg: 'ACEPTAR',
        cancelBtnMsg: 'CANCELAR'
      }
    };
    let body = this.form.value;
    body.mesaId = item.id;

    this.dialog.open(ModalComponent, dialogConfig).afterClosed().subscribe(resp => {
      if (resp == 'confirm') {
        this.selectedTable(item, event);
      }
    });
  }

}
