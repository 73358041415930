<div class="main-content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <app-loader></app-loader>
        <div class="row" [formGroup]="form">
          <div class="col-md-2">
            <mat-form-field appearance="outline" class="date-input">
              <mat-label>Fecha Desde</mat-label>
              <input formControlName="fechaDesde" matInput [matDatepicker]="pickerDesde">
              <mat-datepicker-toggle matSuffix [for]="pickerDesde"></mat-datepicker-toggle>
              <mat-datepicker #pickerDesde></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="outline" class="date-input">
              <mat-label>Fecha Hasta</mat-label>
              <input formControlName="fechaHasta" matInput [matDatepicker]="pickerHasta">
              <mat-datepicker-toggle matSuffix [for]="pickerHasta"></mat-datepicker-toggle>
              <mat-datepicker #pickerHasta></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline">
              <mat-label>Actividad</mat-label>
              <mat-select #tipoActividad formControlName="tipoActividad" 
                (selectionChange)="getReportType($event.value)">
                <mat-option *ngFor="let a of activityList" [value]="a.id">{{a.nombre}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="date-input">
              <mat-label>Tipo Reporte</mat-label>
              <mat-select formControlName="tipoReporte">
                <mat-option *ngFor="let t of reportTypeList" [value]="t.id">
                  {{t.nombre}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <button mat-button [matMenuTriggerFor]="menu" class="btn btn-general">
              <mat-icon>more_vert</mat-icon>
              Exportar
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [disabled]="form.invalid" (click)="getReportPDF()">
                <mat-icon>picture_as_pdf</mat-icon>
                PDF
              </button>
              <button mat-menu-item [disabled]="form.invalid" (click)="generate('XLS')">
                <mat-icon>border_all</mat-icon>
                Excel
              </button>
              <button mat-menu-item [disabled]="form.invalid" (click)="generate('CSV')">
                <mat-icon>receipt</mat-icon>
                CSV
              </button>
            </mat-menu>
          </div>  
          <div class="col-md-12" *ngIf="fileName && type === 'PDF'">
            <object class="box-report" [attr.data]="fileName" type="application/pdf"></object>
          </div>
          <div class="col-md-12" *ngIf="generado && type === 'CSV' || type === 'XLS'">
            <div class="csv-box">
              <div class="toolbar mat-elevation-z2" fxLayout="row" fxLayoutAlign="end center">
                <div fxLayout="row" fxLayoutAlign="center center">
                  <mat-icon class="download" (click)="downloadFile()">download</mat-icon>
                </div>
              </div>

              <div class="csv-container">
                <table cdk-table [dataSource]="dataSource">
                  
                  <ng-container [cdkColumnDef]="obj.key" *ngFor="let obj of header">
                    <th cdk-header-cell *cdkHeaderCellDef> {{ obj.value }} </th>
                    <td cdk-cell *cdkCellDef="let e"> {{e[obj.key]}} </td>
                  </ng-container>
                
                  <tr cdk-header-row *cdkHeaderRowDef="displayedColumns; sticky:true"></tr>
                  <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>

            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>