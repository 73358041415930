//angular
import { Routes, RouterModule } from '@angular/router';
import { ForgetPasswordComponent } from './forget-password.component';
import { LoginComponent } from './login.component';
//components


const appRoutes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'reseteo', component: ForgetPasswordComponent }
];

export const loginRouting = RouterModule.forChild(appRoutes);