import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../core/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { disponibilidadBloqueActividadRouting } from './disponibilidad-bloque-actividad.routing';
import { LoaderModule } from '../components/loader/loader.module';
import { BloqueFormComponent } from './bloque-form.component';
import { DisponibilidadBloqueActividadComponent } from './disponibilidad-bloque-actividad.component';
import { DesbloqueoFormComponent } from './desbloqueo-form.component';
import { DesbloqueoFechaFormComponent } from './desbloqueo-fecha-form.component';


@NgModule({
  declarations: [
    DisponibilidadBloqueActividadComponent,
    BloqueFormComponent,
    DesbloqueoFormComponent,
    DesbloqueoFechaFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    HttpClientModule,
    ClipboardModule,
    disponibilidadBloqueActividadRouting,
    LoaderModule,
  ],
  exports: [DisponibilidadBloqueActividadComponent]
})
export class DisponibilidadBloqueActividadModule { }
