
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { SessionService } from './session.service';
import { SettingsService } from './settings.service';
import { LoaderService } from '../services/loader.service';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(
    private sessionService: SessionService,
    private settingsService: SettingsService,
    private loaderService: LoaderService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.log('interceptor');
    let token: string = null;
    if (this.sessionService.existSession()) {
      token = this.sessionService.getAccessToken();
    }

    let request: HttpRequest<any> = req;
    let existResponse = false;

    // Si en 10 segundos no hay respuesta de error o exito se muestra mensaje de tiempo expirado
    setTimeout(() => {
      if (!existResponse) {
        //console.log('Tiempo de espera agotado');
        //this.loaderService.hideLoader();
      }
    }, this.settingsService.LONG_TIME);

    if (token) {
      request = req.clone({
        setHeaders: {
          authorization: `Bearer ${token}`
        }
      });
    }
    
    // se muestra el loader
    if (!this.loaderService.isLoading.value) {
      this.loaderService.showLoader();
    }

    return next.handle(request)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {

            existResponse = true;

            // creacion
            if (request.method === 'POST') {
              
            }

            // actualizacion
            if (request.method === 'PUT') {
              
            }

            // eliminacion
            if (request.method === 'DELETE') {
              
            }

          }
        }, (error: HttpErrorResponse) => {

          existResponse = true;
          this.errorHandlerService.detectError(error);

          

        }), finalize(() => {
          this.loaderService.hideLoader();
        })
      );
  }


}
