//angular
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';
import { MaterialModule } from '../core/material.module';
import { DirectivesModule } from '../directives/directives.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DisponibilidadGraficoComponent } from './disponibilidad-grafico/disponibilidad-grafico.component';
import { graficoRouting } from './grafico.routing';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GraficoComponent } from './grafico.component';
import { GraficoParticipantesComponent } from './grafico-participantes/grafico-participantes.component';
import { GraficoPersonasComponent } from './grafico-personas/grafico-personas.component';
import {MatTabsModule} from '@angular/material/tabs';
import { GraficoReservasComponent } from './grafico-reservas/grafico-reservas.component';



@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        graficoRouting,
        ComponentsModule,
        DirectivesModule,
        FlexLayoutModule,
        NgxChartsModule,
        MatTabsModule
    ],
    declarations: [

        DisponibilidadGraficoComponent,
        GraficoComponent,
        GraficoParticipantesComponent,
        GraficoPersonasComponent,
        GraficoReservasComponent
    ],
    providers: [
        
    ],
    exports: [

        DisponibilidadGraficoComponent
    ]
})

export class GraficoModule { }