<div class="">
    <mat-toolbar class="dialog-toolbar" color="primary" fxLayout="row" fxLayoutAlign="space-between center">
        <div>Desbloqueo</div>
        <div>
            <button mat-icon-button (click)="close('close')">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>

    <br>

    <div mat-dialog-content [formGroup]="form" fxLayout="column" fxLayoutGap="20px">

        <mat-form-field appearance="outline">
            <mat-label>Tipo</mat-label>
            <mat-select formControlName="accion">
                <mat-option value="BLOQUEO">Bloqueo de Fecha</mat-option>
                <mat-option value="DISPONIBILIDAD">Disponibilidad en Bloque</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Actividad</mat-label>
            <mat-select #tipoActividad formControlName="tipoActividad" multiple>
                <mat-option *ngFor="let l of data" [value]="l.id">{{l.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Fecha Desde</mat-label>
            <input matInput formControlName="fechaDesde" [matDatepicker]="pickerInicio">
            <mat-datepicker-toggle matSuffix [for]="pickerInicio"></mat-datepicker-toggle>
            <mat-datepicker #pickerInicio></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Fecha Hasta</mat-label>
            <input matInput formControlName="fechaHasta" [matDatepicker]="pickerFin">
            <mat-datepicker-toggle matSuffix [for]="pickerFin"></mat-datepicker-toggle>
            <mat-datepicker #pickerFin></mat-datepicker>
        </mat-form-field>
        <section>
            <mat-checkbox *ngFor="let d of days" color="primary" [checked]="d.check" (change)="setChange(d)">{{d.value}}
            </mat-checkbox>
        </section>
        
    </div>
</div>

<br>

<div class="btn-action-container">

    <button class="btn btn-cancel" (click)="close('close')">
        <mat-icon>close</mat-icon>
        Cerrar
    </button>
    <button class="btn btn-general" (click)="save()" [disabled]="form.invalid">
        <mat-icon>save</mat-icon>
        Guardar
    </button>
</div>