import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    // sesion de usuario
    private session: Session = null;

    constructor(private router: Router) {

    }

    /**
     * Almacena la sesion en memoria
     * @param data datos de sesion de usuario
     */
    setSession(data: Session) {
        this.session = data;
        localStorage.setItem('session', JSON.stringify(data));
    }

    existSession() {
        return localStorage.getItem('session') !== null;
    }
    /**
     * Retorna el token de acceso del usuario
     */
    public getAccessToken(): string {
        return localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')).token : null;
        // return this.session.token;
    }

    /**
     * Actualiza el token/sesion del usuario
     */
    public refreshToken(): void {
    }

    logout(path = 'login'): void {
        localStorage.clear();
        this.session = null;
        this.router.navigate([path]);
    }

}

export interface Session {
    token: string;
    refreshToken: string;
    usuario: string;
}