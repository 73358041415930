import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(public router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('session')) {
            // retorna true si el usuaio tiene token.
            return true;
        }
        // sino redirecciona a la pagina de inicio.
        this.router.navigate(['/login']);
        return false;
    }
}