import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './core/material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './interceptor/interceptor.service';
import { ReserveComponent } from './reserve/reserve.component';
import { WizardFormModule } from './wizardform/wizard-form.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from './components/components.module';
import { DatePipe, registerLocaleData } from '@angular/common';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'; // a plugin
import { MAT_DATE_LOCALE } from '@angular/material/core';
import localeEs from '@angular/common/locales/es';
import { LoginModule } from './login/login.module';
import { RestaurantModule } from './restaurant/restaurant.module';
import { DirectivesModule } from './directives/directives.module';
import { ClientModule } from './client/client.module';
import {MatChipsModule} from '@angular/material/chips';

registerLocaleData(localeEs);
import { DragDropReserveModule } from './drag-drop-reserve/drag-drop-reserve.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { LoaderModule } from './components/loader/loader.module';
import { AuthGuard } from './components/guards/auth.guard';
import { SnackbarService } from './interceptor/snackbar.service';
import { ReportModule } from './report/report.module';
import { UserModule } from './user/user.module';
import { ActivityModule } from './activity/activity.module';
import { DisponibilidadBloqueActividadModule } from './disponibilidad-bloque/disponibilidad-bloque-actividad.module';
import { ChangePasswordModule } from './change-password/change-password.module';
import { NotificationModule } from './notification/notification.module';
import { GraficoModule } from './graficos/grafico.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
  declarations: [
    AppComponent,
    ReserveComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    WizardFormModule,
    HttpClientModule,
    ComponentsModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    LoginModule,
    DragDropReserveModule,
    DirectivesModule,
    RestaurantModule,
    ClientModule,
    NgxMaterialTimepickerModule.setLocale('es-PY'),
    LoaderModule,
    ReportModule,
    UserModule,
    ActivityModule,
    DisponibilidadBloqueActividadModule,
    ChangePasswordModule,
    NotificationModule,
    MatChipsModule,
    GraficoModule,
    NgxChartsModule,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    AuthGuard,
    SnackbarService,
    DatePipe, { provide: MAT_DATE_LOCALE, useValue: 'es-PY' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
