import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SettingsService } from 'src/app/interceptor/settings.service';

@Injectable()
export class MenuService {

    constructor(public http: HttpClient,
        public route: ActivatedRoute,
        private settings: SettingsService,
        public router: Router) {
    }
    /**
     * Función para obtener los menús de usuario
     * @param userId identificador del usuario logueado 
     */
    getMenus(): Observable<any> {
        return this.http.get(`${this.settings.URL_BASE}usuario/menu`);
    }

    /**
     * Método para menejo de menú en celulares.
     */
    isMobileMenu() {
        return true;
    };

    /**
     * Función para obtener los menús con filtro y asignados o no a un rol específico
     * @param rolId identificador del rol 
     */
    getFilterMenus(rolId: any, filter: any): Observable<any> {
        return this.http.get(`${this.settings.URL_BASE}administrador/menu`);
    }
}