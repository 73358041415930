import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '../interceptor/settings.service';

@Injectable({
    providedIn: 'root'
})
export class GraficosService {

    constructor(
        private http: HttpClient,
        private settings: SettingsService
    ) { }

    /**
     * Recupera los datos de disponibilidad de una actividad
     */
     getDisponibilidad(body: any): Observable<any> {
        return this.http.post(`${this.settings.URL_BASE}dashboard/disponibilidad`,body);
    }

    //Recupera la cantidad de participantes de una actividad
    getParticipantes(body: any): Observable<any>{
        return this.http.post(`${this.settings.URL_BASE}dashboard/participantes/confirmados`,body);
    }

    //Recupera los datos de las personas por dia
    getPersonas(body: any): Observable<any>{
        return this.http.post(`${this.settings.URL_BASE}dashboard/personas/dia`,body);
    }

    //Recupera los datos de las reservas por dia
    getReservas(body: any): Observable<any>{
        return this.http.post(`${this.settings.URL_BASE}dashboard/reserva/dia`,body);
    }

    getActividad(): Observable<any>{
        return this.http.get(`${this.settings.URL_BASE}dashboard/actividad`);
    }
}
