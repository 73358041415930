<div class="main-content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <app-loader></app-loader>
        <div class="style-filter" [formGroup]="form">
          <div class="fecha-filtro" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="row"
           fxLayout.sm="row">
          <!-- <div class="col-md-4"> -->
            <mat-form-field appearance="outline" class="date-input">
              <mat-label>Nombre</mat-label>
              <input formControlName="nombre" matInput>
            </mat-form-field>
          <!-- </div> -->
          <!-- <div class="col-md-2"> -->
            <mat-form-field appearance="outline" class="date-input">
              <mat-label>Activo</mat-label>
              <mat-select formControlName="activo">
                <mat-option *ngFor="let l of stateList" [value]="l.value">
                  {{l.nombre}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          <!-- </div> -->

          <!-- <div class="col-md-4"> -->
            <button class="btn btn-general" (click)="getList(1)">
              <mat-icon>search</mat-icon>
              Buscar
            </button>
            <button class="btn btn-general" (click)="add()">
              <mat-icon>add</mat-icon>
              Agregar
            </button>
          <!-- </div> -->
        </div>
        </div>
        <div class="tab-content">
          <div class="contenedor" fxLayout="column" fxLayoutGap="20px">
            <div class="form-row table-container" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px">
              <table mat-table [dataSource]="dataSource" multiTemplateDataRows>

                <!-- nombre -->
                <ng-container matColumnDef="nombre">
                  <th mat-header-cell *matHeaderCellDef> Nombre </th>
                  <td mat-cell *matCellDef="let e"> {{e.nombre}} </td>
                </ng-container>

                <!-- visibilidad -->
                <ng-container matColumnDef="visibilidadExterior">
                  <th mat-header-cell *matHeaderCellDef> Visibilidad </th>
                  <td mat-cell *matCellDef="let e"> {{e.visibilidadExterior ? 'SÍ' : 'NO'}} </td>
                </ng-container>

                <!-- activo -->
                <ng-container matColumnDef="activo">
                  <th mat-header-cell *matHeaderCellDef> Activo </th>
                  <td mat-cell *matCellDef="let e"> {{e.activo ? 'SÍ' : 'NO'}} </td>
                </ng-container>

                <!-- tipo de actividad -->
                <ng-container matColumnDef="actividadTipo">
                  <th mat-header-cell *matHeaderCellDef> Tipo de Actividad </th>
                  <td mat-cell *matCellDef="let e"> {{e.actividadTipo}} </td>
                </ng-container>

                <!-- usuario alta -->
                <ng-container matColumnDef="usuarioAlta">
                  <th mat-header-cell *matHeaderCellDef> Usuario de Alta </th>
                  <td mat-cell *matCellDef="let e"> {{e.usuarioAlta}} </td>
                </ng-container>

                <!-- fecha de creación -->
                <ng-container matColumnDef="fechaCreacion">
                  <th mat-header-cell *matHeaderCellDef> Fecha de Creación </th>
                  <td mat-cell *matCellDef="let e"> {{e.fechaCreacion | date:'fullDate':'GMT-4':'ES-py'}} </td>
                </ng-container>

                <!-- Advertencia de correo --> 
                <div>
                  <ng-container matColumnDef="advertenciaCorreo">
                    <th mat-header-cell *matHeaderCellDef> Advertencia Correo </th>
                    <td mat-cell *matCellDef="let e">
                      <div class="boxe"  [innerHTML]="e.advertenciaCorreo" >
                      </div>  
                    </td>
                  </ng-container>
                </div>
   
                <!-- eliminar -->
                <ng-container matColumnDef="remover">
                  <th mat-header-cell *matHeaderCellDef> Eliminar </th>
                  <td mat-cell *matCellDef="let e">
                    <button mat-menu-item (click)="deleteActivity(e)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <ng-container matColumnDef="editar">
                  <th mat-header-cell *matHeaderCellDef> Editar </th>
                  <td mat-cell *matCellDef="let e">
                    <button mat-menu-item (click)="updateActivity(e)">
                      <mat-icon>mode_edit</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <ng-container matColumnDef="division">
                  <th mat-header-cell *matHeaderCellDef> Sub Actividad </th>
                  <td mat-cell *matCellDef="let e">
                    <button mat-menu-item (click)="show(e, 'Division')">
                      <mat-icon class="mat-18">reorder</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <ng-container matColumnDef="horario">
                  <th mat-header-cell *matHeaderCellDef> horario </th>
                  <td mat-cell *matCellDef="let e">
                    <button mat-menu-item (click)="show(e, 'Horario')">
                      <mat-icon class="mat-18">update</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="example-element-detail"
                      [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                      <div class="card">
                        <div class="card-body" *ngIf="showItem == 'Division'">
                          <div class="row filter-style" [formGroup]="formDivision">
                            <div class="col-md-4">
                              <mat-form-field appearance="outline" class="date-input">
                                <mat-label>Nombre</mat-label>
                                <input formControlName="nombre" matInput>
                              </mat-form-field>
                            </div>
                            <div class="col-md-2">
                              <mat-form-field appearance="outline" class="date-input">
                                <mat-label>Activo</mat-label>
                                <mat-select formControlName="activo">
                                  <mat-option *ngFor="let l of stateList" [value]="l.value">
                                    {{l.nombre}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>

                            <div class="col-md-6">
                              <button class="btn btn-cancel" (click)="getDivisionActivities(element.id, 1)">
                                <mat-icon>search</mat-icon>
                                Buscar
                              </button>
                              <button class="btn btn-cancel" (click)="addDivision(element)">
                                <mat-icon>add</mat-icon>
                                Agregar
                              </button>
                            </div>
                          </div>
                          <table class="table table-hover">
                            <thead>
                              <th>Nombre</th>
                              <th>Activo</th>
                              <th>Secuencia</th>
                              <th>Cantidad</th>
                              <th>Usuario de Alta</th>
                              <th>Fecha de Creación</th>
                              <th>Eliminar</th>
                              <th>Editar</th>
                            </thead>
                            <tbody>
                              <tr *ngFor="let l of activityDivisionList">
                                <td>{{l.nombre}}</td>
                                <td>{{l.activo ? 'SÍ' : 'NO'}}</td>
                                <td>{{l.secuencia}}</td>
                                <td>{{l.cantidad}}</td>
                                <td>{{l.usuarioAlta}}</td>
                                <td>{{l.fechaCreacion | date:'fullDate':'GMT-4':'ES-py'}}</td>
                                <td>
                                  <button mat-menu-item class="btn-delete" (click)="deleteActivityDivision(element, l)">
                                    <mat-icon class="mat-18">delete_forever</mat-icon>
                                  </button>
                                </td>
                                <td>
                                  <button mat-menu-item class="btn-edit" (click)="updateActivityDivision(element, l)">
                                    <mat-icon class="mat-18">edit</mat-icon>
                                  </button>
                                </td>
                                
                              </tr>
                            </tbody>
                          </table>
                          <mat-paginator [length]="paginationDivision.cantidad"
                            [pageSize]="paginationDivision.por_pagina" [pageSizeOptions]="paginatorRange"
                            (page)="getServerDataSubActivity($event, element)">
                          </mat-paginator>
                        </div>

                        <div class="card-body" *ngIf="showItem == 'Horario'">      
                          <div class="row filter-style">
                            <div class="col-md-12">
                              <button class="btn btn-cancel" (click)="addSchedule(element)">
                                <mat-icon>add</mat-icon>
                                Agregar
                              </button>
                            </div>
                          </div>                   
                          <table class="table table-hover">
                            <thead>
                              <th>Horario</th>
                              <th>Turno</th>
                              <th>Secuencia</th>
                              <th>Usuario de Alta</th>
                              <th>Fecha de Creación</th>
                              <th>Eliminar</th>
                              <th>Editar</th>
                            </thead>
                            <tbody>
                              <tr *ngFor="let l of activityScheduleList">
                                <td>{{l.horario}}</td>
                                <td>{{l.turnoNombre}}</td>
                                <td>{{l.secuencia}}</td>
                                <td>{{l.usuarioAlta}}</td>
                                <td>{{l.fechaCreacion | date:'fullDate':'GMT-4':'ES-py'}}</td>
                                <td>
                                  <button mat-menu-item class="btn-delete" (click)="deleteActivitySchedule(element, l)">
                                    <mat-icon class="mat-18">delete_forever</mat-icon>
                                  </button>
                                </td>
                                <td>
                                  <button mat-menu-item class="btn-edit" (click)="updateActivitySchedule(element, l)">
                                    <mat-icon class="mat-18">edit</mat-icon>
                                  </button>
                                </td>
                                
                              </tr>
                            </tbody>
                          </table>
                          <mat-paginator [length]="paginationSchedule.cantidad"
                            [pageSize]="paginationSchedule.por_pagina" [pageSizeOptions]="paginatorRange"
                            (page)="getServerDataHorario($event, element)">
                          </mat-paginator>
                        </div>
                      </div>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                  [class.example-expanded-row]="expandedElement === row"></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
              </table>
              <mat-paginator [length]="pagination.cantidad" [pageSize]="pagination.por_pagina"
                [pageSizeOptions]="paginatorRange" (page)="getServerData($event)">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>