import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  // URL base para la comunicacion de servicios
  public URL_BASE = environment.apiUrl;

  // nombre de la app
  public APP_NAME = 'Los Lagos';

  // identificador de la app
  private APP_ID = 'loslagos.web';

  // version de la app
  public APP_VERSION = '1.0.0';

  //tiempo de espera
  public LONG_TIME = 10;

  // cantidad de ítems a paginar para las tablas
  public PAGINATOR_RANGE = [10];


  getAppId() {
      return this.APP_ID;
  }
}
