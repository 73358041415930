import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropReserveComponent } from './drag-drop-reserve.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../core/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { PersonaListComponent } from './persona-list-component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PersonaFormComponent } from './persona-form.component';
import { ReservaFormComponent } from './reserva-form.component';
import { dragDropRouting } from './drag-drop.routing';
import { LoaderModule } from '../components/loader/loader.module';
import { BloqueoFormComponent } from './bloqueo-form.component';


@NgModule({
  declarations: [
    DragDropReserveComponent,
    PersonaListComponent,
    PersonaFormComponent,
    ReservaFormComponent,
    BloqueoFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    HttpClientModule,
    ClipboardModule,
    dragDropRouting,
    LoaderModule,
  ],
  exports: [DragDropReserveComponent]
})
export class DragDropReserveModule { }
