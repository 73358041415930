import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReserveService } from '../services/reserve.service';
import { ReportService } from '../services/report.service';
import { DomSanitizer } from '@angular/platform-browser';
import { JsonCsvService } from './json-csv.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent {

  form: FormGroup;
  activityList: any[] = [];
  reportTypeList: any[] = [];
  generado: boolean = false;
  b64native = '';
  type = 'PDF';

  public fileName: any;
  displayedColumns = ['fecha', 'confirmados', 'pendientes', 'cancelados', 'rechazados'];
  dataSource = [];
  header = [];
  constructor(public fb: FormBuilder, public reserveService: ReserveService,
    private sanitizer: DomSanitizer,
    private reportService: ReportService,
    private csvService: JsonCsvService,) {
    this.initForm();
    this.getActivity();
  }

  /**
   * Construye el formulario
   */
  initForm() {
    this.form = this.fb.group({
      fechaDesde: ['', Validators.required],
      fechaHasta: ['', Validators.required],
      tipoActividad: ['', Validators.required],
      tipoReporte: ['', Validators.required],
    });

    this.form.valueChanges.subscribe( () => {
      this.generado = false;
      this.fileName = null;
      this.dataSource = [];
    });
  }

  /**
   * Método para obtener la lista de actividades
   */
  getActivity() {
    this.reserveService.getAllActivity()
      .subscribe(data => {
        this.activityList = data;
      });
  }

  /**
   * Método para obtener la lista de tipos de reportes por actividad actividades
   * @param id: identificador de la actividad
   */
  getReportType(id: any) {
    this.reportService.getReportType(id)
      .subscribe(data => {
        this.reportTypeList = data;
      });
  }

  /**
   * Método para generar reporte en pdf a partir de los filtros seleccionados
   */
  getReportPDF() {
    this.reportService.getReportPDF(this.form.value.tipoReporte, { fechaDesde: this.form.value.fechaDesde, fechaHasta: this.form.value.fechaHasta, reporteFormato: 'PDF' })
      .subscribe(data => {
        this.generado = true;
        this.type = 'PDF';
        this.fileName = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64," + data.reporte);
      }
    );
  }

  /**
   * Solicita un objeto json para mostrar en pantalla
   */
  generate(formato = 'CSV') {
    
    this.reportService.getReportPDF(this.form.value.tipoReporte, { fechaDesde: this.form.value.fechaDesde, fechaHasta: this.form.value.fechaHasta, reporteFormato: formato })
    .subscribe((data: any) => {

      
      this.type = formato;
      this.generado = true;
      if (data && data.reporte.length > 0) {
        if (data.header) {
            this.displayedColumns = [];
            this.header = data.header;
            data.header.forEach( e => {
              this.displayedColumns.push(e.key);
            });
          }
          this.dataSource = data.reporte;
          if (formato === 'XLS') {
            this.fileName = data.XLSbase64;
          }
        }
      }
    );

  }

  /**
   * Descarga el archivo csv/excel
   */
  downloadFile() {
    const desde = new Date(this.form.controls.fechaDesde.value);
    const hasta = new Date(this.form.controls.fechaHasta.value);
    const nombre = `reporte_${desde.getDate()}-${desde.getMonth()+1}-${desde.getFullYear()}_${hasta.getDate()}-${hasta.getMonth()+1}-${hasta.getFullYear()}`;
    if (this.type === 'CSV') {
      this.csvService.downloadCsv(this.dataSource, this.displayedColumns, nombre);
    } else {

      const byteCharacters = atob(this.fileName);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray]);

      this.csvService.download(blob, nombre);
    }

  }

}
