<!-- Grafico de barra vertical -->
<div class="contenedorGraficos" fxLayout="row" fxLayoutGap="200px">
  <div>
    <ngx-charts-line-chart 
     [view]="vista"
     [scheme]="colorScheme"
     [legend]="legend"
     [showXAxisLabel]="showXAxisLabel"
     [showYAxisLabel]="showYAxisLabel"
     [xAxis]="xAxis"
     [yAxis]="yAxis"
     [xAxisLabel]="xAxisLabel"
     [yAxisLabel]="yAxisLabel"
     [timeline]="timeline"
     [legendPosition]='legendPosition'
     [legendTitle]="tituloLegend"
     [results]="datos">
    </ngx-charts-line-chart>
  </div>
</div>