<div class="main-content">
  <div class="container-fluid">
    <app-loader></app-loader>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6" *ngFor="let s of seccion">
        <div class="card card-stats card-section" (click)="getSection(s)">
          <div class="card-header card-header-warning card-header-icon">
            <div class="card-icon" [class]="s.color">
              <i class="material-icons">{{s.icono}}</i>
            </div>
            <p class="card-category">{{s.nombre}}</p>
            <h3 class="card-title">{{s.cantidadMesas}}<small>Mesas</small></h3>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-map">
      <mat-drawer-container>
        <mat-drawer mode="side" opened #drawer class="detalle-box">
          <div>
            <mat-toolbar color="primary" class="btn-general" fxLayout="row" fxLayoutAlign="space-between center">

              <div class="text-center">
                Reservas
              </div>
            </mat-toolbar>
            <div class="p-15 actividad-content">
              <div class="row" [formGroup]="formReserva">
                <div class="col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>Nombre</mat-label>
                    <input formControlName="nombre" matInput matInput #busqueda (keyup.enter)="getRestaurantReserve(1)">
                    <button mat-button *ngIf="busqueda.value" matSuffix mat-icon-button aria-label="Clear"
                      (click)="formReserva.controls.nombre.setValue('')">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>Turno</mat-label>
                    <mat-select formControlName="horario" #myHours (selectionChange)="getRestaurantReserve(1)">
                      <mat-option *ngFor="let l of horarioList" [value]="l.horario">{{l.horario}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div>
                <mat-accordion>
                  <mat-expansion-panel *ngFor="let r of reserveList" (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{r.cliente}}
                      </mat-panel-title>
                      <mat-panel-description>
                        {{r.horario}}
                        <span class="material-icons" [ngStyle]="{'color': r.mesaId ? '#4eab26' : '#c30808'}">
                          {{r.mesaId ? 'done_all' : 'remove_done' }}
                        </span>
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <p>{{r.mesaId ? '' : 'Sin asignar' }}</p>
                    <h3 class="text-detail">Cantidad: <span>{{r.cantidad}}</span></h3>
                    <h3 class="text-detail">Mesa:<span> {{r.mesa}}</span></h3>

                    <mat-form-field appearance="outline">
                      <mat-label>Asignar Mesa</mat-label>
                      <mat-select (selectionChange)="selectedTable(r, $event.value)">
                        <mat-option *ngFor="let m of mesas" [value]="m.id">
                          {{m.mesa}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>

        </mat-drawer>
        <mat-drawer-content class="box-content">
          <div class="card-header ">
            <div class="row">
              <div class="col-md-4">
                <div class="row input-filter" [formGroup]="form">
                  <div class="col-md-6">
                    <mat-form-field appearance="outline">
                      <mat-label>Fecha</mat-label>
                      <input formControlName="fecha" matInput [matDatepicker]="pickerInicio"
                        (dateChange)="dateChange($event)">
                      <mat-datepicker-toggle matSuffix [for]="pickerInicio"></mat-datepicker-toggle>
                      <mat-datepicker #pickerInicio></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field appearance="outline">
                      <mat-label>Turno</mat-label>
                      <mat-select formControlName="horario" #myHoursSelect
                        (selectionChange)="selectedHour($event.value)">
                        <mat-option *ngFor="let l of horarioList" [value]="l.horario">{{l.horario}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <h5 class="card-title">
                  MAPA DE {{title}}
                </h5>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="row min-h">
              <div class="table-drag" *ngFor="let m of mesas" [id]="m.id"
                (click)="m.reservaId != 0 && updateReserve(m)"
                (click)="m.reservaId == 0 && bloquearMesa(m)"
                [ngClass]="{'estado-ocupado': m.estado === 'Ocupada', 'estado-bloqueado': m.estado === 'Bloqueada', 'estado-reservado': m.estado === 'Reservada'}"
                [ngStyle]="{'width.px': m.ancho, 'height.px': m.alto, transform: 'translate(' + m.posicionX + 'px,' + m.posicionY + 'px)'}"
                [attr.data-x]="m.posicionX" [attr.data-y]="m.posicionY">
                {{m.mesa}} <b>({{m.cantidadReserva ? m.cantidadReserva : 0}}/{{m.cantidadMesa}}) </b>
                <p class="dm-style">{{m.cliente}}</p>
                <p class="dm-style" *ngIf="m.estado === 'Bloqueada'">BLOQUEADO</p>
              </div>
            </div>
          </div>
        </mat-drawer-content>
      </mat-drawer-container>
    </div>
  </div>
</div>