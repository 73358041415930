import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '../interceptor/settings.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService {

  constructor(
    private http: HttpClient,
    private settings: SettingsService
  ) { }

  /**
   * Método para obtener la lista de los tipos de reportes por actividad
   * @param id: identificador de la actividad
   * @returns HTTP/1.1 200 OK lista | HTTP/1.1 500 Internal Server Error
   */
  getReportType(id: any): Observable<any> {
    return this.http.get(`${this.settings.URL_BASE}reporte/actividad/${id}`);
  }

  /**
   * Método para obtener reporte generado en pdf
   * @param body objeto con la información para generar el reporte
   * @param id identificador de tipo de reporte
   * @returns HTTP/1.1 200 OK objeto json | HTTP/1.1 500 Internal Server Error
   */
  getReportPDF(id: any, body: any): Observable<any> {
    return this.http.post(`${this.settings.URL_BASE}reporte/${id}/generar`, body);
  }
}
