import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ReserveService } from '../services/reserve.service';

@Component({
  selector: 'app-client',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientListComponent implements OnInit {
  actividad: any;
  icono: any;
  displayedColumns: string[] = ['cedula', 'nombre', 'apellido', 'celular', 'correo'];
  dataSource = [];

  constructor(private fb: FormBuilder,
    public reserveService: ReserveService,
    private activeRoute: ActivatedRoute,
    private _snackBar: MatSnackBar) {
    localStorage.removeItem('session');
  }

  ngOnInit() {
    this.actividad = this.activeRoute.snapshot.queryParams.actividad;
    this.icono = this.activeRoute.snapshot.queryParams.icono;
    this.getList();
  }

  getList() {
    this.reserveService.getParticipants(this.activeRoute.snapshot.paramMap.get('id'))
      .subscribe(
        data => {
          this.dataSource = data
        });
  }
}
