<div class="main-content">
  <div class="container-fluid">
    <div class="card"> 
      <div class="card-body">
        <app-loader></app-loader>
        <div class="style-filter" [formGroup]="form">
          <div class="fecha-filtro" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="row">
          <!-- <div class="col-md-2"> -->
            <mat-form-field appearance="outline" class="date-input">
              <mat-label>Nombre</mat-label>
              <input formControlName="nombre" matInput>
            </mat-form-field>
          <!-- </div> -->
          <!-- <div class="col-md-2"> -->
            <mat-form-field appearance="outline" class="date-input">
              <mat-label>Apellido</mat-label>
              <input formControlName="apellido" matInput>
            </mat-form-field>
          <!-- </div> -->
          <!-- <div class="col-md-2"> -->
            <mat-form-field appearance="outline">
              <mat-label>Cédula</mat-label>
              <input formControlName="cedula" type="number" matInput>
            </mat-form-field>
          <!-- </div> -->
          <!-- <div class="col-md-2"> -->
            <mat-form-field appearance="outline">
              <mat-label>Bloqueado</mat-label>
              <mat-select formControlName="bloqueado">
                <mat-option *ngFor="let l of selectList" [value]="l.key">
                  {{l.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          <!-- </div> -->
          <!-- <div class="col-md-4"> -->
            <button class="btn btn-general" (click)="getList(1)">
              <mat-icon>search</mat-icon>
              Buscar
            </button>
            <button class="btn btn-general" (click)="addClient()">
              <mat-icon>add</mat-icon>
              Agregar
            </button>
          <!-- </div> -->
        </div> 
        </div>
        <div class="tab-content">
          <div class="contenedor" fxLayout="column" fxLayoutGap="20px">
            <div class="form-row table-container" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px">
              <table mat-table [dataSource]="dataSource" multiTemplateDataRows>

                <!-- cedula -->
                <ng-container matColumnDef="cedula">
                  <th mat-header-cell *matHeaderCellDef> Cédula N° </th>
                  <td mat-cell *matCellDef="let e"> {{e.cedula}} </td>
                </ng-container>

                <!-- nombre -->
                <ng-container matColumnDef="nombre">
                  <th mat-header-cell *matHeaderCellDef> Nombre </th>
                  <td mat-cell *matCellDef="let e"> {{e.nombre}} </td>
                </ng-container>

                <!-- apellido -->
                <ng-container matColumnDef="apellido">
                  <th mat-header-cell *matHeaderCellDef> Apellido </th>
                  <td mat-cell *matCellDef="let e"> {{e.apellido}} </td>
                </ng-container>

                <!-- celular -->
                <ng-container matColumnDef="celular">
                  <th mat-header-cell *matHeaderCellDef> Celular </th>
                  <td mat-cell *matCellDef="let e"> {{e.celular}} </td>
                </ng-container>

                <!-- correo -->
                <ng-container matColumnDef="correo">
                  <th mat-header-cell *matHeaderCellDef> Correo </th>
                  <td mat-cell *matCellDef="let e"> {{e.email}} </td>
                </ng-container>

                <!-- bloqueado -->
                <ng-container matColumnDef="bloqueado">
                  <th mat-header-cell *matHeaderCellDef> Bloqueado </th>
                  <td mat-cell *matCellDef="let e"> {{e.bloqueado ? 'SÍ' : 'NO'}} </td>
                </ng-container>

                <!-- fecha alta -->
                <ng-container matColumnDef="fechaAlta">
                  <th mat-header-cell *matHeaderCellDef> Fecha Alta </th>
                  <td mat-cell *matCellDef="let e"> {{e.fechaAlta | date:'fullDate':'GMT-4':'ES-py'}} </td>
                </ng-container>

                <!-- eliminar -->
                <ng-container matColumnDef="remover">
                  <th mat-header-cell *matHeaderCellDef> Eliminar </th>
                  <td mat-cell *matCellDef="let e">
                    <button mat-menu-item (click)="deleteClient(e)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <ng-container matColumnDef="editar">
                  <th mat-header-cell *matHeaderCellDef> Editar </th>
                  <td mat-cell *matCellDef="let e">
                    <button mat-menu-item (click)="updateClient(e)">
                      <mat-icon>mode_edit</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <ng-container matColumnDef="bloquear">
                  <th mat-header-cell *matHeaderCellDef> Actualizar </th>
                  <td mat-cell *matCellDef="let e">
                    <button mat-raised-button color="primary" (click)="updateState(e)">
                      {{e.bloqueado ? 'Desbloquear' : 'Bloquear'}}
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <mat-paginator [length]="pagination.cantidad" [pageSize]="pagination.por_pagina"
                [pageSizeOptions]="paginatorRange" (page)="getServerData($event)">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>