//angular
import { Routes, RouterModule } from '@angular/router';
import { ClientAdminListComponent } from './client-admin-list.component';
import { ClientFormComponent } from './client-form.component';
import { ClientListComponent } from './client-list.component';

const appRoutes: Routes = [
    { path: 'cliente/:id', component: ClientFormComponent },
    { path: 'lista/:id', component: ClientListComponent },
    { path: 'cliente', component: ClientAdminListComponent }
];

export const clientRouting = RouterModule.forChild(appRoutes);