import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SessionService } from './session.service';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private sessionService: SessionService, private snackbar: SnackbarService ) {}

  detectError(errorResponse: HttpErrorResponse | ErrorEvent | any): void {
    //console.log(errorResponse);
    // el mensaje final a mostrar en el dialog o snackbar
    let viewMsg: Array<string>;

    if (errorResponse instanceof HttpErrorResponse) {
      //console.log(errorResponse)
      if (errorResponse.status === 462) {
        this.goLogin(errorResponse.error.message);
      }

      if (errorResponse.status === 401) {
        this.goLogin(errorResponse.error.message);
      }

      if (errorResponse.status === 0) {
        this.snackbar.showMessage('Verifique su conexión');
      }


      // mensaje de error en el response
      const errorObj: any = errorResponse.error;

      // mensajes a mostrar
      viewMsg = errorResponse.error.message;
      this.snackbar.showMessage(viewMsg.toString())
    }
  }

  goLogin(message: any): void {
    this.snackbar.showMessage(message);
    this.sessionService.logout();
  }
}
