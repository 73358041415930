<div class="image-container set-full-height">
  <div class="container">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="wizard-container">
          <div class="card wizard-card" data-color="primary">
            <div class="wizard-header">
              <div class="row">
                <div class="col-md-4 col-sm-2"></div>
                <div class="col-md-4 col-sm-8">
                  <img src="../../assets/images/los-lagos-sombra.png">
                </div>
                <div class="col-md-4 col-sm-2"></div>
              </div>
              <h3 class="wizard-title"> Servicio de Reservas </h3>
              <h5>Complete el Formulario</h5>
            </div>
            <div class="tab-content">              
              <div [formGroup]="form" fxLayout="column" fxLayoutGap="20px">
                <div class="row">
                  <div class="col-sm-4">
                  </div>
                  <div class="col-sm-4">
                    <div class="choice active">
                      <div class="icon">
                        <i class="material-icons">{{icono}}</i>
                      </div>
                      <h6>{{actividad}}</h6>
                    </div>
                  </div>
                </div>
                <div class="form-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px">
                  <div class="col-md-6">
                    <mat-form-field appearance="outline">
                      <mat-label>Nombre</mat-label>
                      <input matInput formControlName="nombre">
                    </mat-form-field>
                    <div class="text-center">
                      <small class="text-danger"
                        *ngIf="form.controls.nombre.touched && form.controls.nombre.errors?.required">El nombre
                        es requerido</small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field appearance="outline">
                      <mat-label>Apellido</mat-label>
                      <input matInput formControlName="apellido">
                    </mat-form-field>
                    <div class="text-center">
                      <small class="text-danger"
                        *ngIf="form.controls.apellido.touched && form.controls.apellido.errors?.required">El apellido
                        es requerido</small>
                    </div>
                  </div>
                </div>

                <div class="form-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px">
                  <div class="col-md-6">
                    <mat-form-field appearance="outline">
                      <mat-label>Número de Cédula</mat-label>
                      <input matInput type="number" formControlName="cedula">
                    </mat-form-field>
                    <div class="text-center">
                      <small class="text-danger"
                        *ngIf="form.controls.cedula.touched && form.controls.cedula.errors?.required">El número de cédula
                        es requerido</small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field appearance="outline">
                      <mat-label>Número de Celular</mat-label>
                      <input matInput number formControlName="celular">
                    </mat-form-field>
                    <div class="text-center">
                      <small class="text-danger"
                        *ngIf="form.controls.celular.touched && form.controls.celular.errors?.required">El número de celular
                        es requerido</small>
                    </div>
                  </div>
                </div>
                <mat-form-field appearance="outline">
                  <mat-label>Correo Electrónico</mat-label>
                  <input email matInput formControlName="email">
                </mat-form-field>
                <div class="text-center">
                  <small class="text-danger"
                    *ngIf="form.controls.email.touched && form.controls.email.errors?.required">El correo electrónico
                    es requerido</small>
                </div>
              </div>
              <div class="text-center dialog-btn-container pb-15">
                <button class="btn btn-general" [disabled]="form.invalid" (click)="save()">
                    <mat-icon>check</mat-icon>
                    CONFIRMAR
                </button>                
            </div>            
            </div>
          </div>         
        </div>
      </div>
    </div>
  </div>
</div>